import Moment from "moment";
import { ERROR_MESSAGE } from "../messages";

export function useValidate(key, val, type = "text") {
  let message = "";
  let isError = false;

  const numbers = ["cus_app_id", "his_app_id"];
  const byPassKeys = ["cus_token", "his_token"];
  const emailKeys = ["username", "email"];

  if (numbers.includes(key) && val) {
    if (isNaN(val)) {
      message = ERROR_MESSAGE.FORMAT_NUMBER;
      isError = true;
    } else if (parseInt(val) < 0 || parseInt(val) > 1000) {
      message = "Please input from 1 to 1000";
      isError = true;
    }
  } else {
    if (!byPassKeys.includes(key) && !val.length) {
      message = ERROR_MESSAGE.REQUIRED;
      isError = true;
    }
  }

  if (emailKeys.includes(key) && !validateEmail(val)) {
    message = ERROR_MESSAGE.FORMAT_EMAIL;
    isError = true;
  }

  if (key === "domain" && !validateURL(val)) {
    message = ERROR_MESSAGE.FORMAT_URL;
    isError = true;
  }

  if (key === "password" && val.length < 6) {
    message = ERROR_MESSAGE.MIN_LENGTH;
    isError = true;
  }

  if (type === "date" && !Moment(val, "YYYY-MM-DD HH:mm", true).isValid()) {
    message = ERROR_MESSAGE.FORMAT_DATE;
  }

  if (type === "hour" && !Moment(val, "HH:mm", true).isValid()) {
    message = ERROR_MESSAGE.FORMAT_HOUR;
  }

  if (type === "minute" && !Moment(val, "HH:mm", true).isValid()) {
    message = ERROR_MESSAGE.FORMAT_MINUTE;
  }

  if (val.length > 255) {
    message = ERROR_MESSAGE.MAX_LENGTH;
    isError = true;
  }

  return [isError, key, message];
}

export function validateEmail(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function validateURL(param) {
  const re = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/;

  return re.test(param);
}
