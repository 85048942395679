import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { isEmpty } from "lodash";

export default function CustomerInfo(props) {
  const { customer } = props;
  const [remarkCollapse, setRemarkCollapse] = useState(false);
  const [detailCollapse, setDetailCollapse] = useState(false);

  function handleCollapseRemark() {
    setRemarkCollapse(!remarkCollapse);
  }

  function handleCollapseDetail() {
    setDetailCollapse(!detailCollapse);
  }

  function renderCollapseDetail(detailData) {
    // If detail data is exist
    if (!isEmpty(detailData)) {
      return detailData.map((detail, idx) => (
        <div key={idx}>
          <h5 className="title-head">{detail["name"]}</h5>
          {detail.groups.map((group) => {
            // If field text is exist in group
            if (group["text"]) {
              return group["text"].map((text) => {
                // If text value is exst
                return (
                  <Box key={text.key} className="collapse-detail">
                    <span className="title">{text.name}:</span> {text.value}
                  </Box>
                );
              });
            }

            // If field check is exist in group
            if (group["check"]) {
              return group["check"].map((check) => {
                // If check value is exist
                return (
                  <Box key={check.key} className="collapse-detail">
                    <span className="title">{check.name}:</span>
                    {check.value}
                  </Box>
                );
              });
            }

            // If field biko is exist in group and value is exist
            if (group["biko"]) {
              return (
                <Box key={group["biko"].key} className="collapse-detail">
                  <span className="title"> {group.name}: </span>
                  {group["biko"].value}
                </Box>
              );
            }
          })}
        </div>
      ));
    }
  }

  return (
    <Card className="customer-info-card">
      <CardHeader>
        <h4 className="text-bold">顧客情報</h4>
      </CardHeader>
      <CardBody className="customer-info">
        <GridContainer spacing={3} direction="row">
          <GridItem xs={12}>
            <h3 className="text-bold name">{customer.name}</h3>
            <h5>{customer.kana}</h5>
          </GridItem>
          <GridItem xs={12}>
            <Box sx={{ pt: 1 }}>
              <i className="material-icons customer-info-icon">call</i>
              {customer.tel} /
              <i className="material-icons customer-info-icon">pin</i>
              {customer.customer_no}
            </Box>
          </GridItem>
          <GridItem xs={12}>
            <i className="material-icons customer-info-icon">home</i>

            {(!isEmpty(customer.zip) ? customer.zip : "") +
              " / " +
              (!isEmpty(customer.address1) ? customer.address1 : "") +
              "、" +
              (!isEmpty(customer.address2) ? customer.address2 : "")}
          </GridItem>
          <hr className="horizontal dark w-100" />
          <GridItem xs={12}>
            <Button
              className="btn-collapse"
              simple
              onClick={handleCollapseRemark}
            >
              備考 {remarkCollapse ? <RemoveIcon /> : <AddIcon />}
            </Button>
            <Collapse in={remarkCollapse} unmountOnExit>
              {customer.bikos &&
                customer.bikos.map(
                  (biko) =>
                    biko.name && (
                      <Box key={biko.key} className="collapse-biko">
                        <span className="title">{biko.name}:</span> {biko.value}
                      </Box>
                    )
                )}
            </Collapse>
          </GridItem>
          <GridItem xs={12}>
            <Button
              className="btn-collapse"
              simple
              onClick={handleCollapseDetail}
            >
              詳細 {detailCollapse ? <RemoveIcon /> : <AddIcon />}
            </Button>
            <Collapse in={detailCollapse} unmountOnExit>
              {renderCollapseDetail(customer.details)}
            </Collapse>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}
