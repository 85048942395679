import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Moment from "moment";
import React from "react";
import Spinner from "react-bootstrap/Spinner";
import styles from "./dashboardStyle";

const useStyles = makeStyles(styles);

export default function List(props) {
  const { isLoading, items, summary } = props;
  const classes = useStyles();

  const headerColumns = [
    { key: "date_c", label: "日付", sortable: false, minWidth: 90 },
    {
      key: "ec_per_day",
      label: "Ever Call 連携",
      sortable: false,
      minWidth: 140,
    },
    {
      key: "salesforce_per_day",
      label: "Sales force 連携",
      sortable: false,
      minWidth: 160,
    },
    {
      key: "kintone_per_day",
      label: "キントーン 連携",
      sortable: false,
      minWidth: 160,
    },
    {
      key: "other_per_day",
      label: "その他 連携",
      sortable: false,
      minWidth: 150,
    },
    { key: "remain_per_day", label: "滞留", sortable: false, minWidth: 90 },
  ];

  function renderRow() {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell align="center" colSpan={headerColumns.length}>
            <Spinner className="text-light" animation="grow" />
            <Spinner className="text-light" animation="grow" />
            <Spinner className="text-light" animation="grow" />
          </TableCell>
        </TableRow>
      );
    }

    if (!items.length) {
      return (
        <TableRow>
          <TableCell align="center" colSpan={headerColumns.length + 1}>
            <div style={{ width: "100%", textAlign: "center" }}>
              該当データが存在しません。
            </div>
          </TableCell>
        </TableRow>
      );
    }

    return items.map((item, idx) => {
      return (
        <TableRow hover key={idx}>
          <TableCell className="text-gray">
            {Moment(item.date_c).format("MM月 DD日")}
          </TableCell>
          <TableCell className="text-gray">
            <span className={classes.itemInRow}>{item.ec_per_day}</span>件
          </TableCell>
          <TableCell className="text-gray">
            <span className={classes.itemInRow}>{item.salesforce_per_day}</span>件
          </TableCell>
          <TableCell className="text-gray">
            <span className={classes.itemInRow}>{item.kintone_per_day}</span> 件
          </TableCell>
          <TableCell className="text-gray">
            <span className={classes.itemInRow}>{item.other_per_day}</span> 件
          </TableCell>
          <TableCell className="text-gray">
            <span className={classes.itemInRow}>{item.tairyu_per_day}</span> 件
          </TableCell>
        </TableRow>
      );
    });
  }

  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            {headerColumns.map((column) => (
              <TableCell
                key={column.key}
                padding="normal"
                style={{ minWidth: column.minWidth }}
                className={classes.tableDashboardHead}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{renderRow()}</TableBody>
        <TableFooter>
          <TableRow>
            <TableCell className={classes.tableDashboardHead}>
              <span className={classes.amountChar}>合計</span>
            </TableCell>
            <TableCell className={classes.tableDashboardHead}>
              <span className={classes.itemInRow}>{summary?.sum_ec}</span>
              <span className={classes.amountChar}>件</span>
            </TableCell>
            <TableCell className={classes.tableDashboardHead}>
              <span className={classes.itemInRow}>{summary?.sum_sf}</span>
              <span className={classes.amountChar}>件</span>
            </TableCell>
            <TableCell className={classes.tableDashboardHead}>
              <span className={classes.itemInRow}>{summary?.sum_ktn}</span>
              <span className={classes.amountChar}>件</span>
            </TableCell>
            <TableCell className={classes.tableDashboardHead}>
              <span className={classes.itemInRow}>{summary.sum_other}</span>
              <span className={classes.amountChar}>件</span>
            </TableCell>
            <TableCell className={classes.tableDashboardHead}>
              <span className={classes.itemInRow}>{summary.sum_tairyu}</span>
              <span className={classes.amountChar}>件</span>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
