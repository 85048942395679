const sortable = true;

export const headerTable = [
  { key: "name", label: "氏名", sortable, minWidth: 100 },
  { key: "customer_no", label: "顧客番号", sortable, minWidth: 130 },
  { key: "tel", label: "電話番号", sortable, minWidth: 130 },
  { key: "list_name", label: "リスト", sortable, minWidth: 120 },
  {
    key: "renkei_date",
    label: "連携日(時間)",
    sortable: false,
    minWidth: 150,
  },
  {
    key: "result_name",
    label: "結果パターン",
    sortable,
    minWidth: 170,
  },
  { key: "status", label: "ステータス", sortable: false, minWidth: 190 },
  { key: "user_name", label: "担当者", sortable, minWidth: 150 },
  { key: "action", label: "アクション", sortable: false, minWidth: 130 },
];
