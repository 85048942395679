import React from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CallIcon from "@mui/icons-material/Call";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import Paper from "@material-ui/core/Paper";
import Spinner from "react-bootstrap/Spinner";
import PersonIcon from "@material-ui/icons/Person";
import FileCallHistory from "./History/FileCallHistory";
import { dateFormat, renderColorBadge } from "../../../helpers/common";
import evercallIcon from "../../../assets/img/evercall-icon.png";
import Moment from "moment";

export function History(props) {
  const { data, isLoading } = props;

  const columnDatas = [
    {
      key: "tel_time",
      label: "発着信履歴",
      minWidth: 200,
    },
    {
      key: "tel",
      label: "着信元",
      minWidth: 150,
    },
    {
      key: "connect_time",
      label: "通話時間",
      minWidth: 120,
    },
    { key: "call_type", label: "発着", minWidth: 100 },
    { key: "user_name", label: "対応者", minWidth: 100 },
    {
      key: "result",
      label: "結果",
      minWidth: 120,
    },
    { key: "record", label: "録音", minWidth: 90 },
  ];

  function renderRow() {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell align="center" colSpan={columnDatas.length}>
            <Spinner className="text-light" animation="grow" />
            <Spinner className="text-light" animation="grow" />
            <Spinner className="text-light" animation="grow" />
          </TableCell>
        </TableRow>
      );
    }

    if (!data.length) {
      return (
        <TableRow>
          <TableCell align="center" colSpan={columnDatas.length + 1}>
            <div style={{ width: "100%", textAlign: "center" }}>
              該当データが存在しません。
            </div>
          </TableCell>
        </TableRow>
      );
    }

    return data.map((item, idx) => {
      return (
        <TableRow hover role="checkbox" key={idx}>
          <TableCell className="text-gray">
            {item.tel_time.length
              ? dateFormat(item.tel_time, "YYYY-MM-DD HH:mm:ss")
              : ""}
          </TableCell>
          <TableCell className="text-gray">{item.tel}</TableCell>
          <TableCell className="text-gray">
            {Moment.utc(item.connect_time * 1000).format("HH:mm:ss")}
          </TableCell>
          <TableCell className="text-gray">
            <Box sx={{ display: "flex" }} alignItems="center">
              <CallIcon fontSize="small" style={{ fill: "#1A73E8" }} />
              発信
            </Box>
          </TableCell>
          <TableCell className="text-gray">
            <Box sx={{ display: "flex" }} alignItems="center">
              <PersonIcon />
              {item.user_name}
            </Box>
          </TableCell>
          <TableCell className="text-gray">
            <Box sx={{ display: "flex" }} alignItems="center">
              <img className="customer-list-result-icon" src={evercallIcon} />
              <Badge
                color="primary"
                badgeContent={item.result_name}
                className={
                  "customer-list-badge-" + renderColorBadge(item.result)
                }
              ></Badge>
            </Box>
          </TableCell>
          <TableCell className="text-gray">
            {item.media_source.length ? (
              <FileCallHistory source={item.media_source} />
            ) : (
              "Recording file not found"
            )}
          </TableCell>
        </TableRow>
      );
    });
  }

  return (
    <GridItem sm={12} xs={12} md={12} lg={12}>
      <div className="text-light-bold text-dark-grey">発着信履歴</div>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <Box sx={{ mb: 2 }}>
                <div className="text-grey">最新の10日前</div>
              </Box>
              <GridContainer>
                <GridItem xs={12}>
                  <Paper
                    sx={{ width: "100%", overflow: "hidden" }}
                    className="stickyFixed"
                  >
                    <TableContainer className="min-height-customer-history">
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columnDatas.map((column) => (
                              <TableCell
                                key={column.key}
                                padding="normal"
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>{renderRow()}</TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridItem>
  );
}
