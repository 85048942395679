import ReactDOM from "react-dom";
import React from "react";
import { Provider } from "react-redux";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { history } from "./helpers";
import { PrivateRoute } from "./components/PrivateRoute";
import AuthLayout from "./layouts/Auth.js";
import AdminLayout from "./layouts/Admin.js";
import "assets/scss/salto-react.scss?v=1.10.0";
import "react-toastify/dist/ReactToastify.css";
import { store } from "./helpers";
import { CONSTANT } from "./constants/constant";

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route path="/auth/login" component={AuthLayout} />
        <PrivateRoute path={CONSTANT.PREFIX_ADMIN} component={AdminLayout} />
        <Redirect from="/" to={`${CONSTANT.PREFIX_ADMIN}/dashboard`} />
      </Switch>
    </Router>
    <ToastContainer />
  </Provider>,
  document.getElementById("root")
);
