import { API_SALES_FORCE, API_EC } from "../helpers/apiURL";
import { fetchAsGet, fetchAsPut,fetchAsDelete } from "./api.service";
import { handleResData } from "./api.handleRespone";

export async function fetchSetting(dispatch) {
  const res = await fetchAsGet(API_SALES_FORCE.BATCH_SETTING);
  return handleResData(res, dispatch);
}

export async function fetchECListByGroupdId(groupId, dispatch) {
  const res = await fetchAsGet(`${API_EC.LIST}/${groupId}`);
  return handleResData(res, dispatch);
}

export async function fetchUpdate(params, dispatch) {
  const res = await fetchAsPut(API_SALES_FORCE.BATCH_SETTING, params);
  return handleResData(res, dispatch, true);
}

export async function fetchDelete(payload, dispatch) {
  const res = await fetchAsDelete(API_SALES_FORCE.BATCH_SETTING, payload);
  return handleResData(res, dispatch, true);
}


export async function fetchBatchLog(batch_code, dispatch) {
  const res = await fetchAsGet(`${API_SALES_FORCE.BATCH_LOG}/${batch_code}`);
  return handleResData(res, dispatch);
}


export async function fetchCaptureLog(batch_code, dispatch) {
  const url = `${API_SALES_FORCE.BATCH_CAPTURE_LOGS}/${batch_code}`;
  const res = await fetchAsGet(url);
  return handleResData(res, dispatch);
}
