export const evercallMessages = {
  username: {
    required: "ユーザー名は必須項目です。",
    max_length: "ユーザー名は、255英字以下でご指定ください。",
  },
  password: {
    required: "パスワードは必須項目です。",
    min_length: "パスワードは、6英字以上でご指定ください。",
    max_length: "パスワードは、255英字以下でご指定ください。",
  },
  access_key: {
    required: "Access Keyは必須項目です。",
    length: "Access Keyは、6英字でご指定ください。",
  },
};
