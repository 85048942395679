const dashboardStyle = () => ({
  titleBox: {
    fontSize: "25px",
    width: "30px",
    backgroundColor: "transparent",
  },
  titleBoxSpan: {
    color: "#434343",
    fontWeight: "bold",
    border: "4px solid gray",
    padding: "3px 20px 3px 20px",
    textAlign: "center",
  },
  dateHeader: {
    fontSize: "20px",
    color: "#434343",
    fontWeight: "bold",
  },
  boxData: {
    marginTop: "30px",
  },
  boxInfoData: {
    height: "170px",
    border: "4px solid #666666",
  },
  boxTitle: {
    marginTop: "20px",
    marginBottom: "60px",
    fontSize: "25px",
    fontWeight: "bold",
    color: "#434343",
  },
  boxNumber: {
    color: "#434343",
    fontSize: "40px",
    fontWeight: "bold",
  },
  boxDataWrapper: {
    with: "100%",
    textAlign: "center",
    boxSizing: "border-box",
    padding: "25px 20px 25px 0px",
  },
  spanSpace: {
    marginRight: "30px",
  },
  tableDashboardHead: {
    fontSize: "17px",
    color: "#434343",
    borderBottom: "3px solid #434343",
  },
  itemInRow: {
    fontSize: "16px",
    color: "#434343",
    fontWeight: "bold",
  },
  amountChar: {
    fontSize: "15px",
    color: "#434343",
    fontWeight: "bold",
  },
  divideItem: {
    textAlign: "left",
    fontSize: "25px",
    fontWeight: "bold",
    paddingTop: "6px !important",
  },
});

export default dashboardStyle;
