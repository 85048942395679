import { fetchAsPost } from "./api.service";
// import { alertActions } from "../actions/alert.actions";
import { API_HOME } from "../helpers/apiURL";
import { handleResData } from "./api.handleRespone";


export async function fetchData(params, dispatch) {
  const res = await fetchAsPost(API_HOME.GET, params);
  return handleResData(res, dispatch);

  // if (!res.isInvalid) {
  //   return res;
  // }

  // if (!res.isOk) {
  //   const { error_code, message } = res.errors;
  //   if (error_code === 400) {
  //     dispatch(
  //       alertActions.error({ error_code, message: Object.values(message)[0] })
  //     );
  //   }
  // }

  // return res;
}
