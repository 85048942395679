import React, { useEffect, useState } from "react";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Moment from "moment";
import evercallIcon from "../../../assets/img/evercall-icon.png";
import { renderColorBadge, renderWeekday } from "../../../helpers";
import FileCallHistory from "../../Histories/Components/FileCallHistory";

function TelHistory(props) {
  const { data } = props;
  const [urlRecord, setUrlRecord] = useState("");
  useEffect(() => {
    setUrlRecord(data.media_source);
  }, [data.media_source]);

  const badge = (
    <Box>
      <i className="badge-icon material-icons">flag</i>
      {data.result_name}
    </Box>
  );

  return (
    <Card className="tel-history-card ">
      <CardBody className="tel-history-info">
        <GridContainer
          className="summary-detail"
          direction="row"
          alignItems="center"
        >
          <GridItem xs={6}>
            <i className="call-icon material-icons">call</i>
            <img className="tel-history-icon" src={evercallIcon} />
            <span className="text-bold result"> 発信</span>
          </GridItem>
          <GridItem className="tel-history-time-detail" xs={6} align="right">
            {Moment.utc(data.tel_time).format("YYYY-MM-DD HH:mm:ss")}
          </GridItem>
          <GridItem xs={12}>
            <Box sx={{ py: 2 }}>{data.memo}</Box>
          </GridItem>
        </GridContainer>
        <GridContainer className="detail" direction="row" alignItems="center">
          <GridItem xs={6}>
            <h5 className="title">結果</h5>
            <Box sx={{ display: "flex", pb: 1 }} alignItems="center">
              <Badge
                color="primary"
                badgeContent={badge}
                className={
                  "customer-list-badge-" + renderColorBadge(data.result)
                }
              ></Badge>
            </Box>
          </GridItem>
          <GridItem xs={6}>
            <h5 className="title">レベル</h5>
            <Box sx={{ display: "flex", pb: 1 }} alignItems="center">
              {data.level_name ? (
                <Badge
                  color="primary"
                  badgeContent={data.level_name}
                  className={"customer-list-badge-info"}
                ></Badge>
              ) : (
                ""
              )}
            </Box>
          </GridItem>
        </GridContainer>
        <GridContainer className="detail" direction="row" alignItems="center">
          <GridItem xs={6}>
            <h5 className="title">曜日</h5>
            <p>{renderWeekday(data.week_int)}</p>
          </GridItem>
          <GridItem xs={6}>
            <h5 className="title">電話時間</h5>
            <p>{data.time_int}:00</p>
          </GridItem>
        </GridContainer>
        <GridContainer className="detail" direction="row" alignItems="center">
          <GridItem xs={6}>
            <h5 className="title">内線番号</h5>
            <p>{data.exten_name}</p>
          </GridItem>
          <GridItem xs={6}>
            <h5 className="title">担当者</h5>
            <p>{data.user_name}</p>
          </GridItem>
        </GridContainer>
        <GridContainer className="detail" direction="row" alignItems="center">
          <GridItem xs={6}>
            <h5 className="title">再TEL</h5>
            <p>
              {data.prospective_info && data.prospective_info.next_call_time
                ? Moment.utc(data.prospective_info.next_call_time).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                : ""}
            </p>
          </GridItem>
          <GridItem xs={6}>
            <h5 className="title">クレーム</h5>
            <p>{data.claim_group_name}</p>
          </GridItem>
        </GridContainer>
        <GridContainer className="detail" direction="row" alignItems="center">
          <GridItem xs={12}>
            <h5 className="title">オペレータ</h5>
            <p>{data.user_name}</p>
          </GridItem>
        </GridContainer>
        {urlRecord && (
          <GridContainer className="detail" alignItems="center">
            <GridItem xs={12} align="center">
              <FileCallHistory source={urlRecord} />
            </GridItem>
          </GridContainer>
        )}
      </CardBody>
    </Card>
  );
}

export default TelHistory;
