import React, { useState, useEffect } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/salto-react/views/loginPageStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useValidate } from "../../hooks/useValidate";
import LoadingButton from "@mui/lab/LoadingButton";
import LoginIcon from '@mui/icons-material/Login';


import { userActions, alertActions } from "actions";

const useStyles = makeStyles(styles);

/** function login page */
function LoginPage() {
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const { username, password } = inputs;
  const users = useSelector((state) => state.users);
  const [error, setError] = useState({ isError: false });
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [formValid, setFormValid] = useState(
    {
      "username":true,
      "password":true
    }
  );

  const dispatch = useDispatch();
  const classes = useStyles();

  // const passwordInputRef = React.createRef();
  // const usernameInputRef = React.createRef();

  //user effect show focus input error
  useEffect(() => {
    if (isEmpty(users.error_login) === false) {
      // handleFocus(users.error_login);
      return setError(getErrors(users.error_login));
    }
  }, [users.error_login]);


  /**
   * Click button login
   * @returns {}
   */
  function normalLogin() {
    setIsLoadingBtn(true);
    dispatch(alertActions.clear());
    dispatch(userActions.login(username, password));
    setIsLoadingBtn(false);
  }

  /**
   * Submit when enter, set value input when onChange
   * @param {object} e
   * @returns {}
   */
  function handleKeyUp(e) {
    if (e.keyCode === 13) {
      normalLogin();
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    const [isError, key, message] = useValidate(name, value);

    setError((state) => ({
      ...state,
      [key]: isError ? message : null,
      isError,
    }));
    setFormValid((state) => ({
      ...state,
      [key]:isError
    }));

    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function getErrors(data) {
    const error = Object.keys(data).reduce((obj, key) => {
      obj[key] = data[key][0];
      return obj;
    }, {});

    return { ...error, isError: true };
  }

  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={6} md={6}>
          <form>
            <Card login>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Sign in</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email..."
                  success={error.username !== undefined && error.username !== ""}
                  error={isEmpty(error.username) ? false: true}
                  helperText={error.username}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  value={username}
                  inputProps={{
                    name:"username",
                    onChange: (e) => {
                      handleChange(e);
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    required: true,
                  }}
                />
                <CustomInput
                  labelText="Password"
                  success={!isEmpty(error.password)}
                  error={isEmpty(error.password) ? false: true}
                  helperText={error.password}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  value={password}
                  inputProps={{
                    name:"password",
                    onChange: (e) => {
                      handleChange(e);
                    },
                    onKeyUp: (e) => {
                      handleKeyUp(e);
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                    maxLength: 255,
                    required: true,
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <LoadingButton
                    color="primary"
                    disabled={formValid.username || formValid.password}
                    loading={isLoadingBtn}
                    loadingPosition="start"
                    startIcon={<LoginIcon />}
                    variant="contained"
                    onClick={normalLogin}
                  >
                      Sign In
                  </LoadingButton>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export { LoginPage };
