export const URL = {
  AUTH_LOGIN: "/auth/login",
  DAHSBOARD: `/dashboard`,
  LOGIN: "/login",
  ACCOUNT: `/accounts`,
  EC_SETTING: "/ec-setting",
  HISTORY: "/histories",
  HISTORY_DETAIL: "/histories/detail",
  CUSTOMER: "/customers",
  URL_CUSTOMER_DETAILS: "/customer/details/:customerNo", // TODO REMOVE
  CUSTOMER_DETAIL: "/customers/:customer_no",
  KINTONE_SETTING: "/kintone-setting",
  KINTONE_CUS_MAPPING: "/ktn-customer-mapping",
  KINTONE_HIS_MAPPING: "/ktn-history-mapping",
  KTNTONE_BATCH_SETTING: "/kintone-batch-setting",
  SF_SETTING: "/salesforce-setting",
  SF_CUS_MAPPING: "/sf-customer-mapping",
  SF_HIS_MAPPING: "/sf-call-history-mapping",
  SF_BATCH_SETTING: "/salesforce-batch-setting",
  STATUS: "/status",
};

const API_URL = process.env.REACT_APP_API_URL;

// api user
export const USER_API = {
  AUTH: API_URL + "/api/auth/",
  LOGOUT: API_URL + "/api/logout/",
  LIST: API_URL + "/api/users",
  REGISTER: API_URL + "/users/register",
  ACTION: API_URL + "/api/user",
};

// api salesforce setting
export const SALESFORCE_SETTING_API = {
  GET: API_URL + "/api/salesforce-setting",
  UPDATE: API_URL + "/api/salesforce-setting",
};

// api salesforce history mapping
export const SALESFORCE_HISTORY_MAPPING_API = {
  GET: API_URL + "/api/call-history-mapping",
  UPDATE: API_URL + "/api/call-history-mapping",
  GET_SALESFORCE_HISTORY_OBJ: API_URL + "/api/salesforce-history-obj",
  GET_EVERCALL_HISTORY_SCHEMA: API_URL + "/api/histories-structure-evercall",
};

// api salesforce customer mapping
export const SALESFORCE_CUSTOMER_MAPPING_API = {
  GET: API_URL + "/api/customer-mapping",
  UPDATE: API_URL + "/api/customer-mapping",
  GET_SALESFORCE_CUSTOMER_OBJ: API_URL + "/api/salesforce-customer-obj",
  GET_EVERCALL_CUSTOMER_SCHEMA: API_URL + "/api/customer-structure",
};

// api salesforce batch setting
export const SALESFORCE_BATCH_SETTING_API = {
  GET: API_URL + "/api/salesforce-batch-setting",
  UPDATE: API_URL + "/api/salesforce-batch-setting",
  DELETE: API_URL + "/api/salesforce-batch-setting",
  GETSALESFORCEFILTERCONDITION: API_URL + "/api/salesforce-filter-condition",
  GETEVERCALLLISTSELECTED: API_URL + "/api/evercall-list-selected",
  GETBATCHLOGS: API_URL + "/api/batch-logs",
  GETCAPTURELOGS: API_URL + "/api/capture-logs",
};

export const CALL_HISTORY_LIST_API = {
  GET: API_URL + "/api/history",
  EXPORT_CSV: API_URL + "/api/history/export",
  UPDATE_SF: API_URL + "/api/salesforce/history/update",
  GETRECORD: API_URL + "/api/salesforce/get-record-file",
  UPDATE_KT: API_URL + "/api/kintone/history/update",
};

// api evercall setting
export const EVERCALL_SETTING_API = {
  GET: API_URL + "/api/ec-setting",
  UPDATE: API_URL + "/api/ec-setting",
};

// api get salesforce history shema
export const SALESFORCE_HISTORY_SCHEMA_API = {
  GET: API_URL + "/api/salesforce-history-schema",
};

// api get salesforce customer shema
export const SALESFORCE_CUSTOMER_SCHEMA_API = {
  GET: API_URL + "/api/salesforce-customer-schema",
};

// api get evercall list list
export const EVERCALL_LIST_LIST_API = {
  GET: API_URL + "/api/evercall-list-list",
};

// api get evercall list list group
export const EVERCALL_LIST_LIST_GROUP_API = {
  GET: API_URL + "/api/evercall-list-list-group",
};

// api get evercall result list
export const EVERCALL_RESULT_LIST_API = {
  GET: API_URL + "/api/result-patterns",
};

// api customer list
export const CUSTOMER_LIST_API = {
  GET: API_URL + "/api/customers",
  NEW_GET: API_URL + "/api/ new-customers",
  GETEXPORTDATA: API_URL + "/api/customers/export-csv",
  UPDATESALESFORCECUSTOMER: API_URL + "/api/salesforce/customers/update",
  UPDATEKINTONECUSTOMER: API_URL + "/api/kintone/customers/update",
  CHECKUPDATESALESFORCECUSTOMER: API_URL + "/api/salesforce/customers/check",
  UPDATEEVERCALLCUSTOMER: API_URL + "/api/customers/evercall/update",
  UPDATESWITCHCUSTOMER: API_URL + "/api/customer-toggle-switch",
};

export const CUSTOMER_DETAILS_API = {
  UPDATE_CUSTOMER_EC: API_URL + "/api/customer-update-details",
  UPDATE_CUSTOMER_MEMO: API_URL + "/api/customer-update-memo",
  GET_PRODUCTS_ORDER: API_URL + "/api/customer-products-order",
  GET_EC_CUSTOMER_DETAILS: API_URL + "/api/ec-customer-details",
  GET_EC_CUSTOMER_DETAIL_SETTINGS: API_URL + "/api/ec-customer-detail-settings",
  GET_PRODUCTS_LIST: API_URL + "/api/customer-products-list",
  GET_EC_CUSTOMER_DETAIL_CONFIG:
    API_URL + "/api/customer-call-phone-load-config",
  GET_EC_CUSTOMER_DETAIL_LEVEL_CONFIG:
    API_URL + "/api/customer-call-phone-load-level",
  STORE_CUSTOMER_CALL_PHONE_HISTORY: API_URL + "/api/store-call-phone",
  LIST_HISTORY: API_URL + "/api/history",
  GETEXPORT: API_URL + "/api/history/export",
  UPDATESF: API_URL + "/api/salesforce/history/update",
  GETRECORD: API_URL + "/api/salesforce/get-record-file",
};
// api history detail
export const HISTORY_DETAIL_API = {
  GET: API_URL + "/api/history-detail",
  GET_RECORD: API_URL + "/api/salesforce/get-record-file",
};

// api product
export const PRODUCT_API = {
  LIST: API_URL + "/api/mshouzai",
  ACTION: API_URL + "/api/mshouzai/",
};

export const GET_DASHBOARD_DATA = {
  GET: API_URL + "/api/dashboard",
};

export const GET_KINTONE_SETTING_DATA = {
  GET: API_URL + "/api/kintone-setting",
  UPDATE: API_URL + "/api/kintone-setting",
};

// api kintone batch setting
export const KINTONE_BATCH_SETTING_API = {
  GET: API_URL + "/api/kintone-batch-setting",
  UPDATE: API_URL + "/api/kintone-batch-setting",
  DELETE: API_URL + "/api/kintone-batch-setting",
  GETKINTONEFILTERCONDITION: API_URL + "/api/kintone-filter-condition",
  GETEVERCALLLISTSELECTED: API_URL + "/api/kintone-evercall-list-selected",
  GETBATCHLOGS: API_URL + "/api/batch-logs",
  GETCAPTURELOGS: API_URL + "/api/capture-logs",
};

// api get kintone customer shema
export const KINTONE_CUSTOMER_SCHEMA_API = {
  GET: API_URL + "/api/ktn-customer-schema",
};

// api get kintone history shema
export const KINTONE_HISTORY_SCHEMA_API = {
  GET: API_URL + "/api/ktn-history-schema",
};

// api kintone customer mapping
export const KINTONE_CUSTOMER_MAPPING_API = {
  GET: API_URL + "/api/ktn-customer-mapping",
  GET_KTN_CUS_SCHEMA: API_URL + "/api/ktn-customer-schema",
  GET_EC_CUS_SCHEMA: API_URL + "/api/customer-structure",
};

// api kintone history mapping
export const KINTONE_HISTORY_MAPPING_API = {
  GET: API_URL + "/api/ktn-history-mapping",
  GET_KTN_HIS_SCHEMA: API_URL + "/api/ktn-history-schema",
  GET_EC_HIS_SCHEMA: API_URL + "/api/histories-structure-evercall",
};

export const ERROR = {
  PAGE_403: "403",
  PAGE_404: "404",
};

export const RESULT_CONDITIONS = {
  GET: API_URL + "/api/results-conditions-initial",
  POST: API_URL + "/api/results-conditions-initial",
};

export const M_STATUS_API = {
  GET: API_URL + "/api/status-managers",
  UPDATE: API_URL + "/api/status-manager",
};
