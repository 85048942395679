import { API_EC } from "../helpers/apiURL";
import { fetchAsGet, fetchAsPost } from "./api.service";
import { handleResData } from "./api.handleRespone";

export async function fetchSetting(dispatch) {
  const res = await fetchAsGet(API_EC.GET);
  return handleResData(res, dispatch);
}

export async function fetchUpdateEC(params, dispatch) {
  const res = await fetchAsPost(API_EC.GET, params);
  return handleResData(res, dispatch, true);
}

export async function fetchUpdateResult(params, dispatch) {
  const res = await fetchAsPost(API_EC.UPDATE, params);
  return handleResData(res, dispatch, true);
}
