import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Search from "@material-ui/icons/Search";
import { Pagination } from "@material-ui/lab";
import { visuallyHidden } from "@mui/utils";
import clsx from "clsx";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, columns } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align={column.align ? "right" : "left"}
            padding={column.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === column.id ? order : false}
            style={{ minWidth: column.minWidth, padding:"5px" }}
          >
            {column.sortable ? (
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : "asc"}
                onClick={createSortHandler(column.id)}
              >
                {column.label}
                {orderBy === column.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              column.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function DataTable(props) {
  const {
    rows,
    columns,
    rowsPerPageOptions,
    pagination,
    height,
    defaultRowsPerPage,
    isLoading,
    searchBar,
    classData,
  } = props;
  const [rowData, setRowData] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [searched, setSearched] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(
    defaultRowsPerPage ?? 10
  );

  const columnDatas = columns.map((column) => {
    return {
      id: column.key,
      label: column.label,
      minWidth: column.minWidth ?? undefined,
      wordBreak: column.wordBreak ?? undefined,
      align: column.align ?? undefined,
      sortable: column.sortable ?? true,
    };
  });

  useEffect(() => {
    if (!defaultRowsPerPage) {
      setRowsPerPage(rows.length);
    }

    setRowData(rows);
  }, [rows]);

  function handleChangePage(event, newPage) {
    setPage(newPage - 1);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function descendingComparator(a, b, orderBy) {
    if (a[orderBy] && b[orderBy]) {
      if (
        typeof a[orderBy] !== "string" &&
        typeof b[orderBy] !== "string" &&
        typeof a[orderBy] !== "number" &&
        typeof b[orderBy] !== "number"
      ) {
        if (b[orderBy].props.value < a[orderBy].props.value) {
          return -1;
        }
        if (b[orderBy].props.value > a[orderBy].props.value) {
          return 1;
        }
        return 0;
      }
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function handleRequestSort(event, property) {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);

      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function requestSearch(searchedVal) {
    setSearched(searchedVal);
    const filteredRows = rows.filter((row) => {
      const keys = Object.keys(row);
      const result = keys.map((key) => {
        if (typeof row[key] === "string") {
          return row[key].toLowerCase().includes(searchedVal.toLowerCase());
        }
        return false;
      });
      return result.includes(true);
    });
    setRowData(filteredRows);
  }

  return (
    <Paper
      className={classData ? clsx("data-table", classData) : "data-table"}
      sx={{ width: "100%", overflow: "hidden" }}
    >
      {searchBar ? (
        <GridContainer justifyContent="flex-end">
          <GridItem>
            <CustomInput
              inputProps={{
                value: searched,
                onChange: (e) => requestSearch(e.target.value),
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}
      <TableContainer style={{ maxHeight: height }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            rowCount={rowData.length}
            onRequestSort={handleRequestSort}
            columns={columnDatas}
          />
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell align="center" colSpan={columnDatas.length}>
                  <Spinner className="text-light" animation="grow" />
                  <Spinner className="text-light" animation="grow" />
                  <Spinner className="text-light" animation="grow" />
                </TableCell>
              </TableRow>
            ) : rowData.length > 0 ? (
              stableSort(rowData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columnDatas.map((column) => {
                        return (
                          <TableCell
                            style={{ wordBreak: column.wordBreak }}
                            key={column.id}
                            align={column.align}
                          >
                            {row[column.id]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={columnDatas.length + 1}>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    該当データが存在しません。
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {rowData.length > 0 ? (
        <GridContainer
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <GridItem xs={6}>
            {pagination
              ? `Showing ${page * rowsPerPage + 1} to
            ${
              rowData.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).length +
              page * rowsPerPage
            } of ${rowData.length}
            entries`
              : ""}
          </GridItem>
          <GridItem xs={6}>
            {pagination ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
                align="right"
              >
                <TablePagination
                  className="hide-pagination"
                  rowsPerPageOptions={rowsPerPageOptions ?? []}
                  component="div"
                  count={rowData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Pagination
                  count={Math.ceil(rowData.length / rowsPerPage)}
                  page={page + 1}
                  className="pagination-rose"
                  onChange={handleChangePage}
                />
              </Box>
            ) : (
              ""
            )}
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}
    </Paper>
  );
}

DataTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  pagination: PropTypes.bool,
  height: PropTypes.number,
  defaultRowsPerPage: PropTypes.number,
  isLoading: PropTypes.bool,
  searchBar: PropTypes.bool,
  classData: PropTypes.string,
};

export default DataTable;
