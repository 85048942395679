import {
  cardTitle,
  grayColor,
  successColor,
  tooltip,
} from "assets/jss/salto-react.js";
import hoverCardStyle from "assets/jss/salto-react/hoverCardStyle.js";

const dashboardStyle = {
  ...hoverCardStyle,
  tooltip,
  cardTitle: {
    ...cardTitle,
    marginTop: "20px",
    marginBottom: "3px",
    paddingTop: "15px",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "55px",
    marginBottom: "0px",
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "center",
  },
  cardCategory: {
    color: "#7B809A",
    fontSize: "20px",
    paddingTop: "20px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0",
  },
  cardProductDesciprion: {
    textAlign: "center",
    color: grayColor[0],
  },
  stats: {
    color: grayColor[0],
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px",
    },
  },
  productStats: {
    paddingTop: "7px",
    paddingBottom: "7px",
    margin: "0",
  },
  successText: {
    color: successColor[0],
  },
  upArrowCardCategory: {
    width: 14,
    height: 14,
  },
  underChartIcons: {
    width: "17px",
    height: "17px",
  },
  price: {
    color: "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px",
    },
  },
  cardHeaderWrap: {
    height: "100px",
  },
  numberContent: {
    fontSize: "30px",
    fontWeight: "bold",
  },
  subjectContent: {
    color: "#4CAF50 !important",
    fontWeight: "bold !important",
  },
};

export default dashboardStyle;
