import { API_KINTONE, API_EC } from "../helpers/apiURL";
import { fetchAsGet, fetchAsPut, fetchAsDelete } from "./api.service";
import { handleResData } from "./api.handleRespone";

export async function fetchSetting(dispatch) {
  const res = await fetchAsGet(API_KINTONE.BATCH_SETTING);
  return handleResData(res, dispatch);
}

export async function fetchECListByGroupdId(groupId, dispatch) {
  const res = await fetchAsGet(`${API_EC.LIST}/${groupId}`);
  return handleResData(res, dispatch);
}

export async function fetchUpdate(params, dispatch) {
  const res = await fetchAsPut(API_KINTONE.BATCH_SETTING, params);
  return handleResData(res, dispatch, true);
}

export async function fetchDelete(payload, dispatch) {
  const res = await fetchAsDelete(API_KINTONE.BATCH_SETTING, payload);
  return handleResData(res, dispatch, true);
}
