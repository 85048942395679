import { API_KINTONE } from "../helpers/apiURL";
import { fetchAsPost, fetchAsGet, fetchAsPut } from "./api.service";
import { handleResData } from "./api.handleRespone";

export async function fetchSetting(dispatch) {
  const res = await fetchAsGet(API_KINTONE.SETTING);
  return handleResData(res, dispatch);
}

export async function fetchUpdateSetting(body, dispatch) {
  const res = await fetchAsPost(API_KINTONE.SETTING, body);
  return handleResData(res, dispatch, true);
}

export async function fetchUpdateSwitch(body, dispatch) {
  const res = await fetchAsPut(API_KINTONE.SETTING, body);
  return handleResData(res, dispatch);
}
