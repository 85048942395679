import { history } from "helpers";
import { ERROR } from "helpers/url";

export const commonListService = {
  convertObjToQueryString,
  handleResponse,
  logout,
};

/**
 * Get data of customer list
 * @param {paramObj}
 * @returns {object} data of customer
 */
export function convertObjToQueryString(params) {
  let queryString = "?";
  Object.keys(params).map((key) => {
    queryString += `${key}=${params[key]}&`;
  });

  return queryString;
}

/**
 * Handle response
 * @param {object} response data
 * @returns {any} data or error message
 */
function handleResponse(response) {
  return response.text().then((text) => {
    // Check text is exist and parse to json
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }
      if (response.status === 403) {
        history.push(ERROR.PAGE_403);
      }

      // return error message
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    // Return data
    return data;
  });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}
