import { evercallMessages } from "../../../messages";

export function useValidate(key, val) {
  let message = "";
  let isError = false;
  if (!val.length) {
    message = evercallMessages[key]["required"];
    isError = true;
  }

  if (key === "access_key" && val.length !== 6) {
    message = evercallMessages[key]["length"];
    isError = true;
  }

  if (key === "password" && val.length < 6) {
    message = evercallMessages.password.min_length;
    isError = true;
  }

  if (val.length > 255) {
    message = evercallMessages.username.max_length;
    isError = true;
  }

  return [isError, key, message];
}
