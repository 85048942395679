// import modalStyle from "assets/jss/salto-react/modalStyle.js";

const callHistoryStyle = () => ({
  titleHistoryPage: {
    fontWeight: "bold",
    color: "black",
    fontSize: "22px",
  },
  descHistoryPage: {
    color: "#7B809A",
    fontSize: "12px",
  },
  buttonBox: {
    textAlign: "right",
  },
  tableCallHistoryList: {
    fontSize: "10px",
  },
  labelIconNameText: {
    marginLeft: "4px",
  },
  iconCallResult: {
    width: "16px",
  },
  callResultLabel: {
    alignItems: "baseline",
  },
});

export default callHistoryStyle;
