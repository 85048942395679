import CloudIcon from "@material-ui/icons/Cloud";
import ContactPhone from "@material-ui/icons/ContactPhone";
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import PhoneInTalk from "@material-ui/icons/PhoneInTalk";
import FilterDramaIcon from "@mui/icons-material/FilterDrama";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { Account } from "views/Account";
import Dashboard from "views/Dashboard";
import ErrorPage403 from "views/Errors/403";
import ErrorPage404 from "views/Errors/404";
import { EvercallSetting } from "views/EvercallSetting";
import History from "views/Histories";
import KintoneSetting from "views/Kintone/setting";
import SFSetting from "views/Salesforce/Setting";
import SFHisMapping from "views/Salesforce/HisMapping";
import SFCusMapping from "views/Salesforce/CusMapping";
import SFBatch from "views/Batch/salesforce";
import KTHisMapping from "views/Kintone/HisMapping";
import KTCusMapping from "views/Kintone/CusMapping";
import KTBatch from "views/Batch/kintone";

import { StatusManager } from "views/StatusManager";
import Customer from "views/Customer";
import { CONSTANT } from "./constants/constant";
import { URL } from "./helpers/url";


// 0: "ec_api.setting"
// 1: "salesforce.general_setting"
// 2: "salesforce.mapping_setting"
// 3: "kintone.data_linkage"
// 4: "management.status"
// 5: "salesforce.batch_setting"
// 6: "kintone.mapping_setting"
// 7: "management.account"
// 8: "kintone.general_setting
const dashRoutes = [
  {
    redirect: true,
    id: "403",
    path: "/403",
    name: "403",
    component: ErrorPage403,
    layout: CONSTANT.PREFIX_ADMIN,
    permission: "all",
  },
  {
    redirect: true,
    id: "404",
    path: "/404",
    name: "404",
    component: ErrorPage404,
    layout: CONSTANT.PREFIX_ADMIN,
    permission: "all",
  },
  {
    id: "dashboard",
    path: URL.DAHSBOARD,
    name: "ダッシュボード",
    icon: DashboardIcon,
    component: Dashboard,
    layout: CONSTANT.PREFIX_ADMIN,
    permission: "all",
  },

  {
    id: "customer",
    path: URL.CUSTOMER,
    name: "顧客一覧",
    icon: ContactPhone,
    component: Customer,
    layout: CONSTANT.PREFIX_ADMIN,
    permission: "all",
  },
  {
    id: "history",
    path: URL.HISTORY,
    name: "通話履歴一覧",
    icon: SupportAgentIcon,
    component: History,
    layout: CONSTANT.PREFIX_ADMIN,
    permission: "all",
  },

  {
    name: "設定",
    layout: CONSTANT.PREFIX_ADMIN,
    disable: true,
    permission: "all",

  },

  {
    id: "account",
    path: URL.ACCOUNT,
    name: "ユーザー管理",
    icon: ManageAccountsIcon,
    component: Account,
    layout: CONSTANT.PREFIX_ADMIN,
    permission: "management.account"
  },
  {
    id: "statusManager",
    path: URL.STATUS,
    name: "ステータス管理",
    icon: TableRowsIcon,
    component: StatusManager,
    layout: CONSTANT.PREFIX_ADMIN,
    permission: "management.status"
  },
  {
    isShow: false,
    level: 0,
    id: "salesforce",
    name: "Salesforce連携",
    icon: CloudIcon,
    collapse: true,
    permission: "all",
    views: [
      {
        id: "salesforce-setting",
        path: URL.SF_SETTING,
        name: "一般",
        component: SFSetting,
        layout: CONSTANT.PREFIX_ADMIN,
        icon: "G",
        permission: "salesforce.general_setting"
      },
      {
        id: "item-mapping",
        name: "項目マッピング",
        icon: "U",
        collapse: true,
        isShow: false,
        level: 1,
        parent: "salesforce",
        permission: "salesforce.mapping_setting",
        views: [
          {
            id: "sf-customer-mapping",
            path: URL.SF_CUS_MAPPING,
            name: "顧客項目",
            icon: "CM",
            component: SFCusMapping,
            layout: CONSTANT.PREFIX_ADMIN,
            permission: "salesforce.mapping_setting",

          },
          {
            id: "sf-call-history-mapping",
            path: URL.SF_HIS_MAPPING,
            name: "対応履歴項目",
            icon: "HI",
            component: SFHisMapping,
            layout: CONSTANT.PREFIX_ADMIN,
            permission: "salesforce.mapping_setting",
          },
        ],
      },
      {
        id: "salesforce-batch-setting",
        path: URL.SF_BATCH_SETTING,
        name: "バッチ",
        component: SFBatch,
        layout: CONSTANT.PREFIX_ADMIN,
        icon: "S",
        permission: "salesforce.batch_setting"
      },
    ],
  },
  {
    isShow: false,
    level: 0,
    id: "kintone",
    name: "Kintone連携",
    icon: FilterDramaIcon,
    collapse: true,
    permission: "all",
    views: [
      {
        id: "kintone-setting",
        path: URL.KINTONE_SETTING,
        name: "一般",
        component: KintoneSetting,
        layout: CONSTANT.PREFIX_ADMIN,
        icon: "G",
        permission: "kintone.general_setting",

      },
      {
        id: "item-mapping",
        name: "項目マッピング",
        icon: "U",
        collapse: true,
        isShow: false,
        level: 1,
        parent: "kintone",
        permission: "kintone.mapping_setting",
        views: [
          {
            id: "ktn-customer-mapping",
            path: URL.KINTONE_CUS_MAPPING,
            name: "顧客項目",
            icon: "CM",
            component: KTCusMapping,
            layout: CONSTANT.PREFIX_ADMIN,
            permission: "kintone.mapping_setting",

          },
          {
            id: "ktn-history-mapping",
            path: URL.KINTONE_HIS_MAPPING,
            name: "対応履歴項目",
            icon: "HI",
            component: KTHisMapping,
            layout: CONSTANT.PREFIX_ADMIN,
            permission: "kintone.mapping_setting",

          },
        ],
      },
      {
        id: "kintone-batch-setting",
        path: URL.KTNTONE_BATCH_SETTING,
        name: "バッチ",
        component: KTBatch,
        layout: CONSTANT.PREFIX_ADMIN,
        icon: "S",
        permission: "kintone.batch_setting",

      },
    ],
  },
  {
    isShow: false,
    level: 0,
    id: "everApi",
    name: "Ever API",
    icon: PhoneInTalk,
    collapse: true,
    permission: "ec_api.setting",
    views: [
      {
        id: "ec-setting",
        parent: "everApi",
        path: URL.EC_SETTING,
        name: "EverCall",
        component: EvercallSetting,
        layout: CONSTANT.PREFIX_ADMIN,
        permission: "ec_api.setting",
        icon: "EC",
      },
    ],
  },
];
export default dashRoutes;
