import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SearchExtend from "./Components/SearchExtendComponent";
import HistoryListTable from "./Components/HistoryListComponent";
import { useSearchParam } from "./hooks/useSearchParam";

export default function index() {
  const userInfo = useSelector((state) => state.authentication.user);
  const { search } = useLocation();
  const [open, setOpen] = useState(true);
  const [params, setParams] = useState(search);
  const searchExtend = useSearchParam(params);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title = "通話履歴一覧";
  }, []);

  return (
    <>
      <Box className="backdrop">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
      <GridContainer spacing={3}>
        <GridItem xs={12}>
          <SearchExtend
            userInfo={userInfo}
            searchExtend={searchExtend}
            onChangeParam={(params) => setParams(params)}
            isLoading={isLoading}
            onLoading={(loading) => setIsLoading(loading)}
          />
        </GridItem>
        <GridItem xs={12}>
          <HistoryListTable
            userInfo={userInfo}
            searchExtend={searchExtend}
            onChangeParam={(params) => setParams(params)}
            onLoading={(loading) => {
              setIsLoading(loading);
              setOpen(false);
            }}
          />
        </GridItem>
      </GridContainer>
    </>
  );
}
