import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import DataTable from "components/DataTable/DataTable.js";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchBatchLog } from "../../../services/api.sfBatch";

export default function BatchLog(props) {
  const { batchCode } = props;
  const dispatch = useDispatch();
  const [batchLog, setBatchLog] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (batchCode) {
      fetchLog();
    }
  }, [batchCode]);

  async function fetchLog() {
    setIsLoading(true);
    const res = await fetchBatchLog(batchCode, dispatch);
    setBatchLog(res.data);
    setIsLoading(false);
  }

  const rows = batchLog.map((batch) => {
    const batch_type = batch.log_type === 1 ? "info" : "warning";

    const log_type = (
      <i
        className={`material-icons text-sm text-${batch_type}`}
        value={batch_type}
      >
        {batch_type}
      </i>
    );

    const time_stamp = Moment.utc(batch.time_stamp).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    return {
      ...batch,
      log_type,
      time_stamp,
    };
  });

  return (
    <Card className="batch-log-card">
      <CardHeader color="info" icon>
        <CardIcon color="info" className="setting-card-icon">
          <i className="material-icons opacity-10">history_edu</i>
        </CardIcon>
        <h5 className="schedule-setting-title text-bold text-dark-grey">
          {["BATCH_01", "BATCH_04"].includes(batchCode) ? "リスト取込バッチのログ" : ""}
          {["BATCH_02", "BATCH_05"].includes(batchCode) ? "結果反映バッチのログ" : ""}
          {["BATCH_03", "BATCH_06"].includes(batchCode) ? "通話履歴更新バッチのログ" : ""}
        </h5>
      </CardHeader>
      <CardBody>
        <DataTable
          rows={rows}
          columns={[
            { key: "log_type", label: "#" },
            { key: "time_stamp", label: "日付", minWidth: 200 },
            { key: "batch_name", label: "バッチ名", minWidth: 150 },
            { key: "object_name", label: "オブジェクト", minWidth: 170 },
            { key: "list_group_name", label: "	リストグループ", minWidth: 190 },
            { key: "list_name", label: "リスト", minWidth: 120 },
            { key: "content", label: "内容", minWidth: 400 },
          ]}
          height={400}
          isLoading={isLoading}
          searchBar
        />
      </CardBody>
    </Card>
  );
}
