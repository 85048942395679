import { authHeader } from "../helpers";
import { USER_API } from "../helpers/url";
import * as commonService from "./common.service";
import { handleResData } from "./api.handleRespone";

import {
  fetchAsGet,
  fetchAsPost,
  fetchAsPut,
  fetchAsDelete,
} from "./api.service";

export const userService = {
  login,
  logout,
  getById,
};

/**
 * Call api login
 * @param {string} username=""
 * @param {string} password=""
 * @param {boolean} remember=false
 * @returns {user}
 */
function login(username = "", password = "", remember = false) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password, remember }),
  };

  return fetch(USER_API.AUTH, requestOptions)
    .then(commonService.commonListService.handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user));
      return user;
    });
}

/**
 * Call api logout
 * @returns {any}
 */
function logout() {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  return fetch(USER_API.LOGOUT, requestOptions)
    .then(handleResponseLogout)
    .then(() => {
      // remove user from local storage to log user out
      localStorage.removeItem("user");
    });
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${USER_API.ACTION}${id}`, requestOptions).then(
    commonService.commonListService.handleResponse
  );
}

function handleResponseLogout(response) {
  if (response.ok) {
    location.reload(true);
  }
}

export async function getUsers(dispatch) {
  const res = await fetchAsGet(USER_API.LIST);
  return handleResData(res, dispatch);

}

export async function registerUser(body, dispatch) {
  const res = await fetchAsPost(USER_API.ACTION, body);
  return handleResData(res, dispatch, true);

}

export async function updateUser(body, dispatch) {
  const res = await fetchAsPut(`${USER_API.ACTION}/${body.user.uuid}`, body);
  return handleResData(res, dispatch, true);

}

export async function deleteUser(id, dispatch) {
  const res = await fetchAsDelete(`${USER_API.ACTION}/${id}`);
  return handleResData(res, dispatch, true);
}
