import React, { useState } from "react";
import { Box, Icon } from "@material-ui/core";
import { Stack, Chip, Button } from "@mui/material";
import { green } from "@mui/material/colors";
import Moment from "moment";

export default function BikosTemplate(props) {
  const { name, text, options, disabled, onChange } = props;
  const [value, setValue] = useState(text ? text.split("\n") : []);
  const weekday = ["日","月","火","水","木","金","土"];

  function handleChange(event) {
    const val = event.target.value;
    setValue([val]);
    onChange([val].join("\n"));
  }

  function handleClick(val) {
    addValue(val);
  }

  function addValue(val) {
    const str = [...value];
    let changeValue = val;

    if (val === "日付") {
      const date = Moment();
      changeValue = `${date.format("YYYY-MM-DD")}(${weekday[date.day()]})${date.format("HH:mm")}`;
    }
    str.unshift(changeValue);
    setValue(str);
    onChange(str.join("\n"));
  }

  return (
    <Box sx={{ pt: 3 }}>
      <Button color="secondary">テンプレート利用</Button>
      <Stack direction="row" spacing={1} sx={{ pt: 2, pb: 2 }}>
        {options.map((item, idx) => (
          <Chip
            key={`${name}${idx}`}
            disabled={disabled}
            color="success"
            label={item}
            variant="outlined"
            onClick={() => handleClick(item)}
            onDelete={() => handleClick(item)}
            deleteIcon={<Icon sx={{ color: green[500] }}>add_circle</Icon>}
          />
        ))}
      </Stack>
      <textarea
        className="textinput"
        name={name}
        value={value.join("\n")}
        onChange={(e) => handleChange(e)}
        disabled={disabled}
        maxLength="255"
        autoFocus
      />
    </Box>
  );
}
