import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Badge from "@material-ui/core/Badge";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SaveIcon from "@mui/icons-material/Save";
import Edit from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LoadingButton from "@mui/lab/LoadingButton";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import Card from "components/Card/Card.js";
import { isEmpty } from "lodash";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import MultipleSelectChip from "./Select";
import BikosTemplate from "./BikosTemplate";
import {
  selectedOptionById,
  renderColorBadge,
  deleteObjKey,
  findObjByKey,
} from "../../../helpers/common";
import { fetchUpdate, fetchUpdateMemo } from "../../../services/api.cusDetail";
import { useMapModel, useValidate } from "../hooks/useCommon";

export default function Detail(props) {
  const { data, statuses, isLoading } = props;
  const dispatch = useDispatch();
  const [model, setModel, headGroup] = useMapModel({ data, isLoading });
  const [selectedIndex, setSelectedIndex] = useState("");
  const [value, setValue] = useState("bikos");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [inputErrors, setInputErrors] = useState({});

  function handleChangeTab(val) {
    setSelectedIndex("");
    setValue(val);
  }

  function handleClick(index) {
    if (selectedIndex === index) {
      setSelectedIndex("");
    } else {
      setSelectedIndex(index);
    }
  }

  async function handleSave() {
    setIsProcessing(true);
    await fetchUpdate(model, dispatch);
    setIsProcessing(false);
  }

  async function handleSaveMemo() {
    await fetchUpdateMemo({ customer_no: model.customer_no, memo: model.customer_memo_2 }, dispatch);
  }

  function handleBindvalue(k, val) {
    const [isError, key, message] = useValidate(k, val);

    if (isError) {
      setInputErrors((state) => ({
        ...state,
        [key]: message,
      }));
    } else {
      setInputErrors(deleteObjKey(k, inputErrors));
    }
    setModel((state) => ({
      ...state,
      [k]: val,
    }));
  }

  function renderBikoRow() {
    if (isLoading) {
      return [...Array(10)].map((_i, item) => {
        return (
          <Grid key={item} container spacing={2}>
            <Grid item xs={3} sm={3} md={3} lg={2}>
              <ListItemText primary={`備考 ${item + 1}`} />
            </Grid>
            <Grid item xs={9} sm={9} md={9} lg={10}>
              <Skeleton height={40} />
            </Grid>
          </Grid>
        );
      });
    }

    return (
      data.bikos &&
      data.bikos.map((item, idx) => {
        return (
          <Grid key={idx} container spacing={2}>
            <Grid item xs={3} sm={3} md={3} lg={2}>
              <ListItemText primary={item.name} />
            </Grid>
            <Grid item xs={9} sm={9} md={9} lg={10}>
              <Tooltip disableFocusListener title={model[item.key] ?? ""}>
                <TextField
                  name={item.key}
                  sx={{ minWidth: "100%" }}
                  variant="standard"
                  value={model[item.key] ?? ""}
                  disabled={!isEditing}
                  helperText={inputErrors[item.key] ?? ""}
                  onChange={(e) => handleBindvalue(item.key, e.target.value)}
                  error={inputErrors[item.key] ? true : false}
                  color={inputErrors[item.key] ? "error" : "success"}
                  inputProps={{ maxLength: 255 }}
                />
              </Tooltip>
            </Grid>
          </Grid>
        );
      })
    );
  }

  function setDetailValue(head_id, key, val) {
    const obj = { ...model };

    const objs = obj.details.map(item => item.head_id === head_id ? { ...item, [key]: val ?? "" } : item);

    setModel(state => ({
      ...state,
      details: objs
    }));
  }

  function getValue(head_id, item) {
    const data = { ...model };
    const found = findObjByKey(data.details, "head_id", head_id);
    if (!found) {
      return "";
    }

    return found[item.key] ?? "";
  }

  function renderPanelTab(head, head_id) {
    return head.groups.map((group, idx) => {
      if (group.text && group.name) {
        return (
          <Container key={group.group_id}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <ListItem
                sx={{ padding: "0px" }}
                key={idx}
                button
                onClick={() => {
                  handleClick(idx);
                }}
              >
                <ListItemText primary={group.name} />
                <IconButton>
                  {idx === selectedIndex ? (
                    <RemoveIcon color="success" />
                  ) : (
                    <AddIcon color="success" />
                  )}
                </IconButton>
              </ListItem>
            </Grid>
            <Divider style={{ marginBottom: "13px" }} />
            <Collapse in={idx === selectedIndex}>
              {group["text"].map((text, keyItem) => {
                return (
                  <Grid key={keyItem} container spacing={0}>
                    <Grid item xs={3} sm={4} md={4} lg={3}>
                      <ListItem>
                        <ListItemText primary={text.name} />
                      </ListItem>
                    </Grid>
                    <Grid item xs={9} sm={8} md={8} lg={9}>
                      <ListItem sx={{ minWidth: "100%" }}>
                        <Autocomplete
                          id={`details.${head_id}.${text.key}`}
                          value={getValue(head_id, text)}
                          sx={{ minWidth: "100%" }}
                          disabled={!isEditing}
                          freeSolo
                          options={text.template}
                          getOptionLabel={(option) => option}
                          name={`details.${head_id}.${text.key}`}
                          onInputChange={(e, val) => { e !== null && setDetailValue(head_id, text.key, val ?? ""); }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                maxLength: 255,
                              }}
                              variant="standard"
                              md={{ width: "100%", minWidth: "100%" }}
                            />
                          )}
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                );
              })}
            </Collapse>
          </Container>
        );
      }
      // If field check is exist in group
      if (group.check) {
        return (
          <Container key={group.group_id}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <ListItem
                sx={{ padding: "0px" }}
                key={idx}
                button
                onClick={() => {
                  handleClick(idx);
                }}
              >
                <ListItemText primary={group["name"]} />
                <IconButton>
                  {idx === selectedIndex ? (
                    <RemoveIcon color="success" />
                  ) : (
                    <AddIcon color="success" />
                  )}
                </IconButton>
              </ListItem>
            </Grid>
            <Divider style={{ marginBottom: "12px" }} />
            <Collapse in={idx === selectedIndex} timeout="auto" unmountOnExit>
              {group["check"].map((check) => {
                return (
                  <Grid key={check.key} container>
                    <Grid item xs={3} sm={4} md={4} lg={3}>
                      <ListItem>
                        <ListItemText primary={check.name} />
                      </ListItem>
                    </Grid>
                    <Grid item xs={9} sm={8} md={8} lg={9}>
                      <ListItem>
                        <MultipleSelectChip
                          keyId={`details.${head_id}.${check.key}`}
                          options={check.caption}
                          values={getValue(head_id, check)}
                          onChange={(val) => {
                            setDetailValue(head_id, check.key, val);
                          }}
                          disabled={!isEditing}
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                );
              })}
            </Collapse>
          </Container>
        );
      }
      // If field biko is exist in group and value is exist
      if (group.biko) {
        return (
          <Container key={group.group_id}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <ListItem
                sx={{ padding: "0px" }}
                key={idx}
                button
                onClick={() => {
                  handleClick(idx);
                }}
              >
                <ListItemText primary={group["name"]} />
                <IconButton>
                  {idx === selectedIndex ? (
                    <RemoveIcon color="success" />
                  ) : (
                    <AddIcon color="success" />
                  )}
                </IconButton>
              </ListItem>
            </Grid>
            <Divider />
            <Collapse in={idx === selectedIndex} timeout="auto" unmountOnExit>
              {
                <Grid key={group.biko.key} container>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <BikosTemplate
                      name={`details.${head_id}.${group.biko.key}`}
                      text={getValue(head_id, group.biko)}
                      options={[...group.biko.template, "日付"]}
                      disabled={!isEditing}
                      onChange={(val) => {
                        setDetailValue(head_id, group.biko.key, val);
                      }}
                    ></BikosTemplate>
                  </Grid>
                </Grid>
              }
            </Collapse>
          </Container>
        );
      }
    });
  }

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          right: 30,
          bottom: 30,
          zIndex: "modal",
        }}
      >
        <LoadingButton
          color="secondary"
          disabled={!isEmpty(inputErrors) || !isEditing}
          loading={isLoading || isProcessing}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          onClick={handleSave}
        >
          保存
        </LoadingButton>
      </Box>
      <GridItem xs={12} sm={5} md={5} lg={5} className="form-helper">
        <FormLabel component="legend">基本情報</FormLabel>
        <Card>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: "modal",
            }}
          >
            <Tooltip disableFocusListener title="編集">
              <IconButton
                className="button-change"
                color="success"
                onClick={() => setIsEditing(!isEditing)}
              >
                {isEditing ? <VisibilityIcon /> : <Edit />}
              </IconButton>
            </Tooltip>
          </Box>
          <Box component="span" className="min-height-customer-details-explain">
            <CardBody>
              <Grid container>
                <Grid item xs={3} sm={4} md={4} lg={3}>
                  <ListItem>
                    <ListItemText
                      style={{ marginTop: "10px" }}
                      primary="顧客番号"
                    />
                  </ListItem>
                </Grid>
                <Grid item xs={9} sm={8} md={8} lg={9}>
                  {isLoading ? (
                    <Skeleton height={40} width="30%" />
                  ) : (
                    <ListItem>
                      <Tooltip disableFocusListener title={model?.customer_no ?? ""}>
                        <Input
                          disabled
                          value={model?.customer_no ?? ""}
                          sx={{ minWidth: "100%" }}
                          color="success"
                        />
                      </Tooltip>
                    </ListItem>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3} sm={4} md={4} lg={3}>
                  <ListItem>
                    <ListItemText primary="お客様名" />
                  </ListItem>
                </Grid>
                <Grid item xs={9} sm={8} md={8} lg={9}>
                  {isLoading ? (
                    <Skeleton height={40} width="40%" />
                  ) : (
                    <ListItem>
                      <Tooltip disableFocusListener title={model?.name ?? ""}>
                        <TextField
                          variant="standard"
                          name="name"
                          disabled={!isEditing}
                          helperText={inputErrors["name"] ?? ""}
                          value={model?.name ?? ""}
                          onChange={(e) =>
                            handleBindvalue("name", e.target.value)
                          }
                          error={inputErrors.name ? true : false}
                          color={inputErrors.name ? "error" : "success"}
                          sx={{ minWidth: "100%" }}
                          inputProps={{ maxLength: 255 }}
                        />
                      </Tooltip>
                    </ListItem>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3} sm={4} md={4} lg={3}>
                  <ListItem>
                    <ListItemText primary="電話番号" />
                  </ListItem>
                </Grid>
                <Grid item xs={9} sm={8} md={8} lg={9}>
                  {isLoading ? (
                    <Skeleton height={40} width="50%" />
                  ) : (
                    <ListItem>
                      <Tooltip disableFocusListener title={model?.tel ?? ""}>
                        <Input
                          disabled
                          value={model?.tel ?? ""}
                          sx={{ minWidth: "100%" }}
                        />
                      </Tooltip>
                    </ListItem>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3} sm={4} md={4} lg={3}>
                  <ListItem>
                    <ListItemText primary="住所1" />
                  </ListItem>
                </Grid>
                <Grid item xs={9} sm={8} md={8} lg={9}>
                  {isLoading ? (
                    <Skeleton height={40} />
                  ) : (
                    <ListItem>
                      <Tooltip disableFocusListener title={model?.address1 ?? ""}>
                        <TextField
                          variant="standard"
                          name="address1"
                          disabled={!isEditing}
                          value={model.address1 ?? ""}
                          helperText={inputErrors["address1"] ?? ""}
                          onChange={(e) =>
                            handleBindvalue("address1", e.target.value)
                          }
                          error={inputErrors.address1 ? true : false}
                          color={inputErrors.address1 ? "error" : "success"}
                          sx={{ minWidth: "100%" }}
                          inputProps={{ maxLength: 255 }}
                        />
                      </Tooltip>
                    </ListItem>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3} sm={4} md={4} lg={3}>
                  <ListItem>
                    <ListItemText primary="住所2" />
                  </ListItem>
                </Grid>
                <Grid item xs={9} sm={8} md={8} lg={9}>
                  {isLoading ? (
                    <Skeleton height={40} />
                  ) : (
                    <ListItem>
                      <Tooltip disableFocusListener title={model?.address2 ?? ""}>
                        <TextField
                          variant="standard"
                          name="address2"
                          disabled={!isEditing}
                          value={model?.address2 ?? ""}
                          helperText={inputErrors["address2"] ?? ""}
                          onChange={(e) =>
                            handleBindvalue("address2", e.target.value)
                          }
                          error={inputErrors.address2 ? true : false}
                          color={inputErrors.address2 ? "error" : "success"}
                          sx={{ minWidth: "100%" }}
                          inputProps={{ maxLength: 255 }}
                        />
                      </Tooltip>
                    </ListItem>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3} sm={4} md={4} lg={3}>
                  <ListItem>
                    <ListItemText primary="電話番号" />
                  </ListItem>
                </Grid>
                <Grid item xs={9} sm={8} md={8} lg={9}>
                  {isLoading ? (
                    <Skeleton height={40} width="30%" />
                  ) : (
                    <ListItem>
                      <Tooltip disableFocusListener title={model?.tel_for_find ?? ""}>
                        <Input
                          disabled
                          value={model?.tel_for_find ?? ""}
                          sx={{ minWidth: "100%" }}
                        />
                      </Tooltip>
                    </ListItem>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3} sm={4} md={4} lg={3}>
                  <ListItem>
                    <ListItemText primary="結果" />
                  </ListItem>
                </Grid>
                <Grid item xs={9} sm={8} md={8} lg={9}>
                  {isLoading ? (
                    <Skeleton height={40} width="20%" />
                  ) : (
                    <ListItem style={{ padding: "12px" }}>
                      <Badge
                        color="primary"
                        badgeContent={data.result_name}
                        className={
                          "customer-list-badge-" + renderColorBadge(data.result)
                        }
                        sx={{ minWidth: "100%" }}
                      />
                    </ListItem>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3} sm={4} md={4} lg={3}>
                  <ListItem>
                    <ListItemText primary="担当者" />
                  </ListItem>
                </Grid>
                <Grid item xs={9} sm={8} md={8} lg={9}>
                  {isLoading ? (
                    <Skeleton height={40} width="50%" />
                  ) : (
                    <ListItem>
                      <Tooltip disableFocusListener title={model?.user_name ?? ""}>
                        <Input
                          sx={{ minWidth: "100%" }}
                          disabled
                          value={model?.user_name ?? ""}
                        />
                      </Tooltip>
                    </ListItem>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3} sm={4} md={4} lg={3}>
                  <ListItem>
                    <ListItemText primary="ステータス" />
                  </ListItem>
                </Grid>
                <Grid item xs={9} sm={8} md={8} lg={9}>
                  {isLoading ? (
                    <Skeleton height={40} width="80%" />
                  ) : (
                    <ListItem>
                      <div className="result-name-filter w-100">
                        <Autocomplete
                          key="status"
                          name="status"
                          disablePortal
                          disabled={!isEditing}
                          value={
                            selectedOptionById(
                              statuses,
                              model?.status_id ?? null
                            ) || null
                          }
                          options={statuses}
                          getOptionLabel={(option) => option.status || ""}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              key="status"
                            />
                          )}
                          renderOption={(props, option) => (
                            <Box component="li" {...props} key={option.id}>
                              {option.status}
                            </Box>
                          )}
                          color={inputErrors.address2 ? "error" : "success"}
                          onChange={(_e, elem) => {
                            handleBindvalue(
                              "status_id",
                              elem !== null ? elem.id : 0
                            );
                          }}
                        />
                      </div>
                    </ListItem>
                  )}
                </Grid>
              </Grid>
            </CardBody>
          </Box>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={7} md={7} lg={7} className="form-helper">
        <FormLabel component="legend">顧客詳細情報</FormLabel>
        <Card>
          <Box component="span">
            <CardBody className="min-height-customer-details-explain">
              <Box sx={{ width: "100%" }}>
                <TabContext value={value}>
                  <Box sx={{ maxWidth: 700 }}>
                    <TabList
                      onChange={(_e, val) => handleChangeTab(val)}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab label="備考" value="bikos" />
                      {headGroup &&
                        headGroup.map((head, index) => (
                          <Tab
                            key={index}
                            label={head.name}
                            value={String(head.head_id)}
                          />
                        ))}
                    </TabList>
                  </Box>
                  <TabPanel sx={{ padding: "0px" }} value="bikos">
                    <Container>{renderBikoRow()}</Container>
                  </TabPanel>
                  {data.details &&
                    data.details.map((head, idx) => (
                      <TabPanel
                        sx={{ padding: "0px" }}
                        key={idx}
                        value={String(head.head_id)}
                        style={{ marginBottom: "12px" }}
                      >
                        {renderPanelTab(head, head.head_id)}
                      </TabPanel>
                    ))}
                </TabContext>
              </Box>
            </CardBody>
          </Box>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={5}>
        <FormLabel component="legend">メモ 1</FormLabel>
        <Card>
          <CardBody
            className="card-body"
            style={{ minHeight: 155, marginTop: 21 }}
          >
            <Box component="span" sx={{ p: 2 }}>
              {model.memo}
            </Box>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={7}>
        <FormLabel component="legend">メモ 2</FormLabel>
        <Card>
          <CardBody className="card-body">
            <TextField
              fullWidth
              multiline
              disabled={isEditing}
              rows={6}
              value={model.customer_memo_2}
              onBlur={handleSaveMemo}
              onChange={(e) => setModel((state) => ({ ...state, customer_memo_2: e.target.value }))}
            />
          </CardBody>
        </Card>
      </GridItem>
    </>
  );
}
