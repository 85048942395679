import { alertActions } from "../actions/alert.actions";
import { fetchAsPost } from "./api.service";
import { API_HISTORY } from "../helpers/apiURL";
import { handleResData } from "./api.handleRespone";
import Moment from "moment";

export async function fetchHistory(params, dispatch) {
  const res = await fetchAsPost(API_HISTORY.GET, params);

  return handleResData(res, dispatch);
}

export async function fetchCsvData(params, dispatch) {
  const res = await fetchAsPost(API_HISTORY.EXPORT_CSV, params);
  if (!res.isOk && res.isInvalid) {
    const { error_code, message } = res.errors;
    dispatch(
      alertActions.error({ error_code, message: Object.values(message)[0] })
    );
  }

  return res;
}

export async function fetchCheckLimitKT(params, dispatch) {
  const res = await fetchAsPost(API_HISTORY.KT_CHECK_LIMIT, params);
  return handleResData(res, dispatch);
}

export async function fetchBulkImportKT(params, dispatch) {
  const res = await fetchAsPost(API_HISTORY.KT_UPDATE, params);
  return handleResData(res, dispatch, true);
}

export async function fetchCheckLimitSF(params, dispatch) {
  const res = await fetchAsPost(API_HISTORY.SF_CHECK_LIMIT, params);
  return handleResData(res, dispatch);
}

export async function fetchBulkImportSF(params, dispatch) {
  const res = await fetchAsPost(API_HISTORY.SF_UPDATE, params);
  return handleResData(res, dispatch, true);
}

export async function fetchExport(params, dispatch) {
  const res = await fetchAsPost(API_HISTORY.EXPORT_CSV, params);

  if (!res.isInvalid) {
    return res;
  }

  if (!res.isOk && res.isInvalid) {
    const { error_code, message } = res.errors;
    dispatch(
      alertActions.error({ error_code, message: Object.values(message)[0] })
    );
    return res;
  }

  const bodyData = res.data.body.map((expDt) => {
    const telTime = Moment(expDt.tel_time);
    const cTelTime = Moment(expDt.catch_tel_time);

    return {
      ...expDt,
      tel_time: telTime.isValid() ? telTime.format("YYYY-MM-DD HH:mm:ss") : "",
      catch_tel_time: cTelTime.isValid()
        ? cTelTime.format("YYYY-MM-DD HH:mm:ss")
        : "",
    };
  });

  const obj = {
    header: res.data.header,
    body: bodyData,
  };

  return { ...res, data: obj };
}
