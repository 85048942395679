import { LoginPage } from "views/Login";
import { HistoryDetail } from "views/HistoryDetail";
import CusDetail from "views/CusDetail";
import { URL } from "./helpers/url";
import { CONSTANT } from "./constants/constant";

const directRoutes = [
  {
    path: URL.LOGIN,
    name: "Login",
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: URL.HISTORY_DETAIL,
    name: "通話履歴詳細",
    component: HistoryDetail,
    layout: CONSTANT.PREFIX_ADMIN,
  },
  {
    path: URL.CUSTOMER_DETAIL,
    name: "対応履歴",
    component: CusDetail,
    layout: CONSTANT.PREFIX_ADMIN,
  },
];

export default directRoutes;
