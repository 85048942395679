import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { fetchCusdetail } from "../../../services/api.cusDetail";

export function useDetail() {
  const { customer_no } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    ec_customer: {},
    ec_histories: [],
    statuses: [{ id: 0, status: "未ステータス" }],
  });

  useEffect(() => {
    async function fetchData() {
      const res = await fetchCusdetail({ customer_no }, dispatch);

      if (res.isOk) {
        setData((state) => ({
          ...state,
          ...res.data,
          statuses: [...state.statuses, ...res.data.statuses],
        }));
      }
      setIsLoading(false);
    }

    fetchData();
  }, []);

  return [data, isLoading];
}
