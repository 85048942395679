import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchSetting } from "../../../services/api.ec";

export function useData() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [data, setData] = useState({
    company_id: "",
    access_key: "",
    username: "",
    password: "",
    result_pattern: [],
  });

  useEffect(() => {
    document.title = "EverCall API設定";

    async function fetchData() {
      const res = await fetchSetting(dispatch);
      if (res.isOk) {
        const resultPatterns = res.data.result_pattern;
        setResults(resultPatterns);
        setData(res.data.auth);
        const ids = useOptionIds(resultPatterns);
        setCheckAll(isCheckAll(ids, resultPatterns));
        setSelectedIds(ids);
      }
      setIsLoading(false);
      setIsOpen(false);
    }

    fetchData();
  }, []);

  return [
    isOpen,
    isLoading,
    setIsLoading,
    results,
    setResults,
    selectedIds,
    setSelectedIds,
    data,
    setData,
    checkAll,
    setCheckAll,
  ];
}

export function useOptionIds(options) {
  return options.reduce((obj, item) => {
    item.isChecked === 1 && obj.push(item.result_pattern_id);
    return obj;
  }, []);
}

export function isCheckAll(ids, patterns) {
  if (ids.length === 0 || patterns.length === 0) {
    return false;
  }

  if (ids.length === patterns.length) {
    return true;
  }

  return false;
}
