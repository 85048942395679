import { API_SALES_FORCE } from "../helpers/apiURL";
import { fetchAsFormData, fetchAsPutFormData, fetchAsGet } from "./api.service";
import { handleResData } from "./api.handleRespone";

export async function fetchSetting(dispatch) {
  const res = await fetchAsGet(API_SALES_FORCE.SETTING);
  return handleResData(res, dispatch);
}

export async function fetchUpdateSetting(body, dispatch) {
  const res = await fetchAsFormData(API_SALES_FORCE.SETTING, body);
  return handleResData(res, dispatch, true);
}

export async function fetchUpdateSwitch(body, dispatch) {
  const res = await fetchAsPutFormData(API_SALES_FORCE.SETTING, body);
  return handleResData(res, dispatch);
}
