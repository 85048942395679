import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { uniqueArray } from "../../../helpers/common";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function splitOption(options) {
  if (!options) {
    return [];
  }

  return typeof options === "string" ? options.split("|") : options;
}
export default function MultipleSelectChip(props) {
  const theme = useTheme();
  const { keyId, options, values, onChange, disabled } = props;

  const [selectName, setSelectName] = useState(() => splitOption(values));

  function handleChange(e) {
    const {
      target: { value },
    } = e;

    setSelectName(typeof value === "string" ? value.split("|") : value);
    onChange(value.join("|"));
  }

  return (
    <FormControl sx={{ m: 1, width: "100%" }}>
      <Select
        id={keyId}
        multiple
        disabled={disabled}
        value={selectName}
        onChange={handleChange}
        input={<OutlinedInput id={`select-${keyId}`} />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {uniqueArray(options).map((name) => (
          <MenuItem
            key={name}
            value={name}
            style={getStyles(name, selectName, theme)}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
