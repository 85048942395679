export const ERROR_MESSAGE = {
  DATE: {
    INVALID: "「YYYY-MM-DD」形式で入力してください。",
    LESS_THEN_END: "日付が不正です。",
  },
  TEL: {
    INVALID: "電話番号は不正です。",
  },
  CUS_NO: {
    INVALID: "電話番号は不正です。",
  },
  REQUIRED: "この項目は必須です。"
};
