import modalStyle from "assets/jss/salto-react/modalStyle.js";

const statusManagerStyle = (theme) => ({
  ...modalStyle(theme),
  titleStyle: {
    color: "black",
    fontWeight: "600",
    fontSize: "1.25rem",
  },
  supTitleStyle: {
    color: "black",
    fontWeight: "300",
    fontSize: "0.875rem !important",
  },
  marginSelectStyle: {
    marginTop: "25px",
  },
  widthButtonSubmitStyle: {
    width: "90%",
  },
  colorBlackStyle: {
    color: "black",
  },
  titleTableStyle: {
    fontWeight: "600",
    fontSize: "1.25rem",
  },
  supTitleTableStyle: {
    fontWeight: "300",
    fontSize: "0.875rem",
  },
  withCheckBox: {
    width: "32%",
  },
  titlePermission: {
    width: "15%",
    marginTop: "10px",
    float: "left",
    color: "black",
    fontSize: "0.875rem !important",
    fontWeight: "300",
  },
  groupPermission: {
    marginLeft: "15px",
    marginBottom: "10px",
  },
  withIcon: {
    marginLeft: "10px",
    width: "20px",
    left: "20px",
    top: "20px",
    float: "right",
  },
});

export default statusManagerStyle;
