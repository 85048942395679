import React, {  useState } from "react";
import { useDispatch, }
  //useSelector }
   from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@mui/material/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SettingsIcon from "@mui/icons-material/Settings";
import TextureIcon from "@mui/icons-material/Texture";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { isEmpty } from "lodash";
import styles from "assets/jss/salto-react/views/regularFormsStyle";
import logo from "../../assets/img/logo-evercall.png";
// import { alertActions } from "../../actions/alert.actions";
import { //ShowSweetAlert, 
  isEmptyObj } from "../../helpers/common";
import { fetchUpdateEC, fetchUpdateResult } from "../../services/api.ec";
import { useData, useOptionIds } from "./hooks/useData";
import { useValidate } from "./hooks/useValidate";

const useStyles = makeStyles(styles);

export function EvercallSetting() {
  const dispatch = useDispatch();
  const classes = useStyles();
  // const alert = useSelector((state) => state.alert);
  const [
    isOpen,
    isLoading,
    setIsLoading,
    results,
    setResults,
    selectedIds,
    setSelectedIds,
    data,
    setData,
    checkAll,
    setCheckAll,
  ] = useData();
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  // const [isShowAlert, setIsShowAlert] = useState(false);
  const [errors, setError] = useState({
    isError: false,
  });

  // useEffect(() => {
  //   setIsShowAlert(!isEmpty(alert) ? true : false);
  // }, [alert]);

  // Function handle update
  async function handleUpdate() {
    if (errors.isError) {
      return;
    }

    setIsLoading(true);
    const res = await fetchUpdateEC(data, dispatch);

    if (res.isOk) {
      setResults(res.data.result_pattern);
    }
    setIsLoading(false);
  }

  async function handleUpdateEC() {
    setIsLoadingBtn(true);
    await fetchUpdateResult(
      { result_pattern: selectedIds.join(",") },
      dispatch
    );
    setIsLoadingBtn(false);
  }

  function handleBindValue(e) {
    const { id, value } = e.target;
    const [isError, key, message] = useValidate(id, value);

    setError((state) => ({
      ...state,
      [key]: isError ? message : null,
      isError,
    }));

    setData((state) => ({ ...state, [key]: value }));
  }

  function handleCheckAll() {
    const newSelecteds = results.map((item) => ({
      ...item,
      isChecked: !checkAll ? 1 : 0,
    }));

    setSelectedIds(useOptionIds(newSelecteds));
    setResults(newSelecteds);
    setCheckAll(!checkAll);
  }

  function handleChecked(id) {
    const newSelecteds = results.map((item) => {
      if (item.result_pattern_id === id) {
        return { ...item, isChecked: +!item.isChecked };
      }

      return item;
    });

    const ids = useOptionIds(newSelecteds);

    setSelectedIds(ids);
    setCheckAll(ids.length === results.length);
    setResults(newSelecteds);
  }

  function enableBtn() {
    const isEmptyVal = isEmptyObj(data);
    return !(!errors.isError && !isEmptyVal);
  }

  return (
    <>
      <Box className="backdrop">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
      <GridContainer spacing={3} direction="row">
        <GridItem xs={12} style={{ marginTop: "-50px" }}>
          <GridContainer>
            <GridItem xs={12} md={3}>
              <Card className="position-sticky top-1 salesforce-setting-menu-left">
                <List>
                  <ListItem
                    button
                    className={"salesforce-setting-link"}
                    component={Link}
                    href="#ecsetting"
                  >
                    <ListItemText className={"d-flex"}>
                      <SettingsIcon style={{ marginRight: "10px" }} />
                      <span className={"text-sm text-dark-grey"}>認証情報</span>
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    className={"salesforce-setting-link"}
                    component={Link}
                    href="#pattern-setting"
                  >
                    <ListItemText className={"d-flex"}>
                      <TextureIcon style={{ marginRight: "10px" }} />
                      <span className={"text-sm text-dark-grey"}>
                        連携対象条件
                      </span>
                    </ListItemText>
                  </ListItem>
                </List>
              </Card>
            </GridItem>
            <GridItem md={9} xs={12}>
              <div className="evercall-setting-wrapper">
                <Card className="evercall-card-space" id="ecsetting">
                  <CardBody>
                    <GridContainer
                      spacing={3}
                      alignItems="center"
                      direction="row"
                    >
                      <GridItem xs="auto">
                        <CardAvatar className="avatar-evercall">
                          <img width="100%" src={logo} alt="bruce" />
                        </CardAvatar>
                      </GridItem>
                      <GridItem xs="auto">
                        <h3 className="text-bold text-title-color">
                          API情報設定
                        </h3>
                        <p className={"text-sm text-grey"}>
                          EverCallにログインする情報を指定してください。
                        </p>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h3 className={"text-dark-grey text-bold"}>認証情報</h3>
                  </CardHeader>
                  <CardBody>
                    <GridContainer spacing={3}>
                      <GridItem xs={12}>
                        <Box className="evercall-input">
                          <InputLabel className={"text-grey"}>
                            Access Key
                          </InputLabel>
                          <CustomInput
                            success={isEmpty(errors.access_key)}
                            error={!isEmpty(errors.access_key)}
                            helperText={errors.access_key ?? ""}
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              id: "access_key",
                              disabled: isLoading,
                              type: "text",
                              minLength: 6,
                              maxLength: 6,
                              placeholder: "3MVG9",
                              value: data.access_key,
                              onChange: (e) => handleBindValue(e),
                            }}
                          />
                        </Box>
                      </GridItem>
                      <GridItem xs={6}>
                        <Box sx={{ mt: 2 }} className="evercall-input">
                          <InputLabel className={"text-grey"}>
                            ユーザー名
                          </InputLabel>
                          <CustomInput
                            success={isEmpty(errors.username)}
                            error={!isEmpty(errors.username)}
                            helperText={errors.username ?? ""}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              id: "username",
                              type: "text",
                              maxLength: 255,
                              value: data.username,
                              onChange: (e) => handleBindValue(e),
                            }}
                          />
                        </Box>
                      </GridItem>
                      <GridItem xs={6}>
                        <Box sx={{ mt: 2 }} className="evercall-input">
                          <InputLabel className={"text-grey"}>
                            パスワード
                          </InputLabel>
                          <CustomInput
                            success={isEmpty(errors.password)}
                            error={!isEmpty(errors.password)}
                            helperText={errors.password ?? ""}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              id: "password",
                              type: "password",
                              minLength: 6,
                              maxLength: 255,
                              value: data.password ?? "",
                              onChange: (e) => handleBindValue(e),
                            }}
                          />
                        </Box>
                      </GridItem>
                    </GridContainer>
                    <Box sx={{ mt: 3 }}>
                      <GridContainer
                        direction="row"
                        alignItems="flex-end"
                        justifyContent="flex-end"
                      >
                        <GridItem xs="auto">
                          <LoadingButton
                            color="secondary"
                            disabled={enableBtn()}
                            loading={isLoading}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            onClick={() => handleUpdate(data)}
                          >
                            保存
                          </LoadingButton>
                        </GridItem>
                      </GridContainer>
                    </Box>
                  </CardBody>
                </Card>
              </div>
              <div className="result-pattern-wrapper" id="pattern-setting">
                <GridContainer>
                  <GridItem xs={12}>
                    <Card style={{ marginTop: 0 }}>
                      <CardHeader
                        color="rose"
                        icon
                        className={"cardHeaderResult"}
                      >
                        <h3 className={classes.titleResultConditions}>
                          連携対象条件
                        </h3>
                        <h4
                          className={classes.titleResultConditionsDescription}
                        >
                          結果パータンにより、データを連携する条件を指定ください。
                          <br />
                          結果連携条件を指定しない場合、全データを連携することになります。
                        </h4>
                        <br />
                      </CardHeader>
                      {results.length > 0 ? (
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12}>
                              <GridContainer>
                                <GridItem xs={4} md={4}>
                                  <span
                                    className={"text-dark-grey"}
                                    style={{
                                      lineHeight: "40px",
                                      fontSize: "1.1rem",
                                    }}
                                  >
                                    結果パータン
                                  </span>
                                </GridItem>
                                <GridItem xs={8}>
                                  <FormControlLabel
                                    className="checkbox-circle-20"
                                    control={
                                      <Checkbox
                                        checkedIcon={
                                          <Check className={"checked-icon"} />
                                        }
                                        icon={
                                          <Check
                                            className={classes.uncheckedIcon}
                                          />
                                        }
                                        classes={{
                                          checked: classes.checked,
                                          root: classes.checkRoot,
                                        }}
                                        onChange={handleCheckAll}
                                        checked={checkAll}
                                        name="checkAll"
                                      />
                                    }
                                    label="全て選択"
                                  />
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                            <GridItem xs={12}>
                              <GridContainer>
                                {results &&
                                  results.map((item, idx) => (
                                    <GridItem md={4} sm={4} xs={6} key={idx}>
                                      <FormControlLabel
                                        className="checkbox-circle-20"
                                        control={
                                          <Checkbox
                                            checkedIcon={
                                              <Check
                                                className={"checked-icon"}
                                              />
                                            }
                                            icon={
                                              <Check
                                                className={
                                                  classes.uncheckedIcon
                                                }
                                              />
                                            }
                                            classes={{
                                              checked: classes.checked,
                                              root: classes.checkRoot,
                                            }}
                                            onChange={() =>
                                              handleChecked(
                                                item.result_pattern_id
                                              )
                                            }
                                            checked={
                                              item.isChecked === 1
                                                ? true
                                                : false
                                            }
                                            name="optionOne"
                                          />
                                        }
                                        label={item.name}
                                      />
                                    </GridItem>
                                  ))}
                              </GridContainer>
                              <GridContainer></GridContainer>
                            </GridItem>
                          </GridContainer>
                          <Box sx={{ mt: 3 }}>
                            <GridContainer
                              direction="row"
                              alignItems="flex-end"
                              justifyContent="flex-end"
                            >
                              <GridItem xs="auto">
                                <LoadingButton
                                  color="success"
                                  loading={isLoadingBtn}
                                  loadingPosition="start"
                                  startIcon={<SaveIcon />}
                                  variant="contained"
                                  onClick={() => handleUpdateEC()}
                                >
                                  保存
                                </LoadingButton>
                              </GridItem>
                            </GridContainer>
                          </Box>
                        </CardBody>
                      ) : (
                        <Box sx={{ p: 3, textAlign: "center" }}>
                          上記の認証情報を入力してください
                        </Box>
                      )}
                    </Card>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </>
  );
}
