export * from "./salesforce-setting";
export * from "./evercall-setting";
export * from "./customer";
export * from "./batch-setting";
export * from "./kintone-setting";

export const ERROR_MESSAGE = {
  REQUIRED: "この項目は必須です。",
  MIN_LENGTH: "6桁以上でご指定ください。",
  MAX_LENGTH: "255桁以下でご指定ください。",
  FORMAT_EMAIL: "無効なメールアドレスです",
  FORMAT_DATE: "無効な日付です。",
  FORMAT_HOUR: "無効な時間です。",
  FORMAT_MINUTE: "無効な分です。",
  FORMAT_NUMBER: "数を入力してください",
  FORMAT_URL: "無効なURLです。",
};
