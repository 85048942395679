export const batchSTMessage = {
  start_date: {
    blank: "開始日は必須項目です。",
  },
  minute: {
    blank: "分は必須項目です。",
    zero: "分は1以上を入力してください。",
  },
  day: {
    blank: "日は必須項目です。",
  },
  weekday: {
    blank: "平日は必須項目です。",
  },
  month: {
    blank: "月は必須項目です。",
  },
  hour: {
    blank: "時間は必須項目です。",
  },
  item: {
    blank: "項目名を選択してください。",
  },
  filter_type: {
    blank: "条件を選択してください。",
  },
  filter_value: {
    blank: "条件値を入力してください。",
  },
  list_group_id: {
    blank: "リストグループは必須項目です。",
  },
  list_id: {
    blank: "リストは必須項目です。",
  },
};
