import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, InputLabel } from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import { Autocomplete, TextField } from "@mui/material";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { isEmpty, find, cloneDeep } from "lodash";
import { dateFormat, findObjByKey } from "../../../helpers/common";
import { FILTER_TYPE } from "../../../helpers/filterType";
import { ERROR_MESSAGE } from "../../../messages/index";
import { useValue } from "../../../hooks/useBatchCommon";
import { fetchECListByGroupdId } from "../../../services/api.sfBatch";

export default function FilterCondition(props) {
  const { batchCode, title, desc, model, cusSchema,
    conditions,
    listGroups,
    onAddFilterCondition,
    batchFilterCondition,
    onAddBatchFilterCondition,
    errors,
  } = props;
  const dispatch = useDispatch();
  const [ecList, setEcList] = useState([]);
  const mapCusSchema = cusSchema.length ? cusSchema.map((el) => el.label) : [];

  useEffect(() => {
    async function callECList(id) {
      const res = await fetchECListByGroupdId(id, dispatch);
      if (res.isOk) {
        setEcList(res.data);
      }
    }
    if (batchFilterCondition?.list_group_id) {
      callECList(batchFilterCondition.list_group_id);
    }
  }, [batchFilterCondition?.list_group_id]);

  function findFilterTypeData(key) {
    const obj = find(FILTER_TYPE, { key });
    if (!obj) {
      return null;
    }
    return obj;
  }

  // Function handle when salesforce condition change value
  function handleChangeCondition(index, name, value) {
    const data = [...conditions];
    if (name === "filter_type") {
      data[index][name] = !value ? "" : value.key;
    } else if (name === "item") {
      data[index][name] = !value ? "" : value;
    } else {
      data[index][name] = value;
    }

    onAddFilterCondition(data);
  }

  /**
  * Push data thought props
  * @param {string} name 
  * @param {string} value 
  */
  function emitData(name, value) {
    const val = {
      ...batchFilterCondition,
      [name]: value,
      batch_code: model.batch_code,
      batch_name: model.batch_name
    };
    onAddBatchFilterCondition(val);
  }

  // Function handle when salesforce condition change value
  function handleChangeBatchValue(e) {
    const { name, value } = e.target;
    const val = useValue(name, value);
    onAddBatchFilterCondition({
      ...batchFilterCondition,
      [name]: val,
      batch_code: model.batch_code,
      batch_name: model.batch_name
    });
  }

  function handleAddCondition() {
    const data = [...conditions, { item: "", filter_type: "", filter_value: "" }];
    onAddFilterCondition(data);
  }

  function deleteCondition(index) {
    let result = cloneDeep(conditions);
    result.splice(index, 1);
    return onAddFilterCondition(result);
  }

  function findSelectedOption(val, type = 0) {
    const options = type === 0 ? listGroups : ecList;
    return findObjByKey(options, "key", parseInt(val));
  }

  function handleOnSelected(name, value) {
    emitData(name, value);
  }

  function requiredValue(type, value) {
    return (!["blank", "not_blank"].includes(type) && !value);
  }

  const titleRender = <>
    <GridItem xs={12}>
      <h4 className="text-bold text-dark-grey">
        {title}
      </h4>
      <p className="text-grey">{desc}</p>
    </GridItem></>;

  const filterConditionRender = <>
    <GridItem xs={12}>
      <GridContainer>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={4} className="text-grey">
              項目名
            </GridItem>
            <GridItem xs={3} className="text-grey">
              条件
            </GridItem>
            <GridItem xs={4} className="text-grey">
              値
            </GridItem>
            <GridItem xs={1}></GridItem>
          </GridContainer>
          {conditions.map((el, index) => {
            return (
              <GridContainer key={index} alignItems="center">
                <GridItem xs={4}>
                  <Autocomplete
                    disablePortal
                    value={el.item || null}
                    options={!isEmpty(mapCusSchema) ? mapCusSchema : []}
                    renderInput={(params) => {
                      return <TextField
                        helperText={!el.item ? ERROR_MESSAGE.REQUIRED : ""}
                        variant="standard" {...params}
                        error={!el.item ? true : false}
                      />;
                    }}
                    onChange={(_e, val) => handleChangeCondition(index, "item", val)}
                  />
                </GridItem>
                <GridItem xs={3}>
                  <Autocomplete
                    disablePortal
                    value={findFilterTypeData(el.filter_type) || null}
                    options={FILTER_TYPE}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        placeholder="条件"
                        helperText={!findFilterTypeData(el.filter_type) ? ERROR_MESSAGE.REQUIRED : ""}
                        error={!findFilterTypeData(el.filter_type) ? true : false}
                      />
                    )}
                    onChange={(_e, val) => handleChangeCondition(index, "filter_type", val)}
                  />
                </GridItem>
                <GridItem xs={4} className="batch-filter-value">
                  <CustomInput
                    error={requiredValue(el.filter_type, el.filter_value)}
                    helperText={requiredValue(el.filter_type, el.filter_value) ? ERROR_MESSAGE.REQUIRED : ""}
                    labelText="値"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      disabled: el.filter_type === "blank" || el.filter_type === "not_blank",
                      type: "text",
                      value: el.filter_value,
                      onChange: (e) => handleChangeCondition(index, "filter_value", e.target.value)
                    }}
                  />
                </GridItem>
                <GridItem xs={1} align="right">
                  <Button
                    justIcon
                    color="danger"
                    className="btn-schedule-setting"
                    onClick={() => deleteCondition(index)}
                  >
                    <Delete />
                  </Button>
                </GridItem>
              </GridContainer>
            );
          })}
        </GridItem>
        <GridItem xs={12} align="right">
          <Button
            onClick={handleAddCondition}
            justIcon
            className="btn-schedule-setting"
          >
            <Add />
          </Button>
        </GridItem>
      </GridContainer>
    </GridItem>
  </>;
  const periodRender = <>
    <GridItem xs={12}>
      <GridContainer>
        <GridItem xs={6}>
          <InputLabel className="text-grey">開始日</InputLabel>
          <CustomInput
            success={isEmpty(batchFilterCondition?.start_date)}
            error={batchFilterCondition?.start_date ? true : false}
            type="datetime"
            dateFormat="YYYY-MM-DD"
            timeFormat={true}
            closeOnSelect={false}
            helperText={errors?.start_date}
            formControlProps={{ fullWidth: true }}
            onDateChange={(e) => handleChangeBatchValue(e)}
            inputProps={{
              name: "start_date",
              placeholder: "YYYY-mm-dd HH:mm",
              maxLength: 255,
              value: batchFilterCondition?.start_date ? dateFormat(batchFilterCondition?.start_date) : "",
              type: "text",
              autoComplete: "off"
            }}
          />
        </GridItem>
        <GridItem xs={6}>
          <InputLabel className="text-grey">終了日</InputLabel>
          <CustomInput
            success={isEmpty(batchFilterCondition?.end_date)}
            error={batchFilterCondition?.end_date ? true : false}
            type="datetime"
            dateFormat="YYYY-MM-DD"
            timeFormat={true}
            closeOnSelect={false}
            helperText={errors?.end_date}
            formControlProps={{ fullWidth: true }}
            onDateChange={(e) => handleChangeBatchValue(e)}
            inputProps={{
              name: "end_date",
              placeholder: "YYYY-mm-dd HH:mm",
              maxLength: 255,
              value: batchFilterCondition?.end_date ? dateFormat(batchFilterCondition.end_date) : "",
              type: "text",
              autoComplete: "off"
            }}
          />
        </GridItem>
      </GridContainer>
    </GridItem>
  </>;

  const listCondition = <>
    <GridItem xs={12}>
      <GridContainer>
        <GridItem xs={6}>
          <Box sx={{ py: 2 }}>
            <InputLabel className="text-grey">
              リストグループ
            </InputLabel>
          </Box>
          <Autocomplete
            id="list_group_id"
            disablePortal
            value={findSelectedOption(batchFilterCondition?.list_group_id) || null}
            options={!isEmpty(listGroups) ? listGroups : []}
            getOptionLabel={(option) => option.label || ""}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.key}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                error={!isEmpty(errors?.list_group_id)}
                helperText={errors?.list_group_id ?? ""}
                variant="standard" {...params}
              />
            )}
            onChange={(_e, val) => handleOnSelected("list_group_id", val.key)}
          />
        </GridItem>
        <GridItem xs={6}>
          <Box sx={{ py: 2 }}>
            <InputLabel className="text-grey">リスト</InputLabel>
          </Box>
          <Autocomplete
            disablePortal
            defaultValue={null}
            value={findSelectedOption(batchFilterCondition?.list_id, 1) || null}
            options={ecList}
            getOptionLabel={(option) => option.label || ""}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.key}>
                {option.label}
              </Box>
            )}

            renderInput={(params) => (
              <TextField
                error={!isEmpty(errors?.list_id)}
                helperText={errors?.list_id ?? ""}
                variant="standard" {...params} />
            )}
            onChange={(_e, val) => handleOnSelected("list_id", val ? val.key : "")}
          />
        </GridItem>
      </GridContainer>
    </GridItem>
  </>;

  function generateView() {
    // IMPORT EC CUSTOMER LIST
    if (['BATCH_01', 'BATCH_04'].includes(batchCode)) {
      //return start end
      return <>{titleRender} {filterConditionRender}</>;
    }
    //Customer Update KINTONE, SALESFORCE
    if (['BATCH_02', 'BATCH_05'].includes(batchCode)) {
      if (model.schedule == 1) {
        return <>{titleRender} {periodRender}</>;
        //return start end
      }

      return <>{titleRender} {listCondition}</>;
    }

    //HISTORY Update KINTONE, SALESFORCE
    if (['BATCH_03', 'BATCH_06'].includes(batchCode) && model.schedule == 1) {
      //return start end
      return <>{titleRender} {periodRender}</>;
    }
  }

  return (
    <>
      {generateView()}
    </>
  );
}
