import { combineReducers } from "redux";
import { userConstants } from "../constants";
import { alert } from "./alert.reducer";
import { authentication } from "./authentication.reducer";
import { users } from "./users.reducer";

const appReducer = combineReducers({
  authentication,
  users,
  alert,
});

const rootReducer = (state, action) => {
  if (action.type === userConstants.USER_LOGGED_OUT) {
    state = {};
  }
  return appReducer(state, action);
};

export default rootReducer;
