import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, RadioGroup, Radio, FormControlLabel, InputLabel } from "@material-ui/core";
import { Autocomplete, TextField } from "@mui/material";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import { isEmpty } from "lodash";
import { fetchECListByGroupdId } from "../../../services/api.sfBatch";
import { findObjByKey } from "../../../helpers/common";

export default function ImportCondition(props) {
  const { title, desc, listGroups, importCondition, errors, onChange } = props;
  const dispatch = useDispatch();
  const [ecList, setEcList] = useState([]);
  const [type, setType] = useState(() => getType());

  useEffect(() => {
    async function callECList(id) {
      const res = await fetchECListByGroupdId(id, dispatch);
      if (res.isOk) {
        setEcList(res.data);
      }
    }
    if (importCondition.list_group_id) {
      callECList(importCondition.list_group_id);
    }
  }, [importCondition.list_group_id]);

  function getType() {
    return isNaN(importCondition.list_id) ? 2 : 1;
  }

  function findSelectedOption(val, type = 0) {
    const options = type === 0 ? listGroups : ecList;
    return findObjByKey(options, "key", parseInt(val));
  }

  function handleBindValue(e) {
    emitData(e.target.name, e.target.value);
  }

  function handleOnSelected(name, value) {
    emitData(name, value);
  }

  function handleChangeType(e) {
    const val = parseInt(e.target.value);
    setType(val);
    emitData(e.target.name, "");
  }
  /**
   * Push data thought props
   * @param {string} name 
   * @param {string} value 
   */
  function emitData(name, value) {
    const val = {
      ...importCondition,
      [name]: value,
    };
    if (name === "list_group_id") {
      val.list_id = "";
    }

    onChange(val);
  }

  return (
    <Box className="w-100">
      <hr className="horizontal dark" />
      <GridItem>
        <h4 className="text-bold text-dark-grey">{title}</h4>
        <p className="text-grey">{desc}</p>
      </GridItem>
      <GridItem xs={12}>
        <RadioGroup
          row
          value={type}
          onChange={(e) => handleChangeType(e)}
        >
          <FormControlLabel
            value={1}
            control={<Radio name="list_id" />}
            label="既存選択"
            className="text-grey"
          />
          <FormControlLabel
            value={2}
            control={<Radio name="list_id" />}
            label="新規登録"
            className="text-grey"
          />
        </RadioGroup>
        <GridContainer>
          <GridItem xs={6}>
            <Box sx={{ py: 2 }}>
              <InputLabel className="text-grey">
                リストグループ
              </InputLabel>
            </Box>
            <Autocomplete
              id="list_group_id"
              disablePortal
              value={findSelectedOption(importCondition.list_group_id) || null}
              options={!isEmpty(listGroups) ? listGroups : []}
              getOptionLabel={(option) => option.label || ""}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.key}>
                  {option.label}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  error={!isEmpty(errors?.list_group_id)}
                  helperText={errors?.list_group_id ?? ""}
                  variant="standard" {...params}
                />
              )}
              onChange={(_e, val) => handleOnSelected("list_group_id", val ? val.key : "")}
            />
          </GridItem>
          {
            type === 1 ? (
              <GridItem xs={6}>
                <Box sx={{ py: 2 }}>
                  <InputLabel className="text-grey">リスト</InputLabel>
                </Box>
                <Autocomplete
                  disablePortal
                  disabled={!importCondition.list_group_id || !ecList}
                  defaultValue={null}
                  value={findSelectedOption(importCondition.list_id, 1) || null}
                  options={ecList}
                  getOptionLabel={(option) => option.label || ""}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.key}>
                      {option.label}
                    </Box>
                  )}

                  renderInput={(params) => (
                    <TextField
                      error={!isEmpty(errors?.list_id)}
                      helperText={errors?.list_id ?? ""}
                      variant="standard" {...params} />
                  )}
                  onChange={(_e, val) => handleOnSelected("list_id", val ? val.key : "")}
                />
              </GridItem>) : (
              <GridItem xs={6} className="batch-list-input">
                <InputLabel className="text-grey">リスト</InputLabel>
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: "list_id",
                    type: "text",
                    max: 100,
                    value: importCondition?.list_id ?? "",
                    placeholder: "リスト名を入力してください",
                    onChange: (e) => handleBindValue(e)
                  }}
                />
              </GridItem>
            )}
        </GridContainer>
      </GridItem>
    </Box>
  );
}
