import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./callHistoryStyle";
import Badge from "@material-ui/core/Badge";
import { renderColorBadge } from "../../../helpers";
import clsx from "clsx";

const useStyles = makeStyles(styles);

CallHistoryResultLabel.propTypes = {
  text: PropTypes.string,
  result: PropTypes.any,
};

export default function CallHistoryResultLabel(props) {
  const classes = useStyles();
  const { text, result } = props;
  return (
    <div className={clsx(classes.callResultLabel, "callResultLabel")}>
      
      <span >
        <Badge
          color="primary"
          badgeContent={text}
          className={clsx(
            "customer-list-badge-" + renderColorBadge(result),
          )}
        ></Badge>
      </span>
    </div>
  );
}
