import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Moment from "moment";
import { useQuery } from "../../helpers";
import { getDetail } from "../../services/api.historyDetail";
import CustomerInfo from "./Components/CustomerInfoComponent";
import TelHistory from "./Components/TelHistoryComponent";
import { CONSTANT } from "../../constants/constant";
import { URL } from "../../helpers/url";

export function HistoryDetail() {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryString = useQuery();
  const customer_no = queryString.get("customer_no");
  const tel_time = queryString.get("tel_time");
  const [open, setOpen] = useState(true);
  const [historyDetail, setHistoryDetail] = useState({});
  const [customer, setCustomer] = useState({});

  useEffect(() => {
    document.title = "通話履歴";
    if (!customer_no || !tel_time) {
      return history.push(`${CONSTANT.PREFIX_ADMIN}${URL.HISTORY}`);
    }
    async function fetchDetail() {
      setOpen(true);
      const res = await getDetail({ customer_no, tel_time }, dispatch);
      if (res.isOk) {
        setHistoryDetail(res.data.ec_history);
        setCustomer(res.data.ec_customer);
      }

      setOpen(false);
    }
    fetchDetail();
  }, []);



  return (
    <>
      <Box className="backdrop">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
      <GridContainer
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <GridItem xs={12} md={12} lg={4}>
          <CustomerInfo customer={customer ? customer : {}} />
        </GridItem>
        <GridItem xs={12} md={12} lg={8}>
          <h5 className="text-bold tel-history-time">
            {Moment(historyDetail.tel_time).format("YYYY年MM月")}
          </h5>
          <TelHistory data={historyDetail} />
        </GridItem>
      </GridContainer>
    </>
  );
}
