import {
  EVERCALL_LIST_LIST_API,
  EVERCALL_LIST_LIST_GROUP_API,
  EVERCALL_RESULT_LIST_API,
} from "../helpers/url";
import { fetchAsGet } from "./api.service";

export async function fetchECListGroup() {
  const res = await fetchAsGet(EVERCALL_LIST_LIST_GROUP_API.GET);

  return res;
}

export async function fetchECList(listGroupId) {
  const res = await fetchAsGet(`${EVERCALL_LIST_LIST_API.GET}/${listGroupId}`);

  return res;
}

export async function fetchEvercallResultList() {
  const res = await fetchAsGet(EVERCALL_RESULT_LIST_API.GET);

  return res;
}
