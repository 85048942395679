import React from "react";
import Card from "components/Card/Card.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "@material-ui/core/Link";

function LeftMenu() {
  return (
    <Card className="position-sticky top-1 salesforce-setting-menu-left">
      <List>
        <ListItem
          button
          className={"salesforce-setting-link"}
          component={Link}
          href="#basic"
        >
          <ListItemText className={"d-flex"}>
            <i className="material-icons text-lg me-2">cloud_upload</i>
            <span className="text-sm">Salesforce連携</span>
          </ListItemText>
        </ListItem>
        <ListItem
          button
          className={"salesforce-setting-link"}
          component={Link}
          href="#auth"
        >
          <ListItemText className={"d-flex"}>
            <i className="material-icons text-lg me-2">manage_accounts</i>
            <span className="text-sm">認証情報</span>
          </ListItemText>
        </ListItem>
        <ListItem
          button
          className={"salesforce-setting-link"}
          component={Link}
          href="#taishou"
        >
          <ListItemText className={"d-flex"}>
            <i className="material-icons text-lg me-2">description</i>
            <span className="text-sm">連携対象</span>
          </ListItemText>
        </ListItem>
      </List>
    </Card>
  );
}

export default LeftMenu;
