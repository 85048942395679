import { fetchAsPost } from "./api.service";
import { API_CUSTOMER } from "../helpers/apiURL";
import { handleResData } from "./api.handleRespone";

export async function fetchCusdetail(params, dispatch) {
  const res = await fetchAsPost(API_CUSTOMER.DETAIL, params);

  return handleResData(res, dispatch);
}

export async function fetchUpdate(params, dispatch) {
  const res = await fetchAsPost(API_CUSTOMER.UPDATE_DETAIL, params);

  return handleResData(res, dispatch, true);
}


export async function fetchUpdateMemo(params, dispatch) {
  const res = await fetchAsPost(API_CUSTOMER.UPDATE_MEMO, params);

  return handleResData(res, dispatch);
}
