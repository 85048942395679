import { userConstants } from "../constants";

const INITIAL_STATE = {
  loading: false,
  error: null,
  items: null,
  status_update: null,
  status_add: null,
  status_delete: null,
  error_login: null,
};

export function users(state = INITIAL_STATE, action) {
  switch (action.type) {
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        error_login: action.error,
      };
    case userConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
        status_update: false,
        status_add: false,
        status_delete: false,
      };
    case userConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.users,
        loading: false,
      };
    case userConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case userConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        loading: true,
        status_update: false,
        status_add: false,
        status_delete: false,
      };
    case userConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        ...state,
        loading: false,
        status_delete: true,
        items: action.users,
      };
    case userConstants.DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        status_delete: false,
      };
    case userConstants.REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
        status_update: false,
        status_add: false,
        status_delete: false,
      };
    case userConstants.REGISTER_SUCCESS:
      return {
        ...state,
        items: action.users,
        loading: false,
        status_add: true,
      };
    case userConstants.REGISTER_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        status_add: false,
      };
    case userConstants.UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        status_update: false,
        status_add: false,
        status_delete: false,
      };
    case userConstants.UPDATE_SUCCESS:
      return {
        ...state,
        items: action.users,
        loading: false,
        status_update: true,
      };
    case userConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        status_update: false,
      };
    default:
      return state;
  }
}
