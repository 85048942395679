import { alertActions } from "../actions/alert.actions";
export function handleResData(res, dispatch, isShowSuccess = false) {
  if (!res.isInvalid) {
    return res;
  }

  if (!res.isOk) {
    const { error_code, message } = res.errors;

    if (error_code === 400) {
      const mess = typeof message === "object" ? Object.values(message)[0] : message;
      dispatch(alertActions.error({ error_code, message: mess }));
    } else if (error_code === 203) {
      let mess;
      let details = null;
      if (typeof message === 'object') {        
        if (message['detail_errors']) {
          mess = message.message;
          details = message['detail_errors'];
        } else {
          mess = Object.values(message)[0];
        }
      } else {
        mess = message;
      }

      dispatch(alertActions.error({ error_code, message: mess, details }));
    }    
    else {
      dispatch(alertActions.error(res.errors));
    }
  }

  if (res.isOk && isShowSuccess) {
    dispatch(alertActions.success(res.data.message));
  }

  return res;
}
