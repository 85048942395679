import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactAudioPlayer from "react-audio-player";
import playButton from "../../../../assets/img/play.png";
import loadingButton from "../../../../assets/img/loading.gif";
import errorButton from "../../../../assets/img/onerror.png";

const FileCallHistory = (props) => {
  const { source } = props;
  const [sourceData, setSourceData] = useState("");
  const [currentButton, setCurrentButton] = useState(playButton);
  const [canPlay, setCanPlay] = useState(false);
  const [afterLoading, setAfterLoading] = useState(false);

  const setDataForPlay = () => {
    if (currentButton != errorButton) {
      setSourceData(source);
      setCurrentButton(loadingButton);
      setAfterLoading(true);
    }
  };

  const setOnCanPlay = () => {
    setCanPlay(true);
  };

  const setOnError = () => {
    if (afterLoading) {
      setCurrentButton(errorButton);
    }
  };

  return (
    <div className="audioBox">
      <ReactAudioPlayer
        src={sourceData}
        controls
        onCanPlay={setOnCanPlay}
        onError={setOnError}
      />
      {!canPlay ? (
        <div
          className={currentButton == playButton ? "playButton" : "otherButton"}
          onClick={setDataForPlay}
        >
          <img width="100%" src={currentButton} className="imagePlayButton" />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

FileCallHistory.propTypes = {
  source: PropTypes.string,
};

export default FileCallHistory;
