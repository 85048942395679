import React, { useState, createRef } from "react";
import { useDispatch } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import Radio from "@material-ui/core/Radio";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomInput from "components/CustomInput/CustomInput";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { isEmpty } from "lodash";
import Basic from "./Components/Basic";
import LeftMenu from "./Components/Menu.js";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import validateStyles from "assets/jss/salto-react/views/validationFormsStyle";
import Button from "components/CustomButtons/Button.js";
import Taishou from "./Components/Taishou.js";
import { isEmptyObj, prepareFormData, getErrors } from "../../../helpers/common";
import { useData } from "./hooks/useData";
import { toInt } from "./hooks/useComon";
import { useValidate } from "../../../hooks/useValidate";
import { fetchUpdateSetting, fetchUpdateSwitch } from "../../../services/api.sfSetting";
import { userActions } from "../../../actions/user.actions";

export default function Setting() {
  const validateStyle = makeStyles(validateStyles);
  const validateClasses = validateStyle();
  const dispatch = useDispatch();
  const [isOpen, data, setData] = useData();
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInput = createRef();
  const [error, setError] = useState({ isError: false });
  const [isProcess, setIsProcess] = useState(false);


  function handleFileChange(e) {
    e.preventDefault();
    let file = e.target.files[0];
    if (!file) {
      return;
    }

    const fileExt = file.name.split(".").pop();

    setError((state) => ({
      ...state,
      private_key: fileExt !== "key" ? "ファイル拡張子が不正です。" : null,
      isError: fileExt !== "key" ? true : false,
    }));

    setSelectedFile(file);
  }

  // Handle click button choose file
  function handleClick() {
    fileInput.current.click();
  }

  function prepareParams() {
    const { connect_type, username, password, security_token, consumer_key } = data;
    const connectType = (!connect_type || parseInt(connect_type) === 1) ? 1 : 2;
    if (connectType === 1) {
      return prepareFormData({ connect_type: connectType, username, password, security_token });
    }

    const params = prepareFormData({ connect_type: connectType, username, consumer_key });
    if (selectedFile) {
      params.append("private_key", selectedFile, selectedFile.name);
    }
    params.append("modifed_private_key", selectedFile ? true : false);
    return params;
  }

  // Handle update data
  async function handleUpdate() {
    const frmData = prepareParams();
    setIsLoadingBtn(true);
    const res = await fetchUpdateSetting(frmData, dispatch);
    setIsLoadingBtn(false);
    if (!res.isOk) {
      return setError(getErrors(res.errors.message));
    }
    setData(res.data.data);
  }

  function handleBindValue(e) {
    const { name, value } = e.target;

    setData((state) => ({
      ...state,
      [name]: value,
    }));

    const [isError, key, message] = useValidate(name, value.toString());

    setError((state) => ({
      ...state,
      [key]: isError ? message : null,
      isError,
    }));

  }

  function enableBtn() {
    const { connect_type, consumer_key, password, security_token, username } = data;
    const connectType = (!connect_type || parseInt(connect_type) === 1) ? 1 : 2;

    const initObj = connectType ? {
      connect_type: connectType, username, password, security_token
    } : { connect_type: connectType, username, consumer_key };

    const isEmptyVal = isEmptyObj(initObj);

    return !(!error.isError && !isEmptyVal);
  }

  async function handleUpdateSwitch(obj) {
    const { id, checked } = obj;
    const newData = { ...data, [id]: checked };
    setData(newData);

    setIsProcess(true);
    const initData = toInt(newData);
    const res = await fetchUpdateSwitch(prepareFormData(initData), dispatch);
    setIsProcess(false);
    if (res.isOk) {
      const obj = Object.fromEntries(
        Object.entries(res.data.data)
          .map(([key, val]) => [key, !!parseInt(val)])
      );
      dispatch(userActions.updateUserInfo('user_salesforce', obj));
    }
  }

  return (
    <>
      <Box className="backdrop">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>

      <GridContainer spacing={3} direction="row">
        <GridItem xs={12} md={12} lg={3}>
          <LeftMenu />
        </GridItem>
        <GridItem xs={12} md={12} lg={9}>
          <Basic />
          <Card id="auth">
            <CardHeader>
              <h3 className="text-bold text-dark-grey">認証情報</h3>
            </CardHeader>
            <CardBody className="pt-0">
              <GridContainer direction="row">
                <GridItem>
                  <InputLabel className="text-grey">接続方法</InputLabel>
                  <FormControlLabel
                    name="connect_type"
                    onChange={(e) => handleBindValue(e)}
                    value="1"
                    checked={!data.connect_type || parseInt(data.connect_type) === 1}
                    control={<Radio />}
                    label="セキュリティトークン"
                  />
                  <FormControlLabel
                    name="connect_type"
                    onChange={(e) => handleBindValue(e)}
                    checked={!data.connect_type || parseInt(data.connect_type) === 2}
                    value="2"
                    control={<Radio />}
                    label="秘密キーファイル"
                  />
                </GridItem>
              </GridContainer>
              <Box sx={{ mt: 3 }}>
                <GridContainer spacing={3} direction="row">
                  <GridItem
                    xs={!data.connect_type || parseInt(data.connect_type) === 1 ? 6 : 12}
                  >
                    <InputLabel className="text-grey">ユーザー名</InputLabel>
                    <CustomInput
                      success={isEmpty(error.username)}
                      error={!isEmpty(error.username)}
                      helperText={error.username ?? ""}
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: "username",
                        disabled: isLoadingBtn,
                        type: "text",
                        maxLength: 255,
                        placeholder: "tanaka@example.com",
                        value: data.username ?? "",
                        onChange: (e) => handleBindValue(e),
                      }}
                    />
                  </GridItem>
                  {(!data.connect_type || parseInt(data.connect_type) === 1) && (
                    <GridItem xs={6}>
                      <InputLabel className="text-grey">パスワード</InputLabel>
                      <CustomInput
                        success={isEmpty(error.password)}
                        error={!isEmpty(error.password)}
                        helperText={error.password ?? ""}
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          name: "password",
                          disabled: isLoadingBtn,
                          type: "password",
                          maxLength: 255,
                          value: data.password ?? "",
                          onChange: (e) => handleBindValue(e),
                        }}
                      />
                    </GridItem>
                  )}
                </GridContainer>
              </Box>
              {(!data.connect_type || parseInt(data.connect_type) === 1) && (
                <Box sx={{ mt: 3 }}>
                  <GridContainer direction="row">
                    <GridItem xs={12}>
                      <InputLabel className="text-grey">トークン</InputLabel>
                      <CustomInput
                        success={isEmpty(error.security_token)}
                        error={!isEmpty(error.security_token)}
                        helperText={error.security_token ?? ""}
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          name: "security_token",
                          disabled: isLoadingBtn,
                          type: "text",
                          maxLength: 255,
                          value: data.security_token ?? "",
                          onChange: (e) => handleBindValue(e),
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </Box>
              )}
              {(!data.connect_type || parseInt(data.connect_type) === 2) && (
                <>
                  <Box sx={{ mt: 3 }}>
                    <InputLabel className="text-grey">Private Key</InputLabel>
                    <div className="fileinput">
                      <input type="file" onChange={handleFileChange} ref={fileInput} />
                      <Box sx={{ my: 2 }} className={validateClasses.danger}>
                        {error.private_key}
                      </Box>
                      <Box sx={{ my: 2 }}>{selectedFile && selectedFile.name}</Box>
                      <div>
                        <Button color="rose" round={true} onClick={handleClick}>
                          ファイルを選択
                        </Button>
                      </div>
                    </div>
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <InputLabel className="text-grey">Consumer Key</InputLabel>
                    <CustomInput
                      success={isEmpty(error.consumer_key)}
                      error={!isEmpty(error.consumer_key)}
                      helperText={error.consumer_key ?? ""}
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: "consumer_key",
                        disabled: isLoadingBtn,
                        type: "text",
                        maxLength: 255,
                        value: data.consumer_key ?? "",
                        onChange: (e) => handleBindValue(e),
                      }}
                    />
                  </Box>
                </>
              )}
              <Box sx={{ mt: 3 }}>
                <GridContainer
                  direction="row"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <GridItem>
                    <LoadingButton
                      color="secondary"
                      disabled={enableBtn()}
                      loading={isLoadingBtn}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="contained"
                      onClick={handleUpdate}
                    >
                      保存
                    </LoadingButton>
                  </GridItem>
                </GridContainer>
              </Box>
            </CardBody>
          </Card>
          <Taishou
            is_cus_realtime_trans={data.is_cus_realtime_trans}
            is_cus_batch_trans={data.is_cus_batch_trans}
            is_cus_connect={data.is_cus_connect}
            is_his_connect={data.is_his_connect}
            is_his_realtime_trans={data.is_his_realtime_trans}
            is_his_batch_trans={data.is_his_batch_trans}
            connect_type={data.connect_type}
            company_id={data.company_id}
            isProcess={isProcess}
            onChange={(data) => handleUpdateSwitch(data)}
          />
        </GridItem>
      </GridContainer>
    </>
  );
}
