import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import DataTable from "components/DataTable/DataTable.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { isEmpty } from "lodash";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch } from "react-redux";
import styles from "views/StatusManager/Components/statusManagerStyle.js";
import SaveIcon from "@mui/icons-material/Save";
import alertStyles from "assets/jss/salto-react/views/sweetAlertStyle.js";

import { useValidate } from "../../hooks/useValidate";
import LoadingButton from "@mui/lab/LoadingButton";


import {
  addStatus,
  deleteStatus,
  getStatus,
  updateStatus,
} from "../../services/statusService";

const useStyles = makeStyles(styles);
const useAlertStyles = makeStyles(alertStyles);

export function StatusManager() {
  const [open, setOpen] = useState(true);
  const [modal, setModal] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [statusData, setStatusData] = useState([]);
  const [createModal, setCreateModal] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState({
    status: "",
  });
  const [popupConfirm, setPopupConfirm] = useState(null);
  const [error, setError] = useState({ isError: true });
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const alertClasses = useAlertStyles();




  useEffect(() => {
    fetchData();
  }, []);


  async function fetchData() {
    setIsLoading(true);
    const res = await getStatus(dispatch);
    if (res.isOk){
      setStatusData(res.data.data);
    }
    setIsLoading(false);
    setOpen(false);
  }

  /**
   * Click button delete in row dataTable
   * @param {string} id
   * @param {int} index
   * @returns {any}
   */
  async function handleDelete(id) {
    const res = await deleteStatus(id, dispatch);
    if (res.isOk) {
      fetchData();
    } else {
      return setError(getErrors(res.errors.message));
    }
  }

  function getErrors(data) {
    const error = Object.keys(data).reduce((obj, key) => {
      obj[key] = data[key][0];
      return obj;
    }, {});

    return { ...error, isError: true };
  }

  /**
   * handle show popup confirm
   * @param {any} id
   * @returns {any}
   */
  function handPopupConfirm(id) {
    setPopupConfirm(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={"ステータスの削除"}
        onConfirm={() => {
          handleDelete(id);
          hideAlert();
        }}
        onCancel={() => {
          hideAlert();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
        confirmBtnText="はい"
        cancelBtnText="いいえ"
        showCancel
      >
        {"削除しますか？"}
      </SweetAlert>
    );
  }

  // Hide alert
  function hideAlert() {
    setPopupConfirm(null);
  }

  /**
   * Click button add user
   * @returns {any}
   */
  function handleAdd() {
    setCreateModal(true);
    setError({})
    setStatusUpdate({
      status: "",
    });
    setModal(true);
  }

  /**
   * handle change input select in popup
   * @param {string} value
   * @param {string} key
   * @returns {any}
   */
  function handleChange(e) {
    const { name, value } = e.target
    const [isError, key, message] = useValidate(name, value);
    setError((state) => ({
      ...state,
      [key]: isError ? message : null,
      isError,
    }));

    setStatusUpdate({
      ...statusUpdate,
      status: value,
    });
  }

  /**
   * Click button Save
   * @param {object} user
   * @returns {any}
   */
  async function handleSave() {
    const data = statusUpdate;

    setIsLoading(true);
    setIsLoadingBtn(true);

    const res = statusUpdate.id
      ? await updateStatus(data, dispatch)
      : await addStatus(data, dispatch);
    if (!res.isOk) {
      setIsLoading(false);
      setIsLoadingBtn(false);
      return setError(getErrors(res.errors.message));
    } else {
      fetchData();
    }

    
    setModal(false);
    setIsLoading(false);
    setIsLoadingBtn(false);
  }

  /**
   * click button edit set value popup
   * @param {string} id
   * @param {int} index
   * @returns {any}
   */
  function handleEdit(statusData) {
    setCreateModal(false);
    setError({})
    setStatusUpdate({
      id: statusData.id,
      status: statusData.status,
    });
    setModal(true);
  }

  /**
   * custom column dataTable
   * @param {object} user
   * @param {int} index
   * @returns {any}
   */
  const rows = statusData && statusData.map((status, index) => {
    let updated_at;
    if (status.updated_at) {
      updated_at = Moment(status.updated_at).format("YYYY-MM-DD HH:mm:ss");
    }

    let created_at;
    if (status.created_at) {
      created_at = Moment(status.created_at).format("YYYY-MM-DD HH:mm:ss");
    }

    let actions = (
      // we've added some custom button actions
      <div className="actions-right">
        <Button
          justIcon
          color="success"
          onClick={() => handleEdit(status)}
        >
          <EditIcon />
        </Button>
        {/* use this button to remove the data row */}
        <Button
          justIcon
          onClick={() => handPopupConfirm(status.id)}
          color="danger"
        >
          <Delete />
        </Button>{" "}
      </div>
    );

    let row_index = index + 1;

    return {
      ...status,
      updated_at,
      created_at,
      actions: actions,
      row_index,
    };
  });

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });


  return (
    <>
      
      <Box className="backdrop">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
      <GridContainer>
        {popupConfirm}
        <Dialog
          fullWidth
          maxWidth="sm"
          classes={{
            root: classes.center,
          }}
          open={modal}
          transition={Transition}
          keepMounted
          onClose={() => {
            setModal(false);
          }}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
          disableEnforceFocus
        >
          <DialogTitle>
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => {
                setModal(false);
              }}
            >
              <Close className={classes.modalClose} />
            </Button>
            {createModal ? "ステータス登録" : "ステータス編集"}
          </DialogTitle>
          <DialogContent>
            <GridContainer
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <GridItem xs={12}>
                <CustomInput
                  labelText="ステータス"
                  success={error.status !== undefined && error.status !== ""}
                  error={isEmpty(error.status) ? false: true}
                  helperText={error.status}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    required: true,
                    name:"status",
                    type: "text",
                    value: statusUpdate.status,
                    onChange: (e) => {
                      handleChange(e);
                    },
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <Box sx={{ py: 2 }}>
                  <LoadingButton
                    color="secondary"
                    disabled={error.isError}
                    loading={isLoadingBtn}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    onClick={handleSave}
                  >
                      保存
                  </LoadingButton>
                </Box>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
        <GridItem xs={12}>
          <Button
            className="btn-add-status"
            color="rose"
            onClick={() => handleAdd()}
          >
            ステータス追加
          </Button>
          <Card>
            <CardHeader color="primary" icon style={{ color: "black" }}>
              <h5 className={classes.titleTableStyle + " text-dark-grey"}>
                ステータス一覧
              </h5>
            </CardHeader>
            <CardBody>
              <DataTable
                rows={rows}
                columns={[
                  {
                    key: "row_index",
                    label: "#",
                    minWidth: 20,
                  },
                  {
                    key: "status",
                    label: "ステータス",
                    minWidth: 200,
                    wordBreak: "break-all",
                  },
                  { key: "updated_at", label: "更新日", minWidth: 200 },
                  {
                    key: "updated_by",
                    label: "更新者",
                    minWidth: 150,
                    wordBreak: "break-all",
                  },
                  { key: "created_at", label: "作成日", minWidth: 200 },
                  {
                    key: "created_by",
                    label: "作成者",
                    minWidth: 150,
                    wordBreak: "break-all",
                  },
                  {
                    key: "actions",
                    label: "ACTION",
                    minWidth: 150,
                    sortable: false,
                  },
                ]}
                rowsPerPageOptions={[10, 20, 50, 100]}
                defaultRowsPerPage={10}
                pagination
                searchBar
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
