import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import logo from "../../../../assets/img/logo-salesforce.png";

function Basic() {
  return (
    <Card id="basic">
      <CardBody>
        <GridContainer spacing={3} alignItems="center" direction="row">
          <GridItem xs="auto">
            <CardAvatar className="avatar d-flex align-items-center">
              <img width="100%" src={logo} alt="bruce" />
            </CardAvatar>
          </GridItem>
          <GridItem xs="auto">
            <div className="h-100">
              <h3 className="mb-1 text-bold text-dark-grey">
                Salesforce連携情報設定
              </h3>
              <p className="mb-0 font-weight-normal text-sm text-grey taishou-desc">
                Connectedアプリで連携する情報を設定してください。
                <br />
                Connectedアプリ作成は
                <a
                  className="text-primary"
                  href="https://help.salesforce.com/s/articleView?id=sf.connected_app_create.htm&type=5"
                  target="blank"
                >
                  ここ
                </a>
                です。
              </p>
            </div>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

export default Basic;
