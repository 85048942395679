import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchSetting } from "../../../../services/api.sfSetting";

export function useData() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    document.title = "Salesforce一般設定";
    async function callAPI() {
      const res = await fetchSetting(dispatch);
      if (res.isOk) {
        const data = res.data;
        if (data.connect_type === null || data.connect_type === "") {
          data.connect_type = 1;
        }
        setData(data);
      }
      setIsOpen(false);

    }
    callAPI();
  }, []);

  return [isOpen, data, setData];
}
