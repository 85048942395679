import React from "react";
import Box from "@material-ui/core/Box";
import ContactPhone from "@material-ui/icons/ContactPhone";
import Settings from "@material-ui/icons/Settings";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Moment from "moment";

import Spinner from "react-bootstrap/Spinner";

export default function ListCaptureBatch(props) {
  const {
    handleChangeCardOpen,
    onDelete,
    handleChangeBatchValue,
    batchItem,
  } = props;

  const schedule = parseInt(batchItem.schedule);

  // Function render schedule to schedule type
  function renderSchedule() {
    switch (parseInt(schedule)) {
      case 1:
        return "一回のみ";
      case 2:
        return "分";
      case 3:
        return "毎日";
      case 4:
        return "毎週";
      case 5:
        return "毎月";
      default:
        return "未実行";
    }
  }

  // Function render weekday from number to string
  function renderWeekday(weekday) {
    let weekdayData = [];
    const weekdayArr = weekday.split(",");
    weekdayArr.map((day, index) => {
      // If day is checked and is not sunday, push data to weekDay data
      if (day === "1" && index > 0) {
        switch (index) {
          case 1:
            weekdayData.push("月曜日");
            break;
          case 2:
            weekdayData.push("火曜日");
            break;
          case 3:
            weekdayData.push("水曜日");
            break;
          case 4:
            weekdayData.push("木曜日");
            break;
          case 5:
            weekdayData.push("金曜日");
            break;
          case 6:
            weekdayData.push("土曜日");
            break;
        }
      }
    });

    // If sunday is checked, push data to weekdayData
    if (weekdayArr[0] === "1") {
      weekdayData.push("日曜日");
    }

    return weekdayData.join("、 ");
  }

  // Handle when click item in dropdown
  function handleOnClickDropdown(event) {
    // If item is 編集, open 編集 card
    if (event === "編集") {
      handleChangeBatchValue(batchItem.batch_code, batchItem.batch_name);
      handleChangeCardOpen("setting");
    }

    // If item is ログ, open ログ card
    if (event === "ログ") {
      handleChangeBatchValue(batchItem.batch_code, batchItem.batch_name);
      handleChangeCardOpen("log");
    }

    // If item is 削除, delete card
    if (event === "削除") {
      onDelete(batchItem.batch_code);
    }
  }
  return (
    <Card>
      <CardHeader color="info" icon>
        <CardIcon color="info" className="batch-card-icon">
          <ContactPhone />
        </CardIcon>
        <h5 className="schedule-setting-title-right text-bold text-dark-grey">{batchItem.batch_name}</h5>
      </CardHeader>
      <CardBody className="text-grey">
        <Box sx={{ p: 1 }}>
          <GridContainer spacing={3} direction="row">
            <GridItem xs={7}>スケジュール</GridItem>
            <GridItem xs={5} align="right">
              {renderSchedule(batchItem)}
            </GridItem>
          </GridContainer>
        </Box>
        {schedule === 1 && (
          <Box sx={{ p: 1 }}>
            <GridContainer spacing={3} direction="row">
              <GridItem xs={5}>開始日</GridItem>
              <GridItem xs={7} align={"right"}>
                {batchItem.start_date
                  ? Moment.utc(batchItem.start_date).format("YYYY-MM-DD HH:mm")
                  : ""}
              </GridItem>
            </GridContainer>
          </Box>
        )}
        {schedule === 2 && (
          <Box sx={{ p: 1 }}>
            <GridContainer spacing={3} direction="row">
              <GridItem xs={5}>分</GridItem>
              <GridItem xs={7} align={"right"}>
                {batchItem.minute}
              </GridItem>
            </GridContainer>
          </Box>
        )}
        {schedule === 3 && (
          <Box sx={{ p: 1 }}>
            <GridContainer spacing={3} direction="row">
              <GridItem xs={5}>時間</GridItem>
              <GridItem xs={7} align={"right"}>
                {batchItem.hour ? batchItem.hour.substr(0, 5) : ""}
              </GridItem>
            </GridContainer>
          </Box>
        )}
        {schedule === 4 && (
          <Box sx={{ p: 1 }}>
            <GridContainer spacing={3} direction="row">
              <GridItem xs={7}>{renderWeekday(batchItem.weekday)}</GridItem>
              <GridItem xs={5} align={"right"}>
                {batchItem.hour ? batchItem.hour.substr(0, 5) : ""}
              </GridItem>
            </GridContainer>
          </Box>
        )}
        {schedule === 5 && (
          <Box sx={{ p: 1 }}>
            <GridContainer spacing={3} direction="row">
              <GridItem xs={5}>{batchItem.day}</GridItem>
              <GridItem xs={7} align={"right"}>
                {batchItem.hour ? batchItem.hour.substr(0, 5) : ""}
              </GridItem>
            </GridContainer>
          </Box>
        )}
      </CardBody>
      <CardFooter stats>
        <GridContainer alignItems="center">
          <GridItem xs={6}>
            <Box sx={{ p: 1 }}>
              {batchItem?.status === 1 ? (
                <Spinner className="text-light" animation="grow" size="sm" />
              ) : (
                <div
                  className="text-success "
                  style={{
                    height: "15px",
                    width: "15px",
                    background: "#4CAF50",
                    borderRadius: "10px",
                  }}
                  role="status"
                ></div>
              )}
            </Box>
          </GridItem>
          <GridItem xs={6} align="right">
            <Box sx={{ p: 1 }} className="btn-setting">
              <CustomDropdown
                caret={false}
                buttonText={<Settings />}
                onClick={handleOnClickDropdown}
                buttonProps={{ simple: true, color: "github" }}
                dropPlacement="bottom"
                dropdownList={["編集", "ログ", "削除"]}
              />
            </Box>
          </GridItem>
        </GridContainer>
      </CardFooter>
    </Card>
  );
}
