import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/salto-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

const useStyles = makeStyles(styles);
Square.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  format: PropTypes.string,
  marks: PropTypes.string,
};

export default function Square(props) {
  const classes = useStyles();
  const { title, number, icon, format, marks } = props;
  return (
    <Card>
      <CardHeader className={classes.cardHeaderWrap} stats icon>
        <CardIcon color={format} className={marks ? marks : ""}>
          <img style={{ width: "80px" }} src={icon} />
        </CardIcon>
        <p className={classes.cardCategory}>{title}</p>
        <h3 className={classes.cardTitle}>
          <span className={classes.numberContent}>{number}</span>{" "}
          <small className={classes.subjectContent}>件</small>
        </h3>
      </CardHeader>
    </Card>
  );
}
