import { userConstants } from "../constants";
import { userService } from "../services";
import { alertActions } from "./";
import { URL } from "../helpers/url";

export const userActions = {
  login,
  logout,
  register,
  getAll,
  delete: _delete,
  update,
  updateUserInfo,
};

/**
 * Action call service login
 * @param {string} username
 * @param {string} password
 * @returns {any}
 */
function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));

    userService.login(username, password).then(
      (user) => {
        dispatch(success(user));
        if (
          (user["company_id"] === "" ||
            user["company_id"] === null ||
            user["company_id"] === undefined) &&
          user["is_staff"] === true
        ) {
          window.location.href = URL.EC_SETTING;
        } else {
          dispatch(updateSalesforceSetting(user));
          window.location.href = URL.DAHSBOARD;
        }
      },
      (error) => {
        dispatch(failure(error));
        Object.values(error).map(function (value) {
          dispatch(alertActions.error(value));
        });
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function updateSalesforceSetting(user) {
    return {
      type: "SALESFORCESETTING_GETDETAIL_SUCCESS",
      response: user.user_salefore,
    };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function updateUserInfo(key, setting) {
  let user = JSON.parse(localStorage.getItem("user"));
  user[key] = setting;
  localStorage.setItem("user", JSON.stringify(user));

  return (dispatch) => {
    dispatch({ type: userConstants.LOGIN_SUCCESS, user });
  };
}

/**
 * Action call service logout
 * @returns {}
 */
function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

/**
 * Action call service register
 * @param {any} user
 * @param {any} permissions
 * @returns {any}
 */
function register(user, permissions) {
  return (dispatch) => {
    dispatch(request(user));

    userService.register(user, permissions).then(
      (data) => {
        dispatch(success());
        dispatch(alertActions.success(data.message));
      },
      (error) => {
        dispatch(failure(error));
        Object.values(error).map(function (value) {
          dispatch(alertActions.error(value));
        });
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

/**
 * get all data users
 * @returns {any}
 */
function getAll() {
  return (dispatch) => {
    dispatch(request());

    userService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(user_id) {
  return (dispatch) => {
    dispatch(request({ user_id }));

    userService.delete(user_id).then(
      () => {
        dispatch(success(user_id));
      },
      (error) => {
        dispatch(failure(user_id, error));
      }
    );
  };

  function request(id) {
    return { type: userConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: userConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: userConstants.DELETE_FAILURE, id, error };
  }
}

/**
 * Description
 * @param {any} user
 * @param {any} permissions
 * @returns {any}
 */
function update(user, permissions) {
  return (dispatch) => {
    dispatch(request({ user }));

    userService.update(user, permissions).then(
      (data) => {
        dispatch(success());
        dispatch(alertActions.success(data.message));
      },
      (error) => {
        dispatch(failure(error));
        Object.values(error).map(function (value) {
          dispatch(alertActions.error(value));
        });
      }
    );
  };

  function request() {
    return { type: userConstants.UPDATE_REQUEST };
  }
  function success(users) {
    return { type: userConstants.UPDATE_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_FAILURE, error };
  }
}
