import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Search from "@material-ui/icons/Search";
import { visuallyHidden } from "@mui/utils";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useRef, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch } from "react-redux";
import { fetchCaptureLog } from "../../../services/api.sfBatch";

export default function CaptureError(props) {
  const scrollRef = useRef(null);
  const { batchCode } = props;
  const dispatch = useDispatch();
  const [captureLog, setCaptureLog] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [orderBy, setOrderBy] = useState("");
  const [orderType, setOrderType] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [countData, setCoundData] = React.useState(0);
  const [searchData, setSearchData] = React.useState("");
  const [callSearch, setCallSearch] = React.useState(false);

  useEffect(() => {
    if (batchCode) {
      fetchLog();
    }
  }, [batchCode, page, rowsPerPage, orderBy, orderType, callSearch]);

  async function fetchLog() {
    const params = {
      page: page + 1,
      rowsPerPage,
      orderBy,
      orderType,
      searchData,
    };
    setIsLoading(true);
    const res = await fetchCaptureLog(batchCode, params, dispatch);
    setCaptureLog(res.data.data);
    setCoundData(res.data.number_of_row);
    setIsLoading(false);
  }
  const columnDatas = [
    { key: "customer_no", label: "顧客番号", minWidth: 130 },
    { key: "tell", label: "電話番号", minWidth: 130 },
    { key: "list_group_name", label: "リストグループ", minWidth: 190 },
    { key: "list_name", label: "リスト", minWidth: 150 },
    { key: "message", label: "理由", minWidth: 100 },
  ];

  const createSortHandler = (property) => () => {
    const isAsc = orderBy === property && orderType === "asc";
    setOrderType(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangePage(event, newPage) {
    scrollRef.current.scrollIntoView();
    setPage(newPage);
  }

  function handleChangeInput(value) {
    setSearchData(value);
  }

  function searchDataTable() {
    setCallSearch(!callSearch);
  }

  /**
   * Render for table body
   * @returns string
   */
  function renderRow() {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell align="center" colSpan={columnDatas.length}>
            <Spinner className="text-light" animation="grow" />
            <Spinner className="text-light" animation="grow" />
            <Spinner className="text-light" animation="grow" />
          </TableCell>
        </TableRow>
      );
    }

    if (captureLog.length === 0) {
      return (
        <TableRow>
          <TableCell align="center" colSpan={columnDatas.length + 1}>
            <div style={{ width: "100%", textAlign: "center" }}>
              該当データが存在しません。
            </div>
          </TableCell>
        </TableRow>
      );
    }

    return captureLog.map((item, idx) => {
      return (
        <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
          {columnDatas.map((column) => {
            return <TableCell key={column.key}>{item[column.key]}</TableCell>;
          })}
        </TableRow>
      );
    });
  }

  return (
    <>
      <div ref={scrollRef} style={{ height: "1px" }}></div>
      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info" className="danger-card-icon">
            <i className="material-icons opacity-10">report_problem</i>
          </CardIcon>
          <h5 className="schedule-setting-title text-bold text-dark-grey">
            取込エラー
          </h5>
          <p className="schedule-setting-subtitle text-grey">
            EverCallに取込できないデータ
          </p>
        </CardHeader>
        <CardBody>
          <GridContainer justifyContent="flex-end" className="error-search-bar">
            <GridItem>
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: (e) => {
                    handleChangeInput(e.target.value);
                  },
                  placeholder: "Search...",
                  type: "text",
                  value: searchData,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        className="btn-search-table"
                        color="github"
                        onClick={searchDataTable}
                      >
                        <Search />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </GridItem>
          </GridContainer>
          <Paper
            className="data-table"
            sx={{ width: "100%", overflow: "hidden" }}
          >
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columnDatas.map((column) => (
                      <TableCell
                        key={column.key}
                        padding="normal"
                        sortDirection={
                          orderBy == column.key ? orderType : false
                        }
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          active={orderBy === column.key}
                          direction={orderBy === column.key ? orderType : "asc"}
                          onClick={createSortHandler(column.key)}
                        >
                          {column.label}
                          {orderBy === column.key ? (
                            <Box component="span" sx={visuallyHidden}>
                              {orderType === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{renderRow()}</TableBody>
              </Table>
            </TableContainer>
            {captureLog.length > 0 ? (
              <GridContainer
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <GridItem sx={6}>
                  {`Showing ${page * rowsPerPage + 1} to
                  ${captureLog.length + page * rowsPerPage} of ${countData}
                  entries`}
                </GridItem>
                <GridItem sx={6}>
                  <TablePagination
                    className="capture-error-paginate"
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    component="div"
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </GridItem>
              </GridContainer>
            ) : (
              ""
            )}
          </Paper>
        </CardBody>
      </Card>
    </>
  );
}
