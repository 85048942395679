import toastify from "../components/Toastify";
import { authHeader, logout } from "../helpers";
import { history } from "helpers";
import { ERROR } from "helpers/url";
import { CONSTANT } from "../constants/constant";

export const TIME_OUT = CONSTANT.TIME_OUT;

function getHeader() {
  return { ...authHeader(), "Content-Type": "application/json;charset:utf-8" };
}

/**
 * Use for post with FormData
 * @param {string} url
 * @param {object} body
 * @param {int} timeout
 * @returns {object}
 */
export async function fetchAsFormData(url, body = {}, timeout = TIME_OUT) {
  const options = { method: "POST", headers: { ...authHeader() }, body };

  return handleFetch(url, options, timeout);
}

/**
 * Use for post with FormData
 * @param {string} url
 * @param {object} body
 * @param {int} timeout
 * @returns {object}
 */
export async function fetchAsPutFormData(url, body = {}, timeout = TIME_OUT) {
  const options = { method: "PUT", headers: { ...authHeader() }, body };

  return handleFetch(url, options, timeout);
}

/**
 * Fetch with method DELETE
 * @param {string} url
 * @param {object} body
 * @param {int} timeout
 * @returns {object}
 */
export async function fetchAsDeleteFormData(
  url,
  body = {},
  timeout = TIME_OUT
) {
  const options = { method: "DELETE", headers: { ...authHeader() }, body };

  return handleFetch(url, options, timeout);
}

/**
 * Fetch with method DELETE
 * @param {string} url
 * @param {object} body
 * @param {int} timeout
 * @returns {object}
 */
export async function fetchAsDelete(url, body = {}, timeout = TIME_OUT) {
  const options = { method: "DELETE", headers: getHeader(), body:JSON.stringify(body) };
  return handleFetch(url, options, timeout);
}

/**
 * Fetch data with method GET
 * @param {string} url
 * @param int timeout
 * @returns
 */
export async function fetchAsGet(url, timeout = TIME_OUT) {
  return handleFetch(url, { method: "GET", headers: getHeader() }, timeout);
}

/**
 * Fetch data with method POST
 * @param string url
 * @param {object} body
 * @param {int} timeout
 * @returns
 */
export async function fetchAsPost(url, body = {}, timeout = TIME_OUT) {
  const options = {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(body),
  };

  return handleFetch(url, options, timeout);
}

export async function fetchAsPut(url, body = {}, timeout = TIME_OUT) {
  const options = {
    method: "PUT",
    headers: getHeader(),
    body: JSON.stringify(body),
  };

  return handleFetch(url, options, timeout);
}

/**
 * { isOk: false, data: {}, isInvalid: false }
 * isInvalid = false: handle for cancel request, Internal server error
 * @param {string} url
 * @param {object} requestOptions
 * @param {integer} timeout
 * @returns
 */
async function handleFetch(url, requestOptions, timeout) {
  try {
    const res = await fetchWithTimeout(url, requestOptions, timeout);
    if (!res.ok) {
      if (res.status === 401) {
        logout();
        return location.reload(true);
      }
      if ([404, 405, 415, 429, 403].includes(res.status)) {
        if (res.status === 429) {
          toastify("Too Many Requests");
        }
        if (res.status === 405) {
          toastify("Method Not Allowed");
        }

        if (res.status === 403) {
          history.push(ERROR.PAGE_403);
        }

        if (res.status === 404) {
          const message = await res.text();
          toastify(message);
        }

        if (res.status === 415) {
          toastify("Unsupported Media Type");
        }
        if (res.status === 504) {
          toastify("Gateway Time-out");
        }

        return { isOk: false, data: {}, isInvalid: false, status: res.status };
      }
      const errors = (await res.json()) || res.statusText;
      return { isOk: false, data: {}, errors, isInvalid: true, status: res.status };
    }

    return { isOk: true, data: await res.json(), isInvalid: true };
  } catch (error) {
    if (error.name === "AbortError") {
      toastify("Request Timeout");
    } else {
      toastify("Internal server error");
    }
    return { isOk: false, data: {}, isInvalid: false };
  }
}

async function fetchWithTimeout(resource, requestOptions, timeout = TIME_OUT) {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...requestOptions,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
}
