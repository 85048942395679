import React, { useEffect, useState, createRef } from "react";
import { useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import styles from "assets/jss/salto-react/modalStyle.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { cloneDeep, find, isEmpty } from "lodash";
import { alertActions } from "../../../actions/alert.actions";
import { FILTER_TYPE, KINTONE_FILTER_TYPE } from "../../../helpers/filterType";
import { batchSTMessage } from "../../../messages";
import { fetchBulkImportEC } from "../../../services/api.customer";
import { fetchECList } from "../../../services/api.ecListList";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

export default function ModalComponent(props) {
  const { modal, toggleModal, userInfo, settingInfo } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [conditions, setConditions] = useState([]);
  const [selectList, setSelectList] = useState({
    list_type: 1,
    list_id: "",
    list_group_id: "",
  });
  const [createType, seCreateType] = useState(1);
  const [isUpdating, setIsUpdating] = useState(false);

  const listGroupIdInputRef = createRef();
  const listIdExistInputRef = createRef();
  const listIdSignInInputRef = createRef();

  const [evercallListData, setEvercallListData] = useState([]);

  useEffect(() => {
    async function fetchListData() {
      const res = await fetchECList(selectList.list_group_id);
      if (res.isOk) {
        setEvercallListData(res.data);
        setSelectList((prevState) => {
          return { ...prevState, list_id: "" };
        });
      }
    }

    if (selectList.list_group_id) {
      fetchListData();
    }
  }, [selectList.list_group_id]);

  // Function add new salesforce condition
  function addSalesforceCondition() {
    setConditions((prevState) => {
      let result = cloneDeep(prevState);
      result.push({
        item: "",
        filter_type: "",
        filter_value: "",
      });
      return result;
    });
  }

  // Delete salesforce condition data in data state
  function deleteSFCondition(index) {
    setConditions((prevState) => {
      let result = cloneDeep(prevState);
      result.splice(index, 1);
      return result;
    });
  }

  // Function handle change number
  function handleChangeNumber(index, id, value) {
    value = value.split(" ").join("");
    value = value.split(".").join("");
    value = value.split("　").join("");
    if (!isNaN(Number(value))) {
      handleChangeSFCondition(index, id, value);
    }
  }

  // Function handle when salesforce condition change value
  function handleChangeSFCondition(index, key, value) {
    // Update salesforce condition state
    setConditions((prevState) => {
      let objData = cloneDeep(prevState);
      const k = objData[index][key];

      if (key === "filter_type") {
        if (k === "blank" || k === "not_blank") {
          objData[index].filter_value = "";
        }
        objData[index][key] = !value ? "" : value.key;
      } else if (key === "item") {
        objData[index][key] = !value ? "" : value;
      } else {
        objData[index][key] = value;
      }

      return objData;
    });
  }

  function handleChangeEcCondition(key, value) {
    if (key === "list_type") {
      return setSelectList((prevState) => {
        return {
          ...prevState,
          list_type: parseInt(value),
          list_id: "",
        };
      });
    }

    setSelectList((prevState) => {
      return { ...prevState, [key]: value };
    });
  }

  // Function change list creation type
  function handleChangeCreateType(value) {
    seCreateType(parseInt(value));
    setConditions([]);
  }

  // Function handle when update customer from salesforce/kintone to evercall
  async function handleUpdateCustomer() {
    const query = {
      ...selectList,
      conditions,
      list_creation_type: createType,
    };

    let message;

    const empty_item = find(conditions, { item: "" });
    const empty_filter_type = find(conditions, { filter_type: "" });
    const empty_value = find(conditions, { filter_value: "" });

    if (!query["list_id"]) {
      message = batchSTMessage["list_id"]["blank"];
    }

    if (!query["list_group_id"]) {
      message = batchSTMessage["list_group_id"]["blank"];
    }

    if (
      empty_value &&
      !["not_blank", "blank"].includes(empty_value["filter_type"])
    ) {
      message = batchSTMessage["filter_value"]["blank"];
    }

    if (empty_filter_type) {
      message = batchSTMessage["filter_type"]["blank"];
    }

    if (empty_item) {
      message = batchSTMessage["item"]["blank"];
    }

    if (message) {
      return dispatch(alertActions.error(message));
    }

    setIsUpdating(true);
    await fetchBulkImportEC(query, dispatch);
    setIsUpdating(false);
  }

  function findData(data, key) {
    return find(data, { key });
  }

  function getSchemaOption() {
    const data =
      createType === 1
        ? settingInfo.sf_customer_schema
        : settingInfo.kt_customer_schema;
    return data;
  }
  return (
    <Dialog
      open={modal}
      transition={Transition}
      fullWidth={true}
      maxWidth="md"
      keepMounted
      onClose={() => toggleModal(false)}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={() => toggleModal(false)}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h3 className={classes.modalTitle}>リスト作成</h3>
      </DialogTitle>
      <DialogContent
        id="modal-slide-description"
        className="list-creation-dialog"
      >
        <GridContainer>
          <GridItem xs={12}>
            <Box sx={{ py: 2 }}>
              <RadioGroup
                row
                value={createType}
                onChange={(e) => handleChangeCreateType(e.target.value)}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio name="radio button enabled" />}
                  label="Salesforce"
                  disabled={!userInfo.user_salesforce?.is_cus_connect}
                />
                <FormControlLabel
                  value={2}
                  control={<Radio name="radio button enabled" />}
                  label="キントーン"
                  disabled={!userInfo.user_kintone?.is_cus_connect}
                />
              </RadioGroup>
            </Box>
          </GridItem>
          <GridItem xs={12}>
            {createType === 1 ? (
              <h4 className="text-bold text-dark-grey">
                Salesforce絞り込み条件
              </h4>
            ) : (
              <h4 className="text-bold text-dark-grey">
                キントーン絞り込み条件
              </h4>
            )}
            {createType === 1 ? (
              <p className="text-grey">
                EverCallに取り込みたいデータを絞り込むため、Salesforce絞り込み条件を指定ください。
              </p>
            ) : (
              <p className="text-grey">
                EverCallに取り込みたいデータを絞り込むため、キントーン絞り込み条件を指定ください。
              </p>
            )}
          </GridItem>
          <GridItem xs={12}>
            <GridContainer>
              <GridItem xs={4} className="text-grey">
                項目名
              </GridItem>
              <GridItem xs={3} className="text-grey">
                条件
              </GridItem>
              <GridItem xs={4} className="text-grey">
                値
              </GridItem>
              <GridItem xs={1}></GridItem>
            </GridContainer>
            {conditions.map((data, index) => {
              return (
                <GridContainer key={index} alignItems="center">
                  <GridItem xs={4}>
                    <Autocomplete
                      disablePortal
                      value={!isEmpty(data.item) ? data.item : null}
                      options={getSchemaOption() || []}
                      renderInput={(params) => (
                        <TextField variant="standard" {...params} />
                      )}
                      onChange={(event, newValue) =>
                        handleChangeSFCondition(index, "item", newValue)
                      }
                    />
                  </GridItem>
                  <GridItem xs={3}>
                    <Autocomplete
                      disablePortal
                      value={findData(FILTER_TYPE, data.filter_type) ?? null}
                      options={
                        createType === 1 ? FILTER_TYPE : KINTONE_FILTER_TYPE
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          placeholder="条件"
                        />
                      )}
                      onChange={(event, newValue) =>
                        handleChangeSFCondition(index, "filter_type", newValue)
                      }
                    />
                  </GridItem>
                  <GridItem xs={4} className="list-creation-filter-value">
                    <CustomInput
                      labelText="値"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled:
                          data.filter_type === "blank" ||
                          data.filter_type === "not_blank",
                        type: "text",
                        value: data.filter_value,
                        onChange: (e) =>
                          data.filter_type === ">" ||
                            data.filter_type === "<" ||
                            data.filter_type === "="
                            ? handleChangeNumber(
                              index,
                              "filter_value",
                              e.target.value
                            )
                            : handleChangeSFCondition(
                              index,
                              "filter_value",
                              e.target.value
                            ),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={1}>
                    <Button
                      justIcon
                      color="danger"
                      className="btn-schedule-setting"
                      onClick={() => deleteSFCondition(index)}
                    >
                      <Delete />
                    </Button>
                  </GridItem>
                </GridContainer>
              );
            })}
          </GridItem>
          <GridItem xs={12} align="right">
            <Button
              onClick={addSalesforceCondition}
              justIcon
              className="btn-schedule-setting"
            >
              <Add />
            </Button>
          </GridItem>
          <Box sx={{ py: 1 }}>
            <hr className="horizontal dark" />
          </Box>
          <GridItem xs={12}>
            <h4 className="text-bold text-dark-grey">ECリスト取込条件</h4>
            <p className="text-grey">
              EverCallでリストとリストグループで顧客情報を管理されます。
              <br />
              取込したいリストとリストグループを設定してください。
            </p>
          </GridItem>
          <GridItem xs={12}>
            <Box sx={{ py: 2 }}>
              <RadioGroup
                row
                value={selectList.list_type}
                onChange={(e) =>
                  handleChangeEcCondition("list_type", e.target.value)
                }
              >
                <FormControlLabel
                  value={1}
                  control={<Radio name="radio button enabled" />}
                  className="text-grey"
                  label="既存選択"
                />
                <FormControlLabel
                  value={2}
                  control={<Radio name="radio button enabled" />}
                  className="text-grey"
                  label="新規登録"
                />
              </RadioGroup>
            </Box>
          </GridItem>
          <GridItem xs={12}>
            {selectList.list_type === 1 ? (
              <h4 className="text-bold text-dark-grey">
                存在リスト・リストグループ
              </h4>
            ) : (
              ""
            )}
            {selectList.list_type === 2 ? (
              <h4 className="text-bold text-dark-grey">
                新規リスト・リストグループ作成
              </h4>
            ) : (
              ""
            )}
            <p className="text-grey">
              {selectList.list_type === 1
                ? "EverCallにあるリストとリストグループを入力してください。"
                : ""}
              {selectList.list_type === 2
                ? "EverCallに登録するリスト名、リストグループを入力してください。"
                : ""}
              <br />
              <span className="text-danger">注意</span>
              ：取り込みする際にこのリストでEverCallに登録します。
            </p>
          </GridItem>
          <GridItem xs={12}>
            <Box sx={{ py: 4 }}>
              <GridContainer alignItems="center">
                <GridItem xs={6}>
                  <Box sx={{ py: 2 }}>
                    <InputLabel className="text-grey">
                      リストグループ
                    </InputLabel>
                  </Box>
                  <Autocomplete
                    disablePortal
                    value={
                      findData(
                        settingInfo.ec_list_group,
                        selectList.list_group_id
                      ) ?? null
                    }
                    options={settingInfo.ec_list_group}
                    renderInput={(params) => (
                      <TextField
                        inputRef={listGroupIdInputRef}
                        variant="standard"
                        {...params}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props} key={option.key}>
                        {option.label}
                      </Box>
                    )}
                    onChange={(event, newValue) =>
                      handleChangeEcCondition(
                        "list_group_id",
                        newValue ? newValue.key : ""
                      )
                    }
                  />
                </GridItem>
                {selectList.list_type === 1 ? (
                  <GridItem xs={6}>
                    <Box sx={{ py: 2 }}>
                      <InputLabel className="text-grey">リスト</InputLabel>
                    </Box>
                    <Autocomplete
                      disablePortal
                      value={
                        findData(evercallListData, selectList.list_id) ?? null
                      }
                      options={evercallListData}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          inputRef={listIdExistInputRef}
                        />
                      )}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={option.key}>
                          {option.label}
                        </Box>
                      )}
                      onChange={(event, newValue) =>
                        handleChangeEcCondition(
                          "list_id",
                          newValue ? newValue.key : ""
                        )
                      }
                    />
                  </GridItem>
                ) : (
                  <GridItem xs={6} className="list-creation-input">
                    <InputLabel className="text-grey">リスト</InputLabel>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        inputRef: listIdSignInInputRef,
                        type: "text",
                        placeholder: "リスト名を入力してください",
                        value: selectList.list_id,
                        onChange: (e) =>
                          handleChangeEcCondition("list_id", e.target.value),
                      }}
                    />
                  </GridItem>
                )}
              </GridContainer>
            </Box>
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions className="list-creation-modal-footer">
        <Button className="btn-close-custom" onClick={() => toggleModal(false)}>
          閉じる
        </Button>
        <Button
          className="btn-create-custom"
          onClick={handleUpdateCustomer}
          color="rose"
          disabled={isUpdating}
        >
          登録
          {isUpdating ? (
            <CircularProgress className="icon-loading" color="inherit" />
          ) : (
            ""
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
