import { toast } from "react-toastify";

const options = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

export default function index(message, type = "error") {
  if (type === "error") {
    return toast.error(message, options);
  }
  return toast.success(message, options);
}
