import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "components/CustomButtons/Button.js";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SearchExtend from "./Components/SearchExtendComponent";
import ModalComponent from "./Components/ModalComponent";
import CustomerListTable from "./Components/CustomerListComponent";
import { useSearchParam } from "./hooks/useSearchParam";
import { fetchCusSetting } from "../../services/api.customer";

export default function Customer() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.authentication.user);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { search } = useLocation();
  const [params, setParams] = useState(search);
  const searchExtend = useSearchParam(params);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(true);

  const [settingInfo, setSettingInfo] = useState({
    ec_list_group: [],
    sf_customer_schema: [],
    kt_customer_schema: [],
    result_pattern: [],
    status: [],
  });

  useEffect(() => {
    document.title = "顧客一覧";
    fetchAllData();
  }, []);


  async function fetchAllData() {
    const resCusStgInfo = await fetchCusSetting(dispatch);
    if (resCusStgInfo.isOk) {
      setMappingSettingInfo(resCusStgInfo.data);
    }
    setOpen(false);
  }

  function setMappingSettingInfo(data) {
    const obj = {
      ec_list_group: data.ec_list_group,
      kt_customer_schema: data.kt_customer_schema.map((item) => item.label),
      sf_customer_schema: data.sf_customer_schema.map((item) => item.label),
      result_pattern: data.result_pattern,
      status: [{ id: 0, status: "未ステータス" }, ...data.statuses],
    };

    setSettingInfo(obj);
  }

  return (
    <>
      <Box className="backdrop">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
      <GridContainer spacing={3}>
        <GridItem xs={12}>
          <Button
            className="btn-list-creation"
            color="github"
            onClick={() => setIsOpenModal(!isOpenModal)}
          >
            ECのリスト作成
          </Button>
          <ModalComponent
            modal={isOpenModal}
            toggleModal={() => setIsOpenModal(!isOpenModal)}
            userInfo={userInfo}
            settingInfo={settingInfo}
          />
        </GridItem>
        <GridItem xs={12}>
          <SearchExtend
            searchExtend={searchExtend}
            onChangeParam={(params) => setParams(params)}
            isLoading={isLoading}
            onLoading={(loading) => setIsLoading(loading)}
          />
        </GridItem>
        <GridItem xs={12}>
          <CustomerListTable
            userInfo={userInfo}
            settingInfo={settingInfo}
            searchExtend={searchExtend}
            onChangeParam={(params) => setParams(params)}
            onLoading={(loading) => setIsLoading(loading)}
          />
        </GridItem>
      </GridContainer>
    </>
  );
}
