import Moment from "moment";

export function useInitialSFBatchValue() {
  return [
    {
      batch_code: "BATCH_01",
      batch_name: "リスト取込バッチ",
      start_date: null,
      day: 1,
      hour: null,
      minute: null,
      month: null,
      schedule: 1,
      status: 0,
      weekday: "0,0,0,0,0,0,0",
      is_active: false
    },
    {
      batch_code: "BATCH_02",
      batch_name: "結果反映バッチ",
      start_date: null,
      day: 1,
      hour: null,
      minute: null,
      month: null,
      schedule: 1,
      status: 0,
      weekday: "0,0,0,0,0,0,0",
      is_active: false
    },
    {
      batch_code: "BATCH_03",
      batch_name: "通話履歴更新バッチ",
      start_date: null,
      day: 1,
      hour: null,
      minute: null,
      month: null,
      schedule: 1,
      status: 0,
      weekday: "0,0,0,0,0,0,0",
      is_active: false
    }
  ];
}

export function useInitialKTBatchValue() {
  return [
    {
      batch_code: "BATCH_04",
      batch_name: "リスト取込バッチ",
      start_date: null,
      day: 1,
      hour: null,
      minute: null,
      month: null,
      schedule: 1,
      status: 0,
      weekday: "0,0,0,0,0,0,0",
      is_active: false
    },
    {
      batch_code: "BATCH_05",
      batch_name: "結果反映バッチ",
      start_date: null,
      day: 1,
      hour: null,
      minute: null,
      month: null,
      schedule: 1,
      status: 0,
      weekday: "0,0,0,0,0,0,0",
      is_active: false
    },
    {
      batch_code: "BATCH_06",
      batch_name: "通話履歴更新バッチ",
      start_date: null,
      day: 1,
      hour: null,
      minute: null,
      month: null,
      schedule: 1,
      status: 0,
      weekday: "0,0,0,0,0,0,0",
      is_active: false
    }
  ];
}

export function useValue(key, value) {
  if (key === "minute" && value < 0) {
    value = 0;
  }

  // If key is hour, format to HH:mm
  if (key === "hour" && Moment(value, "HH:mm", true).isValid()) {
    value = Moment(value).format("HH:mm");
  }

  // If key is start_date, format to YYYY-MM-DD HH:mm
  if (["start_date", "end_date"].includes(key) && Moment(value, "YYYY-MM-DD HH:mm", true).isValid()) {
    value = Moment(value).format("YYYY-MM-DD HH:mm");
  }

  return value;
}

export function getTitleFilter(code) {
  switch (code) {
    case "BATCH_01":
      return {
        title: "Salesforce絞り込み条件",
        desc: "EverCallに取り込みたいデータを絞り込むため、Salesforce絞り込み条件を指定ください。"
      };
    case "BATCH_02":
      return {
        title: "EverCall顧客データ絞り込み条件",
        desc: "Salesforceに顧客データを取り込みするために、EverCall顧客データの絞り込み条件を指定ください。"
      };
    case "BATCH_03":
      return {
        title: "EverCall通話履歴絞り込み条件",
        desc: "Salesforceに通話履歴データを取り込みするために、EverCall通話履歴の絞り込み条件を指定ください。"
      };
    case "BATCH_04":
      return {
        title: "Kintone絞り込み条件",
        desc: "EverCallに取り込みたいデータを絞り込むため、Kintone絞り込み条件を指定ください。"
      };
    case "BATCH_05":
      return {
        title: "Kintone顧客データ絞り込み条件",
        desc: "Kintoneに顧客データを取り込みするために、EverCall顧客データの絞り込み条件を指定ください。"
      };
    case "BATCH_06":
      return {
        title: "EverCall通話履歴絞り込み条件",
        desc: "Kintoneに通話履歴データを取り込みするために、EverCall通話履歴の絞り込み条件を指定ください。"
      };

    default:
      return {
        title: "",
        desc: ""
      };
  }
}

export function getTitleImport(code) {
  switch (code) {
    case "BATCH_01":
      return {
        title: "EverCallリスト・リストグループ指定",
        desc: "EverCallにあるリストとリストグループを入力してください。"
      };
    case "BATCH_02":
      return {
        title: "EverCall顧客データ絞り込み条件",
        desc: "EverCallにあるリストとリストグループを選択してください。"
      };

    case "BATCH_03":
      return {
        title: "EverCall通話履歴データ絞り込み条件",
        desc: "EverCallにあるリストとリストグループを選択してください。"
      };
    case "BATCH_04":
      return {
        title: "EverCallリスト・リストグループ指定",
        desc: "EverCallにあるリストとリストグループを入力してください。"
      };
    case "BATCH_05":
      return {
        title: "EverCall顧客データ絞り込み条件",
        desc: "EverCallにあるリストとリストグループを選択してください。"
      };
    case "BATCH_06":
      return {
        title: "EverCall通話履歴データ絞り込み条件",
        desc: "EverCallにあるリストとリストグループを選択してください。"
      };

    default:
      return {
        title: "",
        desc: ""
      };
  }
}

export function useDisableButton(payload) {
  const { batch_code } = payload;
  if (batch_code === "BATCH_01" || batch_code === "BATCH_04") {
    return validateSettingOne(payload);
  }

  if (batch_code === "BATCH_02" || batch_code === "BATCH_05") {
    return validateSettingTwo(payload);
  }
  if (batch_code === "BATCH_03" || batch_code === "BATCH_06") {
    return validateSettingThree(payload);
  }

  return false;
}

export function validateSettingOne(payload) {
  const { evercall_list_condition } = payload;
  let isDisable = false;
  if (validateBatchSetting(payload)) {
    isDisable = true;
  }

  if (validateFilterCondition(payload)) {
    isDisable = true;
  }

  if (validateGroupAndList(evercall_list_condition)) {
    isDisable = true;
  }
  return isDisable;
}

export function validateSettingTwo(payload) {
  let isDisable = false;
  if (validateBatchSetting(payload)) {
    isDisable = true;
  }

  if (validateBatchImport(payload)) {
    isDisable = true;
  }

  return isDisable;
}

export function validateSettingThree(payload) {
  const { schedule, batch_filter_condition } = payload;
  let isDisable = false;
  if (validateBatchSetting(payload)) {
    isDisable = true;
  }

  if (schedule === 1) {
    if (batch_filter_condition.length === 0) {
      return true;
    }

    const conditions = JSON.parse(batch_filter_condition);
    if (!conditions.start_date || conditions.start_date.length === 0 ||
      !Moment(conditions.start_date, Moment.ISO_8601, true).isValid()) {
      return true;
    }

    if (!conditions.end_date || conditions.end_date.length === 0 ||
      !Moment(conditions.end_date, Moment.ISO_8601, true).isValid()) {
      return true;
    }
  }

  return isDisable;
}

export function validateBatchSetting(payload) {
  const { schedule, start_date, hour, minute, weekday, day } = payload;
  if (schedule === 1 && (!start_date || start_date.length === 0 || !Moment(start_date, Moment.ISO_8601, true).isValid())) {
    return true;
  }

  if (schedule === 2) {
    return !minute || minute === null || parseInt(minute) === 0 || minute.toString().length > 255;
  }

  if (schedule === 3) {
    return checkHour(hour);
  }

  if (schedule === 4) {
    return checkHour(hour) || checkWeeks(weekday);
  }

  if (schedule === 5) {
    return checkDay(day) || checkWeeks(weekday);
  }

  return false;
}

export function validateBatchImport(payload) {
  const { schedule, batch_filter_condition } = payload;
  if (batch_filter_condition.length === 0) {
    return true;
  }

  const conditions = JSON.parse(batch_filter_condition);

  if (schedule === 1) {
    if (!conditions.start_date || conditions.start_date.length === 0 ||
      !Moment(conditions.start_date, Moment.ISO_8601, true).isValid()) {
      return true;
    }

    if (!conditions.end_date || conditions.end_date.length === 0 ||
      !Moment(conditions.end_date, Moment.ISO_8601, true).isValid()) {
      return true;
    }
  }

  if (schedule !== 1 && validateGroupAndList(batch_filter_condition)) {
    return true;
  }
  return false;
}

export function checkWeeks(weekday) {
  const weekdays = weekday.split(",").filter(item => item > 0);

  return weekdays.length === 0;
}

export function checkDay(day) {
  return !(parseInt(day) >= 1 && parseInt(day) <= 31);
}

export function checkHour(hour) {
  return !hour || hour === null || !Moment(hour, "HH:mm").isValid();
}

export function validateFilterCondition(payload) {
  const data = payload.filter_condition;
  if (!data) {
    return false;
  }

  return data.some((el) => el.filter_type === "" || el.item === "" ||
    (!["blank", "not_blank"].includes(el.filter_type) && el.filter_value === "")
  );
}

export function validateGroupAndList(data) {
  if (!data) {
    return true;
  }

  const condition = JSON.parse(data);
  return !condition.list_group_id || !condition.list_id;
}
