export const FILTER_TYPE = [
  {
    key: "blank",
    label: "空白",
  },
  {
    key: "not_blank",
    label: "空白ではない",
  },
  {
    key: "contain",
    label: "含むテキスト",
  },
  {
    key: "start_with",
    label: "始まるテキスト",
  },
  {
    key: "end_with",
    label: "終わるテキスト",
  },
  {
    key: "equal",
    label: "完全一致するテキスト",
  },
  {
    key: ">",
    label: "より大き",
  },
  {
    key: "<",
    label: "より小さい",
  },
  {
    key: "=",
    label: "エコール",
  },
];

export const KINTONE_FILTER_TYPE = [
  {
    key: "blank",
    label: "空白",
  },
  {
    key: "not_blank",
    label: "空白ではない",
  },
  {
    key: "equal",
    label: "完全一致するテキスト",
  },
  {
    key: ">",
    label: "より大き",
  },
  {
    key: "<",
    label: "より小さい",
  },
  {
    key: "=",
    label: "エコール",
  },
];
