import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import Settings from "@material-ui/icons/Settings";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import BatchLog from "./Components/BatchLog";
import CaptureError from "./Components/CaptureError";
import ListCaptureBatch from "./Components/ListCapture";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { findObjByKey } from "../../helpers/common";
import BatchSetting from "./Components/BatchSetting";
import FilterCondition from "./Components/FilterCondition";
import ImportCondition from './Components/ImportCondition';
import { useValidate } from "../../hooks/useValidate";
import { useInitialKTBatchValue, useValue, getTitleImport, getTitleFilter, useDisableButton } from "../../hooks/useBatchCommon";
import { fetchDelete, fetchSetting, fetchUpdate } from "../../services/api.ktBatch";

export default function index() {
  const dispatch = useDispatch();
  const [cardOpen, setCardOpen] = useState("");
  const [open, setOpen] = useState(true);
  const [batchCode, setBatchCode] = useState("");
  const [data, setData] = useState(useInitialKTBatchValue());
  const [cusSchema, setCusSchema] = useState([]);
  const [listGroups, setListGroups] = useState([]);
  const [filterCondition, setFilterCondition] = useState([]);
  const [importCondition, setImportCondition] = useState({ list_type: 1, list_group_id: "", list_id: "" });
  const [batchConditions, setBatchConditions] = useState([]);
  const [model, setModel] = useState({});
  const [isLoadingBtn, setIsLoadingBtn] = useState(true);
  const [errors, setErrors] = useState({
    isError: false
  });

  useEffect(() => {
    document.title = "バッチ設定";

    async function fetchAllApi() {
      const res = await fetchSetting(dispatch);
      if (res.isOk) {
        mapData(res.data.data);
        setCusSchema(res.data.kintone_customer_schema);
        setListGroups(res.data.list_groups);
        setFilterCondition(res.data.filter_condition);
        setImportCondition(res.data.import_condition);
        setBatchConditions(res.data.batch_filter_condition);
      }
      setOpen(false);
      setCardOpen("log");
      setIsLoadingBtn(false);
    }
    fetchAllApi();
  }, []);

  function mapData(items) {
    let i = 0;
    const batches = data.map((batch) => {
      const existItem = items.find(item => item.batch_code === batch.batch_code);
      if (existItem && i === 0) {
        setBatchCode(existItem.batch_code);
        i++;
      }
      return existItem ? { ...batch, ...existItem, is_active: true } : batch;
    });
    setData(batches);
  }

  // Function change card open
  function handleChangeCardOpen(value) {
    setCardOpen(value);
  }

  // Function delete batch
  async function handleDelete(code) {
    const res = await fetchDelete({ batch_code: code }, dispatch);
    if (res.isOk) {
      const initItemValue = useInitialKTBatchValue().find(item => item.batch_code === code);
      let count = 0;
      const batches = data.map(item => {
        const existItem = item.batch_code === code;
        if (batchCode === code) {
          setBatchCode("");
        }
        return existItem ? { ...item, ...initItemValue } : item;
      });
      if (count === 0) {
        setCardOpen("");
      }
      setModel({});

      if (code === "BATCH_04") {
        setFilterCondition([]);
        setImportCondition({});
      }

      setBatchConditions((state) => state.filter(item => item.batch_code !== code));
      setData(batches);
    }
  }

  // Function handel change batch value
  function handleChangeBatchCode(code) {
    setBatchCode(code);
    setCardOpen("setting");
    const batches = data.map(item => {
      const existItem = item.batch_code === code;
      if (existItem) { setModel(item); }
      return existItem ? { ...item, is_active: true } : item;
    });
    setData(batches);
  }

  function onchangeValue(e) {
    const { name, value } = e.target;
    // Need to check type of value
    const isDate = ["start_date", "end_date"].includes(name) ? true : false;
    const [isError, key, message] = useValidate(name, value.toString(), isDate);
    setErrors((state) => ({
      ...state,
      [key]: isError ? message : null,
      isError,
    }));

    const val = useValue(key, value);
    setModel((state) => {
      return { ...state, [key]: key === "schedule" ? parseInt(val) : val };
    });

    const batches = data.map(item => {
      const existItem = item.batch_code === batchCode;
      return existItem ? { ...item, [name]: val } : item;
    });
    setData(batches);
  }

  async function handleUpdate() {
    const payload = getPayload();

    setIsLoadingBtn(true);
    await fetchUpdate(payload, dispatch);
    setIsLoadingBtn(false);
  }

  function getPayload() {
    const payload = {
      ...model,
      filter_condition: filterCondition,
      evercall_list_condition: JSON.stringify(importCondition),
    };

    if (!["BATCH_01", "BATCH_04"].includes(model.batch_code)) {
      const obj = findObjByKey(batchConditions, "batch_code", model.batch_code);
      payload.batch_filter_condition = obj ? JSON.stringify(obj) : "";
    }

    return payload;
  }

  function handleChangeBatchValue(val) {
    const exist = batchConditions.find((item) => item.batch_code === val.batch_code);
    if (exist !== undefined) {
      const objs = batchConditions.map((item) =>
        item.batch_code === val.batch_code ? { ...item, ...val } : item
      );
      return setBatchConditions(objs);
    }

    return setBatchConditions((state) => ([
      ...state, val
    ]));
  }

  function enableBtn() {
    const payload = getPayload();
    return useDisableButton(payload);
  }

  return (
    <>
      <Box className="backdrop">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
      <GridContainer spacing={3} direction="row">
        <GridItem xs={12} md={8}>
          <h3 className="text-bold text-dark-grey">実行バッチとログ</h3>
          <p className="text-grey">
            バッチ設定情報通りでキントーンに連携します
            <br />
            設定アイコンで実行するログ確認と設定時間編集できます。
          </p>
        </GridItem>
        <GridItem align="right" xs={12} md={4} className="dropdown-addition">
          <Box sx={{ mt: 3 }}>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              {
                data.map((item, idx) =>
                  <Chip
                    key={idx}
                    label={item.batch_name}
                    color="success"
                    onClick={() => handleChangeBatchCode(item.batch_code)}
                    variant={item.batch_code === batchCode ? "filled" : "outlined"}
                    sx={{ pl: 2 }}
                  />
                )
              }
            </Stack>
          </Box>
        </GridItem>
        <GridItem xs={12} lg={4} xl={3}>
          {data.map((item, idx) =>
            item.is_active &&
            <ListCaptureBatch
              key={idx}
              handleChangeCardOpen={item => handleChangeCardOpen(item)}
              onDelete={item => handleDelete(item)}
              handleChangeBatchValue={handleChangeBatchCode}
              batchItem={item}
            />
          )}
        </GridItem>
        {cardOpen === "setting" && (
          <GridItem xs={12} lg={8} xl={9}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary" className="setting-card-icon">
                  <Settings />
                </CardIcon>
                <h5 className="schedule-setting-title text-bold text-dark-grey">
                  {model?.batch_code === "BATCH_04"
                    ? "リスト取り込みバッチ設定"
                    : model?.batch_code === "BATCH_05"
                      ? "結果反映バッチ設定"
                      : "通話履歴更新バッチ設定"}
                </h5>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <BatchSetting
                    model={model}
                    errors={errors}
                    onChange={(e) => onchangeValue(e)}
                  />
                  {/* type = 1: Linkage Filter condition type = 2: evercall filter condition */}
                  <FilterCondition
                    batchCode={model.batch_code}
                    title={getTitleFilter(model.batch_code)?.title}
                    desc={getTitleFilter(model.batch_code)?.desc}
                    model={model}
                    errors={errors}
                    onChange={(e) => onchangeValue(e)}
                    cusSchema={cusSchema}
                    listGroups={listGroups}
                    conditions={filterCondition}
                    onAddFilterCondition={(data) => setFilterCondition(data)}
                    batchFilterCondition={findObjByKey(batchConditions, "batch_code", model.batch_code)}
                    onAddBatchFilterCondition={(data) => handleChangeBatchValue(data)}
                  />
                  {(["BATCH_01", "BATCH_04"].includes(model.batch_code)) &&
                    <ImportCondition
                      title={getTitleImport(model.batch_code).title}
                      desc={getTitleImport(model.batch_code).desc}
                      listGroups={listGroups}
                      importCondition={importCondition}
                      errors={errors}
                      onChange={(val) => setImportCondition(val)}
                    />
                  }

                  <GridItem xs={12} align="right">
                    <Box sx={{ pt: 3 }}>
                      <LoadingButton
                        color="secondary"
                        disabled={enableBtn()}
                        loading={isLoadingBtn}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                        onClick={handleUpdate}
                      >
                        保存
                      </LoadingButton>
                    </Box>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        )}
        {cardOpen === "log" && (
          <GridItem xs={12} lg={8} xl={9}>
            <BatchLog batchCode={batchCode} />
            <CaptureError batchCode={batchCode} />
          </GridItem>
        )}
      </GridContainer>
    </>
  );
}
