import { makeStyles } from "@material-ui/core/styles";
import alertStyles from "assets/jss/salto-react/views/sweetAlertStyle.js";
import { isEmpty, isNil, isUndefined, pickBy } from "lodash";
import Moment from "moment";
import React, { useMemo } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useLocation } from "react-router-dom";
import { CONSTANT } from "../constants/constant";
import { history } from "./index";
import { URL } from "./url";

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

// Render result from int to class
export function renderColorBadge(currentResult) {
  switch (currentResult) {
    case -1:
      return "warning";
    case -2:
    case -11:
      return "danger";
    case -9:
      return "secondary";
    case -3:
    case -4:
    case -5:
    case -6:
    case -7:
    case -8:
      return "success";
    default:
      return "info";
  }
}

export function getLocalStorage(item) {
  return JSON.parse(localStorage.getItem(item));
}

export function setLocalStorage(item, value) {
  const data = JSON.stringify(value);
  localStorage.setItem(item, data);
}

export function removeLocalStorage(item) {
  localStorage.removeItem(item);
}

export function logout() {
  localStorage.removeItem("user");
}

// Get confirm function
export function getConfirmFunc(error) {
  if (error === "EverCall認証情報設定がありません。") {
    directToEcSetting();
  } else if (
    [
      "SalesForceの認証情報を設定してください。",
      "SalesForce連携ができません",
      "Salesforce顧客マッピング設定が存在しません。",
      "マッピング設定取得失敗",
    ].includes(error)
  ) {
    directToSfSetting();
  } else if (
    [
      "キントーン認証情報設定がありません。",
      "キントーン連携ができません。",
    ].includes(error)
  ) {
    directToKintoneSetting();
  } else if (
    error ===
    "SalesForce認証情報設定がありません。システム管理者に連絡してください。"
  ) {
    directToDashboard();
  }
}

export function handleErrorSetting(user, errorMessage) {
  if (isEmpty(user.user_ecsetting)) {
    return "EverCall認証情報設定がありません。";
  } else if (isNil(user.user_salefore.company_id)) {
    if (user.is_staff) {
      return "SalesForceの認証情報を設定してください。";
    } else {
      return "SalesForce認証情報設定がありません。システム管理者に連絡してください。";
    }
  } else {
    return errorMessage;
  }
}

// Direct to kintone setting
function directToKintoneSetting() {
  history.push(URL.KINTONE_SETTING);
}

// Direct to ec setting
function directToEcSetting() {
  history.push(URL.EC_SETTING);
}

// Direct to sf setting
function directToSfSetting() {
  history.push(URL.SF_SETTING);
}

// Direct to dashboard
function directToDashboard() {
  history.push(URL.DAHSBOARD);
}

// Render result from int to class
export function renderWeekday(currentWeekDayInt) {
  switch (currentWeekDayInt) {
    case 1:
      return "日曜日";
    case 2:
      return "月曜日";
    case 3:
      return "火曜日";
    case 4:
      return "水曜日";
    case 5:
      return "木曜日";
    case 6:
      return "金曜日";
    case 7:
      return "土曜日";
    default:
      return "";
  }
}

export function ShowSweetAlert({ type, title, message, details = null, onClick }) {
  const useAlertStyles = makeStyles(alertStyles);
  const alertClasses = useAlertStyles();
  function handleAction() {
    if (onClick) {
      onClick(false);
    }
  }

  const error_details = []

  if (details) {
    const keys = Object.keys(details);

    for (let i=0; i < keys.length; i++) {
      error_details.push(`${keys[i]}: ${details[keys[i]].messages[0]}`)
    }
  }

  const error = type === "danger" ? true : false;
  const success = type === "success" ? true : false;
  const cls = type === "danger" ? alertClasses.danger : alertClasses.success;
  return (
    <SweetAlert
      success={success}
      error={error}
      style={{ display: "block", marginTop: "-100px" }}
      title={title}
      onConfirm={handleAction}
      confirmBtnCssClass={alertClasses.button + " " + cls}
    >
      <div className="d-flex alert-dialog">{message}</div>
      { error_details.length > 0 && <div className="d-flex flex-wrap error-detail w-100 mt-2"> {
         error_details.map((item, idx) => (
          <div className="d-block detail-item py-2" key={idx}>{item}</div>
         ))}
         </div>
      }
    </SweetAlert>
  );
}

export function buildURL(url, obj) {
  const params = new URLSearchParams(pickBy(obj)).toString();
  if (!params) {
    return url;
  }
  return `${url}?${params}`;
}

export function isNotEmpty(value) {
  return !(
    typeof value === undefined ||
    value === "undefined" ||
    value === null ||
    value === ""
  );
}

/**
 * check permission user login
 * @param {any} value
 * @returns {any}
 */
export function CheckPermission(value) {
  if (
    JSON.parse(localStorage.getItem("user"))["permissions"]?.includes(value)
  ) {
    // user exits permission
    return true;
  }
  return false;
}

export function dateFormat(date, type = "YYYY-MM-DD HH:mm") {
  if (typeof date === "undefined" || !date.length) {
    return "";
  }
  if (Moment(date, Moment.ISO_8601, true).isValid()) {
    return Moment.utc(date).format(type);
  }
  return "";
}

export function prepareFormData(data) {
  let formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));

  return formData;
}

export function objToQueryString(params) {
  let queryString = "?";
  Object.keys(params).map((key) => {
    queryString += `${key}=${params[key]}&`;
  });

  return queryString;
}

export function cleanObject(obj) {
  for (let item in obj) {
    const val = obj[item];
    if (typeof val === "object" && isEmpty(val)) {
      delete obj[item];
    } else {
      if (!isNotEmpty(obj[item])) delete obj[item];
    }
  }
  return obj;
}

export function consoleGroup(val, group = "GROUP") {
  console.group(group);
  console.log(val);
  console.groupEnd(group);
}

export function checkUserPermission(permissions, val) {
  if (permissions.length && permissions.includes(val)) {
    return true;
  }

  return false;
}
export function redirectWithErrorCode(code) {
  if (code === 100 || code === 103) {
    return history.push(`${CONSTANT.PREFIX_ADMIN}${URL.EC_SETTING}`);
  }

  if (code === 201) {
    return history.push(`${CONSTANT.PREFIX_ADMIN}${URL.KINTONE_CUS_MAPPING}`);
  }

  if (code === 202) {
    return history.push(`${CONSTANT.PREFIX_ADMIN}${URL.KINTONE_HIS_MAPPING}`);
  }

  if (code === 200 || code === 204) {
    return history.push(`${CONSTANT.PREFIX_ADMIN}${URL.KINTONE_SETTING}`);
  }

  if (code === 301) {
    return history.push(`${CONSTANT.PREFIX_ADMIN}${URL.SF_CUS_MAPPING}`);
  }

  if (code === 302) {
    return history.push(`${CONSTANT.PREFIX_ADMIN}${URL.SF_HIS_MAPPING}`);
  }

  if (code === 300 || code === 304) {
    return history.push(`${CONSTANT.PREFIX_ADMIN}${URL.SF_SETTING}`);
  }

  return true;
}

export function selectedOptionById(data, id) {
  if (id === null) {
    return id;
  }
  id = isNotEmpty(id) ? id : 0;
  const obj = data.find((o) => o.id === id);

  return !isUndefined(obj) ? obj : null;
}

/**
 * data = [{ id: 1, name: test }, { id: 2, name: test }];
 * ids = [1]
 * @param {array} data
 * @param {array} ids "1,2,3"
 * @returns
 */
export function findByIds(data, strIds) {
  const ids = strIds.split(",");
  if (!ids.length) return [];

  const obj = [];
  data.map((item) => {
    if (ids.includes(item.result_pattern_id.toString())) {
      obj.push(item);
    }
  });

  return obj;
}

export function getOnlyNumber(str) {
  if (typeof str === undefined || typeof str === "undefined") return "";
  return str.toString().replace(/[^\d]/g, "");
}

export function regexTel(str) {
  if (!isNotEmpty(str)) return true;
  const pattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$/g;
  return pattern.test(str);
}

export function deleteObjKey(k, data) {
  return Object.keys(data).reduce((object, key) => {
    if (key !== k) {
      object[key] = data[key];
    }
    return object;
  }, {});
}

export function findObjByKey(data, key, val) {
  if (!data) {
    return null;
  }

  return data.find((item) => item[key] === val);
}

export function getOptions(data) {
  if (!data) {
    return [];
  }
  return data.map((item) => item.label);
}

export function uniqueArray(data) {
  return [...new Set(data.map((item) => item))];
}

export function findOneObj(data) {
  return data.reduce((obj, item) => {
    obj[item.key] = item.value;
    return obj;
  }, {});
}

export function isEmptyObj(data) {
  return Object.values(data).some(
    (el) => el === undefined || el === "" || el === null
  );
}

export function mapObjToOptions(obj) {
  return Object.keys(obj).map((key) => ({ label: key, value: obj[key] }));
}

export function getErrors(data) {
  const error = Object.keys(data).reduce((obj, key) => {
    obj[key] = data[key][0];
    return obj;
  }, {});

  return { ...error, isError: true };
}
