import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { fetchSetting } from "../../../../services/api.kintoneSetting";

export function useData() {
  const [isOpen, setIsOpen] = useState(true);
  const [data, setData] = useState({});
  const dispatch = useDispatch();


  useEffect(() => {
    document.title = "キントーン一般設定";

    async function fetchApi() {
      const res = await fetchSetting(dispatch);
      setData(res.data);
      setIsOpen(false);
    }

    fetchApi();
  }, []);

  return [isOpen, data, setData];
}
