import { M_STATUS_API } from "../helpers/url";
import {
  fetchAsDelete,
  fetchAsGet,
  fetchAsPost,
  fetchAsPut,
} from "./api.service";
import { handleResData } from "./api.handleRespone";


export async function getStatus(dispatch) {
  const res = await fetchAsGet(M_STATUS_API.GET);
  return handleResData(res, dispatch);

}

export async function addStatus(body, dispatch) {
  const res = await fetchAsPost(M_STATUS_API.GET, body);
  return handleResData(res, dispatch, true);
}

export async function updateStatus(body, dispatch) {
  const res = await fetchAsPut(`${M_STATUS_API.UPDATE}/${body.id}`, body);
  return handleResData(res, dispatch, true);
}

export async function deleteStatus(id, dispatch) {
  const res = await fetchAsDelete(`${M_STATUS_API.UPDATE}/${id}`);
  return handleResData(res, dispatch, true);
}
