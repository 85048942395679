import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchData } from "../../../services/api.dashboard";

export function useData(params) {
  const dispatch = useDispatch();
  const [isShowBackdrop, setIsShowBackdrop] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState({
    salesforce_total: 0,
    kintone_total: 0,
    ec_total: 0,
    other_total: 0,
    range_salesforce_total: 0,
    range_kintone_total: 0,
    range_ec_total: 0,
    items: [],
    total_items: 0,
    summary: {
      sum_ec: 0,
      sum_sf: 0,
      sum_ktn: 0,
      sum_other: 0,
      sum_tairyu: 0,
    },
  });

  useEffect(() => {
    async function fetchApi() {
      const res = await fetchData(params, dispatch);
      if (res.isOk) {
        setData(res.data);
      }

      setIsLoading(false);
      setIsShowBackdrop(false);
    }

    fetchApi();
  }, [params]);

  return [data, isShowBackdrop, isLoading];
}
