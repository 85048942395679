import {
  cardTitle,
  dangerColor,
  successColor,
} from "assets/jss/salto-react.js";
import customCheckboxRadioSwitch from "assets/jss/salto-react/customCheckboxRadioSwitch.js";

const regularFormsStyle = {
  ...customCheckboxRadioSwitch,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  staticFormGroup: {
    marginLeft: "0",
    marginRight: "0",
    paddingBottom: "10px",
    margin: "8px 0 0 0",
    position: "relative",
    "&:before,&:after": {
      display: "table",
      content: '" "',
    },
    "&:after": {
      clear: "both",
    },
  },
  staticFormControl: {
    marginBottom: "0",
    paddingTop: "8px",
    paddingBottom: "8px",
    minHeight: "34px",
  },
  inputAdornment: {
    marginRight: "8px",
    position: "relative",
  },
  inputAdornmentIconSuccess: {
    color: successColor[0] + "!important",
  },
  inputAdornmentIconError: {
    color: dangerColor[0] + "!important",
  },
  descHistoryPage: {
    color: "#7B809A",
    fontSize: "12px",
  },
  titleResultConditions: {
    color: "#344767",
    marginTop: "30px",
    fontWeight: "bold",
  },
  titleResultConditionsDescription: {
    color: "#7b809a",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  checkAllTitle: {
    color: "#344767",
    fontWeight: "bold",
    fontSize: "15px",
    width: "200px",
  },
  wrapperCheckedAll: {
    display: "flex",
    height: "50px",
    lineHeight: "50px",
  },
  checkAllContent: {
    color: "black",
    fontSize: "15px",
  },
};

export default regularFormsStyle;
