import { useEffect, useState } from "react";
import { customerlMessages } from "../../../messages/customer";

export function useMapModel({ data, isLoading }) {
  const [obj, setObj] = useState({});
  const [headGroup, setHeadGroup] = useState([]);

  useEffect(() => {
    if (!isLoading) {
      const obj = mapObj();
      setObj(obj);
      setHeadGroup(data.details);
    }
  }, [isLoading]);

  function mapObj() {
    const objData = {
      customer_no: data.customer_no,
      name: data.name,
      tel: data.tel,
      address1: data.address1,
      address2: data.address2,
      tel_for_find: data.tel_for_find,
      user_name: data.user_name,
      status: data.status,
      status_id: data.status_id,
      customer_memo_2: data.customer_memo_2,
      details: [],
    };

    const details = useModel(data);
    return { ...objData, ...details };
  }
  return [obj, setObj, headGroup];
}

export function useValidate(key, val) {
  let message = "";
  let isError = false;
  if (val.length > 255) {
    message = customerlMessages["max_length"];
    isError = true;
  }

  return [isError, key, message];
}

export function useModel(data) {
  const model = { details: [] };

  // Map bikos
  data.bikos.map(item => {
    model[item.key] = item.value;
  });

  // Map details
  data.details.map(item => {
    const init = {};
    init.head_id = item.head_id;

    item.groups.map(group => {
      if (group.biko) {
        init[group.biko.key] = group.biko.value ?? "";
      }

      if (group.text) {
        group.text.map(t => {
          init[t.key] = t.value ?? "";
        });
      }

      if (group.check) {
        group.check.map(c => {
          init[c.key] = c.value ?? "";
        });
      }
    });

    model.details.push(init);
  });

  return model;
}
