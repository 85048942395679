import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/salto-react/components/adminNavbarLinksStyle.js";
import { connect } from "react-redux";

import { userActions } from "actions";

// const useStyles = makeStyles(styles);

class AdminNavbarLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openNotification: null,
      openProfile: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleClickNotification = (event) => {
    const { openNotification } = this.state;
    if (openNotification && openNotification.contains(event.target)) {
      this.setState({ openNotification: null });
    } else {
      this.setState({ openNotification: event.currentTarget });
    }
  };

  handleCloseNotification = () => {
    this.setState({ openNotification: null });
  };

  handleClickProfile = (event) => {
    // this.setState({ openProfile: event.currentTarget });
    const { openProfile } = this.state;
    if (openProfile && openProfile.contains(event.target)) {
      this.setState({ openProfile: null });
    } else {
      this.setState({ openProfile: event.currentTarget });
    }
  };

  handleCloseProfile = () => {
    this.setState({ openProfile: null });
  };

  responseGoogle = () => {
    this.props.logout();
  };

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  render() {
    const { rtlActive, classes } = this.props;
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive,
    });
    return <div className={wrapper}></div>;
  }
}

AdminNavbarLinks.propTypes = {
  rtlActive: PropTypes.bool,
};
function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

const connectedHeaderLinks = connect(
  mapState,
  actionCreators
)(withStyles(styles)(AdminNavbarLinks));
export { connectedHeaderLinks as AdminNavbarLinks };
