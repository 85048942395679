import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import alertStyles from "assets/jss/salto-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import CircularProgress from "@material-ui/core/CircularProgress";
const useAlertStyles = makeStyles(alertStyles);

export function AlertConfirm(props) {
  const { data, onClick, showConfirm, type = 1, clickOutside = false } = props;
  const alertClasses = useAlertStyles();

  function handleAction(isProcess) {
    const obj = { isProcess, type };
    if (onClick) {
      onClick(obj);
    }
  }

  function getMessage() {
    let obj = {
      title: "",
      message: "",
    };

    if (!showConfirm) {
      obj.title = type === 3 ? "EXPORT" : "UPDATE";
      obj.message =  type === 3 ?  `タイムアウトが発生しないように、CSV出力件数の上限は${data.left}  件です。` :`本日残: ${data.left}/${data.update}`;
    } else {
      // obj.title = `${data.update} 件を連携してもよろしいでしょうか？`;
      obj.title = type === 3 ? "EXPORT" : `${data.update} 件を連携してもよろしいでしょうか？`;
      obj.message =  type === 3 ?`タイムアウトが発生しないように、CSV出力件数の上限は${data.left}  件です。`:`本日残：${data.left}  件`;
    }

    return obj;
  }
  // showCancel = true
  return (
    <SweetAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title={getMessage().title}
      onConfirm={() => handleAction(true, 1)}
      onCancel={() => handleAction(false)}
      confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
      confirmBtnText="はい"
      cancelBtnText="いいえ"
      showCancel
      showConfirm={showConfirm}
      closeOnClickOutside={clickOutside}
    >
      {getMessage().message}
    </SweetAlert>
  );
}

export function AlertProcess(props) {
  const { type } = props;

  function getMessage() {
    if (type === 1) {
      return "Kintoneにデータを連携しています。";
    }
    if (type === 2) {
      return "Salesforceにデータを連携しています。";
    }

    return "Exporting...";
  }
  return (
    <SweetAlert
      custom
      title=""
      showConfirm={false}
      onConfirm={() => {
        return;
      }}
      customIcon={
        <CircularProgress className="icon-loading-alert" color="inherit" />
      }
      closeOnClickOutside={false}
    >
      {getMessage()}
      <br />
      少々お待ちください。
    </SweetAlert>
  );
}
