import { API_KINTONE } from "../helpers/apiURL";
import { fetchAsGet, fetchAsPost } from "./api.service";
import { handleResData } from "./api.handleRespone";

export async function fetchMapping(dispatch) {
  const res = await fetchAsGet(API_KINTONE.HIS_MAPPING);
  const result = handleResData(res, dispatch);

  if (!result.isOk) {
    return { data: [], salesforce_object: null };
  }
  const { data, salesforce_object } = result.data;

  return { data, salesforce_object };

}

export async function fetchMappingInfo(dispatch) {
  const res = await fetchAsGet(API_KINTONE.HIS_MAPPING_INFO);
  return handleResData(res, dispatch);
}

export async function fetchUpdateMapping(params, dispatch) {
  const res = await fetchAsPost(API_KINTONE.HIS_MAPPING, params);
  return handleResData(res, dispatch, true);
}

export async function fetchCusMapping(dispatch) {
  const res = await fetchAsGet(API_KINTONE.CUS_MAPPING);
  const result = handleResData(res, dispatch);

  if (!result.isOk) {
    return { data: [], salesforce_object: null };
  }
  const { data, salesforce_object } = result.data;

  return { data, salesforce_object };

}

export async function fetchCusMappingInfo(dispatch) {
  const res = await fetchAsGet(API_KINTONE.CUS_MAPPING_INFO);
  return handleResData(res, dispatch);
}

export async function fetchUpdateCusMapping(params, dispatch) {
  const res = await fetchAsPost(API_KINTONE.CUS_MAPPING, params);
  return handleResData(res, dispatch, true);
}