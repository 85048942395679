import React, { useEffect } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import { History } from "./Components/History";
import { useDetail } from "./hooks/useDetail";
import Detail from "./Components/Detail";

export default function index() {
  const [data, isLoading] = useDetail();

  useEffect(() => {
    document.title = "通話履歴一覧";
  });

  return (
    <>
      <GridContainer spacing={3}>
        <Detail
          data={data.ec_customer}
          statuses={data.statuses}
          isLoading={isLoading}
        />
        <History data={data.ec_histories} isLoading={isLoading} />
      </GridContainer>
    </>
  );
}
